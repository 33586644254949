import React from "react"
// import { createContext, useContext, useState } from "react";

import usePersistedState from "./usePersistedState";

// register the context
const ThemeContext = React.createContext({});

/**
 * export custom provider
 * @param {boolean} darkMode
 * @returns
 */
export function ThemeProvider({ children }) {
  /** usePersistedState for storing state in local store */
  // const [darkMode, setDarkMode] = React.useState(false);
  const [darkMode, setDarkMode] = usePersistedState("darkmode", false);
  //  const[user,setUser] = React.useState("")
  return (
    <ThemeContext.Provider value={{ darkMode, setDarkMode }}>
      {children}
    </ThemeContext.Provider>
  );
}

// export a custom hook to use this specific context
export function useThemeContext() {
  return React.useContext(ThemeContext);
}

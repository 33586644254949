import React from "react";
import { FaPlus } from "react-icons/fa6";
import { CiCreditCard1 } from "react-icons/ci";
import { Link, useNavigate } from "react-router-dom";
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { toast } from "react-toastify";
import { FaBars } from "react-icons/fa";
import Sidebar from "./Sidebar";
import { UserContext } from "../UserContext";
import axios from 'axios';
import logoHorizontal from '../images/logo-horizontal.jpg';

function Header() {
  const [show, setShow] = useState(false);
  const { user } = UserContext();
  const [conversionRateToDollars, setConversionRateToDollars] = React.useState(0);
  const [prettyConversionRateToDollars, setPrettyConversionRateToDollars] = React.useState("");

  React.useEffect(() => {
    // GET CONVERSION RATE
    const url = process.env.REACT_APP_BASE_DOMAIN + "/api/exchange-rates/current";

    axios.get(url)
      .then(response => {
        setConversionRateToDollars(response.data.conversionRateToDollars);
        setPrettyConversionRateToDollars(response.data.prettyConversionRateToDollars);
      })
      .catch(err => {
        toast.warning(err.response.data.message)
      });
  }, [])

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  
  const naviagtion = useNavigate();
  return (
    <div className="header">
      {user &&
        <div className="headerblock">
          <div className="logobar">
            <Link to="/" >
              <img src={ logoHorizontal } className="logo" alt="logo" />
            </Link>
          </div>
          <ul>
            <li>
              <button className="btn btnprice" type="button">
                ǂ1 / ${conversionRateToDollars.toFixed(3)}
              </button>
            </li>
            <li>
              <button className="btn btn-primary" onClick={handleShow}><FaBars /></button>
            </li>
          </ul>
          <Offcanvas className="mobile-sidebar" show={show} onHide={handleClose}>
            <Offcanvas.Header closeButton>
              <Offcanvas.Title></Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body className="auth-layout">
              <div className="sidebar"><Sidebar/></div>
            </Offcanvas.Body>
          </Offcanvas>
        </div>
      }
    </div>
  )
}
export default Header;

import React,{useState} from "react";
import { FaCalendarAlt } from "react-icons/fa";
import moment from 'moment'
import { UserContext } from "../../UserContext";
import { toast } from "react-toastify";
import Cookies from 'universal-cookie';
import axios from "axios";


function SignInHistory() {
  const { user} = UserContext();
  const [signIns, setSignIns] = useState([]);

  React.useEffect(() => {
    const url = process.env.REACT_APP_BASE_DOMAIN + "/api/signins";
    const cookies = new Cookies();
    const authToken = cookies.get('nJWT');
    const config = { headers: { Authorization: `Bearer ${authToken}` } };

    axios.get(url, config)
      .then(signins => {
        setSignIns(signins.data);
        console.log("sign",signins.data)
      }).catch(err => {
        toast.warning(err.response.data.message)
      });
  }, []);

  return(
  <>
    <div className="usefull-boxes my-5">
      <div className="usefull-head">
        <FaCalendarAlt />
        <div className="detail">
          <h4>Sign In History</h4>
        </div>
      </div>
      {signIns.map(s =>
        <ul className="contactdetail" key={s.id}>
          <li className="add1">{s.location}</li>
          <li className="date">{moment(s.createdAt).format('h:mma dddd MMMM DD YYYY')}</li>
          <li className={s.wasSuccessful?"status":"status-f"}>{s.wasSuccessful?"Success":"Failed"}</li>
        </ul>
      )}
    </div>
  </>
  )
}
export default SignInHistory;

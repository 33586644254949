// SignUpForm.js

import React, { useContext, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import InputMask from "react-input-mask";
import axios from "axios";
import AuthService from "../AuthService";
import { UserContext } from "../UserContext";
import { toast } from "react-toastify";
import moment from "moment";

function SignUpForm() {
  const { user, setUser } = UserContext();
  const { getCurrentUser } = AuthService();

  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [passwordShown, setPasswordShown] = React.useState(false);
  const [phone, setPhone] = React.useState("");
  const [agreedToTOS, setAgreedToTOS] = React.useState(true);
  
  const [username, setUsername] = React.useState("");
  const [first, setFirst] = React.useState("");
  const [middle, setMiddle] = React.useState("");
  const [last, setLast] = React.useState("");
  const [suffix, setSuffix] = React.useState("");
  const [address, setAddress] = React.useState("");
  const [city, setCity] = React.useState("");
  const [state, setState] = React.useState("");
  const [postalCode, setPostalCode] = React.useState("");
  const [dob, setDob] = React.useState("");
  const [ssn, setSsn] = React.useState("");

  const [loading, setLoading] = React.useState(false);

  const navigate = useNavigate();
  const { signUp } = AuthService();
    
  useEffect(()=>{
    function isMinor(date) {
      const eighteenYearsAgo = moment().subtract(18, "years");
      const birthday = moment(date);
      if (eighteenYearsAgo.isBefore(birthday)) {
        toast.warning(
          "You must be over 18 years of age to create a wallet."
        );
      } 
    }
     isMinor(dob);
  },[dob])

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  const handleFirstNameChange = (e) => {
    setFirst(e.target.value);
    const fullName = e.target.value + last;
    const username = fullName.replace(/\s+/g, "").toLowerCase();
    setUsername(username);
  };
  const handleLastNameChange = (e) => {
    setLast(e.target.value);
    const fullName = first + e.target.value;
    const username = fullName.replace(/\s+/g, "").toLowerCase();
    setUsername(username);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    if (!agreedToTOS) { toast.warning("To continue, you must agree to the User Agreement and Privacy Statement.")}

    const user = {
      email:email,
      password: password,
      phone:phone,
      agreedToTOS:agreedToTOS,
      first: first,
      middle: middle,
      last: last,
      suffix: suffix,
      username: username,
      dob:dob,
      address: address,
      city: city,
      state: state,
      postalCode: postalCode,
      // ssn: ssn,
    };

    signUp(user)
      .then(() => {
        toast.success("Welcome to Numey!");
        navigate("/two-factor-auth")
        // getCurrentUser().then(user => {
        //   console.log('setting current user')
          // setUser(user)
        // });
        setLoading(false);
      })
      .catch((err) => {
        if (err.response) {
          toast.warning(err.response.data.message);
        } else {
          toast.warning("There was a problem signing up. Please try again.");
        }
        setLoading(false);
      });
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <h5>Authentication</h5>
          <div className="mb-3">
            <label className="form-label" htmlFor="email">
              Email<span className="text-danger">*</span>
            </label>
            <input
              className="form-control"
              id="email"
              name="email"
              placeholder=""
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required="required"
            />
          </div>
          <div className=" mb-3">
            <label className="form-label" htmlFor="phone">
              Phone Number<span className="text-danger">*</span>
            </label>
            <InputMask
              className="form-control"
              id="phone"
              type="tel"
              name="phone"
              placeholder="+1(555) 333-4444"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              mask="+1\(999) 999-9999"
              maskplaceholder={""}
              maskchar=" "
              required="required"
            />
          </div>
          <div className="mb-3">
            <label className="form-label" htmlFor="password">
              Password<span className="text-danger">*</span>
            </label>
            <div className="input-group">
              <input
                className="form-control"
                id="password"
                name="password"
                type={passwordShown ? "text" : "password"}
                autoComplete="new-password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required="required"
              />
              <button
                className="btn btn-light btn-sm border"
                type="button"
                onClick={togglePassword}
              >
                {passwordShown ? "Hide" : "Show"}
              </button>
            </div>
          </div>
        </div>
        <div className="row mb-3">
          <h5 className="mt-3 mb-3">Personal Information</h5>
          <div className="col-7">
            <label className="form-label" htmlFor="first">
              First Name<span className="text-danger">*</span>
            </label>
            <input
              className="form-control"
              id="first"
              name="first"
              placeholder="First Name"
              value={first}
              onChange={handleFirstNameChange}
              required="required"
            />
          </div>
          <div className="col-5">
            <label className="form-label" htmlFor="middle">
              Middle Name
            </label>
            <input
              className="form-control"
              id="middle"
              name="middle"
              placeholder="Middle Name"
              value={middle}
              onChange={(e) => setMiddle(e.target.value)}
            />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-9">
            <label className="form-label" htmlFor="last">
              Last Name<span className="text-danger">*</span>
            </label>
            <input
              className="form-control"
              id="last"
              name="last"
              placeholder="Last Name"
              value={last}
              onChange={handleLastNameChange}
              required="required"
            />
          </div>
          <div className="col-3">
            <label className="form-label" htmlFor="suffix">
              Suffix
            </label>
            <input
              className="form-control"
              id="suffix"
              name="suffix"
              placeholder="Suffix"
              value={suffix}
              onChange={(e) => setSuffix(e.target.value)}
            />
          </div>
        </div>
        <div className="col-12 mb-3">
          <label className="form-label" htmlFor="username">
            Username<span className="text-danger">*</span>
          </label>
          <div className="input-group mb-3">
            <input
              className="form-control"
              id="username"
              name="username"
              placeholder="Username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required="required"
            />
          </div>
        </div>
        <div className="col-12 mb-3">
          <label className="form-label" htmlFor="dob">
            Date of Birth<span className="text-danger">*</span>
          </label>
          <input
            className="form-control"
            id="dob"
            type="date"
            name="dob"
            placeholder=""
            value={dob}
            onChange={(e) => setDob(e.target.value)}
            required="required"
          />
        </div>
        <div className="row">
          <div className="col-12 mb-3">
            <label className="form-label" htmlFor="dob">
              Address<span className="text-danger">*</span>
            </label>
            <input
              className="form-control"
              id="Address"
              type="Address"
              name="Address"
              placeholder="Address"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              required="required"
            />
          </div>
        </div>

        {/*          <div className="col-12 mb-3">
                    <label className="form-label" htmlFor="ssn">Social Security Number</label>
                    <InputMask
                      className="form-control"
                      id="ssn"
                      name="ssn"
                      placeholder="444-33-5555"
                      value={ssn}
                      onChange={e => setSsn(e.target.value)}
                      mask="999-99-9999"
                      maskplaceholder={""}
                      maskchar=" "/>
                    <div className="form-text">SSN only required for carrying a balance over <span className="nmy">ǂ</span>25,000 NMY. We encrypt your SSN for your security.</div>
                  </div>*/}
        {/* 
                  <div className="row">
                    <div className="col-12 mb-3">
                      <label className="form-label" htmlFor="address">
                        Street Address & Unit Number
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        className="form-control"
                        id="address"
                        name="address"
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                        required="required"
                      />
                      <div className="form-text">
                        Numey wallet users must be individuals who reside within
                        the United States.
                      </div>
                    </div>
                  </div> */}
        <div className="row">
          <div className="col-6">
            <label className="form-label" htmlFor="city">
              City<span className="text-danger">*</span>
            </label>
            <input
              className="form-control"
              id="city"
              name="city"
              value={city}
              placeholder="City "
              onChange={(e) => setCity(e.target.value)}
              required="required"
            />
          </div>
          <div className="col-3">
            <label className="form-label" htmlFor="state">
              State<span className="text-danger">*</span>
            </label>
            <select
              className="form-select form-control"
              id="state"
              name="state"
              value={state}
              placeholder="State"
              onChange={(e) => setState(e.target.value)}
              required="required"
            >
              <option value=""></option>
              <option value="AL">AL</option>
              <option value="AK">AK</option>
              <option value="AS">AS</option>
              <option value="AZ">AZ</option>
              <option value="AR">AR</option>
              <option value="CA">CA</option>
              <option value="CO">CO</option>
              <option value="CT">CT</option>
              <option value="DE">DE</option>
              <option value="DC">DC</option>
              <option value="FL">FL</option>
              <option value="GA">GA</option>
              <option value="GU">GU</option>
              <option value="HI">HI</option>
              <option value="ID">ID</option>
              <option value="IL">IL</option>
              <option value="IN">IN</option>
              <option value="IA">IA</option>
              <option value="KS">KS</option>
              <option value="KY">KY</option>
              <option value="LA">LA</option>
              <option value="ME">ME</option>
              <option value="MD">MD</option>
              <option value="MA">MA</option>
              <option value="MI">MI</option>
              <option value="MN">MN</option>
              <option value="MS">MS</option>
              <option value="MO">MO</option>
              <option value="MT">MT</option>
              <option value="NE">NE</option>
              <option value="NV">NV</option>
              <option value="NH">NH</option>
              <option value="NJ">NJ</option>
              <option value="NM">NM</option>
              <option value="NY">NY</option>
              <option value="NC">NC</option>
              <option value="ND">ND</option>
              <option value="MP">MP</option>
              <option value="OH">OH</option>
              <option value="OK">OK</option>
              <option value="OR">OR</option>
              <option value="PA">PA</option>
              <option value="PR">PR</option>
              <option value="RI">RI</option>
              <option value="SC">SC</option>
              <option value="SD">SD</option>
              <option value="TN">TN</option>
              <option value="TX">TX</option>
              <option value="UT">UT</option>
              <option value="VT">VT</option>
              <option value="VA">VA</option>
              <option value="VI">VI</option>
              <option value="WA">WA</option>
              <option value="WV">WV</option>
              <option value="WI">WI</option>
              <option value="WY">WY</option>
            </select>
          </div>
          <div className="col-3 mb-3">
            <label className="form-label" htmlFor="postalCode">
              Zip<span className="text-danger">*</span>
            </label>
            <input
              className="form-control"
              id="postalCode"
              name="postalCode"
              value={postalCode}
              placeholder="Zip"
              onChange={(e) => setPostalCode(e.target.value)}
              required="required"
            />
          </div>
        </div>

        <div className="row mt-3">
          <div className="col-12 mb-3">
            <div className="form-check form-switch">
              <input 
                className="form-check-input" 
                type="checkbox" 
                role="switch" 
                id="agreedToTOS"
                name="agreedToTOS"
                value={agreedToTOS}
                onChange={(e) => setAgreedToTOS(e.target.checked)}
                required="required"
                defaultChecked={agreedToTOS}
                />
              <label className="form-check-label" for="agreedToTOS">I agree to the <Link to="/user-agreement" target="_blank">User Agreement</Link> and the <Link to="/privacy" target="_blank">Privacy Statement</Link><span className="text-danger">*</span></label>
            </div>
          </div>
        </div>
        <div className="col-12 mt-4">
          <div className="text-end"></div>
        </div>
        <button
          className="btn btn-primary w-100"
          disable={loading}
          onClick={(e)=>handleSubmit(e)}
          type="button"
        >
          Save & Continue
        </button>
      </form>
    </>
  );
}

export default SignUpForm;

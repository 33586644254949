import React from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import Cookies from "universal-cookie";
import { toast } from "react-toastify";
import logoHorizontal from '../images/logo-horizontal.jpg';

function PasswordForgot() {
  const [email, setEmail] = React.useState("");

  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();

    const body = {
      email: email,
    };

    const url =
      process.env.REACT_APP_BASE_DOMAIN + "/api/request-change-password";

    axios
      .put(url, body)
      .then((response) => {
        toast.success("Password reset email sent! Please check your inbox.");
        navigate("/change-password");
      })
      .catch((err) => {
        toast.warning("There was a problem. Please try again.");
        console.log(err);
      });
  };

  return (
    <>
      <div className="authentication-block">
        <div className="sidebar">
          <div className="logobar">
            <img alt="logo" src={ logoHorizontal } className="logo" />
          </div>
          <div className="ilustrationbar">
            <img src="images/cuate.png" alt="ilustrationbar" />
          </div>
          <div className="sidebar-bottomside">
            <h5>Get Numey Mobile App</h5>
            <p>
              Numey mobile app is now also available on Google play and App
              store
            </p>
          </div>
        </div>
        <div className="Auth-bar centeredbox">
        <div className="logobar mobenable" onClick={()=>navigate("/")} style={{cursor:"pointer"}}>
            <img alt="logo" src={ logoHorizontal } className="logo" />
          </div>
          <form onSubmit={handleSubmit}>
          <div className="formtitle">
              <h3>Forgot password</h3>
              <p>We will send you an email for your password </p>
            </div>
            <div className="mb-3">
              <label className="form-label" htmlFor="email">
                Email Address<span className="text-danger">*</span>
              </label>
              <input
                className="form-control"
                id="email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                type="email"
                placeholder="Enter email"
                required="required"
              />
              <div className="form-text">
                Enter the email associated with your account here and submit
                this form to receive a secure link to reset your password.
              </div>
            </div>
            <div className="text-end">
              <button
                className="me-3 btn btn-link link-secondary text-decoration-none w-100"
                onClick={() => navigate(-1)}
              >
                Cancel
              </button>
              <button className="btn btn-primary w-100 " type="submit">
                Send Request
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
export default PasswordForgot;

import React from 'react';
import { Link } from 'react-router-dom';

function UserAgreement() {
  return (
    <div className="about">
      <div className="container mt-5 mb-5">
        <h2 className="quote text-center">The Numey Payment System User Agreement</h2>
        <div className="row">
          <div className="col-lg-8 offset-lg-2">
            <div className="lead text-center mb-4 mt-3">This user agreement is effective for all users as of April 5 2022.</div>
            <p>Welcome to the Numey Payment System!</p>
            <p>
              The New Reserve LLC ("The New Reserve”, "The Numey Payment System", "Numey", "NPS", “we,” “us,” “our”) provides its services (described below) to you through its website located at www.paywithnumey.com (the “Site”) and through its mobile applications and related technology and services (collectively, such services, including any new features and applications, and the Site, the “Service”), subject to the following User Agreement (as amended from time to time, the “User Agreement”). We reserve the right, at our sole discretion, to change or modify portions of these User Agreement at any time. If we do this, we will post the changes on this page and will indicate at the top of this page the date these terms were last revised. We will also notify you, either through the Service user interface, in an email notification or through other reasonable means. Any such changes will become effective no earlier than fourteen (14) days after they are posted, except that changes addressing new functions of the Service or changes made for legal reasons will be effective immediately. Your continued use of the Service after the date any such changes become effective constitutes your acceptance of the new User Agreement.
            </p>
            <p>
              PLEASE READ THESE TERMS OF SERVICE CAREFULLY, AS THEY CONTAIN AN AGREEMENT TO ARBITRATE AND OTHER IMPORTANT INFORMATION REGARDING YOUR LEGAL RIGHTS, REMEDIES, AND OBLIGATIONS. THE AGREEMENT TO ARBITRATE REQUIRES (WITH LIMITED EXCEPTION) THAT YOU SUBMIT CLAIMS YOU HAVE AGAINST US TO BINDING AND FINAL ARBITRATION, AND FURTHER (1) YOU WILL ONLY BE PERMITTED TO PURSUE CLAIMS AGAINST THE NEW RESERVE ON AN INDIVIDUAL BASIS, NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY CLASS OR REPRESENTATIVE ACTION OR PROCEEDING, (2) YOU WILL ONLY BE PERMITTED TO SEEK RELIEF (INCLUDING MONETARY, INJUNCTIVE, AND DECLARATORY RELIEF) ON AN INDIVIDUAL BASIS, AND (3) YOU MAY NOT BE ABLE TO HAVE ANY CLAIMS YOU HAVE AGAINST US RESOLVED BY A JURY OR IN A COURT OF LAW.
            </p>
            <p>In addition, when using certain services, you will be subject to any additional terms applicable to such services that may be posted on the Service from time to time, including, without limitation, the Privacy Statment located at https://www.paywithnumey.com/privacy (the “Privacy Statment”). All such terms are hereby incorporated by reference into these User Agreement.</p>

            <h5>Opening a NPS Wallet</h5>

            <div className="fw-bold">Eligibility</div>

            <p>In order to open a NPS wallet and use the Numey Payment System you must be</p>
            <p>
              <ul>
              <li>a resident of the United States or one of its territories</li>
              <li>at least 18 years old, or the age of majority in your state of residence</li>
              </ul>
            </p>

            <div className="fw-bold">Wallet Actions</div>

            <p>NPS wallets enable you to do the following:</p>
            <p>
              <ul>
              <li>Convert US Dollars into Numey.</li>
              <li>Send and receive Numey to other NPS wallets.</li>
              <li>Convert Numey into US Dollars.</li>
              </ul>
            </p>
            <p>You are responsible for maintaining adequate security and control of any and all IDs, passwords, personal identification numbers, or any other codes that you use to access your NPS wallet and the Numey Payment System. You must keep your mailing address, email address and other contact information current in your NPS wallet profile.</p>

            <h5>Deactivating Your NPS Wallet</h5>
            <p>You may deactivate your NPS wallet and terminate your relationship with us at any time without cost, but you will remain liable for all obligations related to your NPS wallet even after the NPS wallet is deactivated. When you deactivate your NPS wallet, we will cancel any scheduled or incomplete transactions. In certain cases, you may not deactivate your NPS wallet, including:</p>
            <p>
              <ul>
              <li>To evade an investigation.</li>
              <li>If you have a pending transaction or an open dispute or claim.</li>
              <li>If your NPS wallet has a negative balance.</li>
              <li>If your NPS wallet has a positive balance.</li>
              <li>If your NPS wallet is subject to a hold, limitation or reserve.</li>
              </ul>
            </p>

            <h5>Receiving Funds, Holding a Balance or Transferring Funds</h5>

            <div className="fw-bold">Not a Bank. No Interest.</div>
            <p>Any balance in your NPS wallet is not insured by the Federal Deposit Insurance Corporation (FDIC).</p>
            <p>The New Reserve is not a bank and does not itself take deposits. You will not receive any interest on the funds held with The New Reserve. The New Reserve purhcases and holds liquid investments to serve as a reserve of the Numey in circulation. The New Reserve owns this reserve and the interest or other earnings on this reserve.</p>

            <div className="fw-bold">Fee Structure</div>
            <p>In order to operate, Numey gathers fees from transactions on the Numey Payment System. The goal of Numey's fee structure is to be significantly less than the fees (or gas) charged by cyptocurrency networks and exchanges and even less than traditional credit card fees which are usually 2.9% + $0.30.</p>
            <p>
              <ul>
              <li>NPS Purchase Fee: 0%</li>
              <li>NPS Send Fee: 0%</li>
              <li>NPS Sell Fee: 1%</li>
              </ul>
            </p>

            <div className="fw-bold">Once Processed, All Transactions are Final</div>
            <p>It is your responsibility to verify the identity of the recipient of funds sent via NPS. If you send money by mistake to the wrong NPS wallet, we cannot refund this money.</p>

            <div className="fw-bold">Acceptable Use</div>
            <p>You are responsible for complying with all applicable laws in your actions related to your use of NPM's services, regardless of the purpose of the use. In addition, you must adhere to the terms of this Acceptable Use Policy. Violation of this Acceptable Use Policy constitutes a violation of the NPS User Agreement and may subject you to damages.</p>

            <div className="fw-bold">NPS Balance Exchange</div>
            <p>The exchange of Numey for US Dollars and US Dollars for Numey is at the discretion of The New Reserve LLC and is not guaranteed.</p>

            <h5>Prohibited Activities</h5>
            <p>You may not use the NPS service for activities that:</p>
            <ol>
              <li>violate any law, statute, ordinance or regulation.</li>
              <li>send or receive money outside the United States</li>
              <li>relate to transactions involving (a) narcotics, steroids, certain controlled substances or other products that present a risk to consumer safety, (b) drug paraphernalia, (c) cigarettes, (d) items that encourage, promote, facilitate or instruct others to engage in illegal activity, (e) stolen goods including digital and virtual goods, (f) the promotion of hate, violence, racial or other forms of intolerance that is discriminatory or the financial exploitation of a crime, (g) items that are considered obscene, (h) items that infringe or violate any copyright, trademark, right of publicity or privacy or any other proprietary right under the laws of any jurisdiction, (i) certain sexually oriented materials or services, (j) ammunition, firearms, or certain firearm parts or accessories, or (k) certain weapons or knives regulated under applicable law.</li>
              <li>relate to transactions that (a) show the personal information of third parties in violation of applicable law, (b) support pyramid or ponzi schemes, matrix programs, other "get rich quick" schemes or certain multi-level marketing programs, (c) are associated with purchases of annuities or lottery contracts, lay-away systems, off-shore banking or transactions to finance or refinance debts funded by a credit card, (d) are for the sale of certain items before the seller has control or possession of the item, (e) are by payment partners to collect payments on behalf of merchants, (f) are associated with the sale of traveler's checks or money orders, (g) involve currency exchanges or check cashing businesses, (h) involve certain credit repair, debt settlement services, credit transactions or insurance activities, or (i) involve offering or receiving payments for the purpose of bribery or corruption.</li>
              <li>involve the sales of products or services identified by government agencies to have a high likelihood of being fraudulent.</li>
              <li>relate to transactions involving any activity that requires pre-approval without having obtained said approval.</li>
            </ol>
            <div className="fw-bold">Wallet Activity Log</div>
            <p>You have the right to receive a log showing your NPS wallet activity. You may view your NPS wallet activity log by signing into your NPS wallet.</p>

            <h5>Sending and Accepting Payments</h5>

            <div className="fw-bold">Taxes and information reporting</div>
            <p>Our fees do not include any taxes, levies, duties or similar governmental assessments of any nature, including, for example, value-added, sales, use or withholding taxes, assessable by any jurisdiction (collectively, “taxes”). It is your responsibility to determine what, if any, taxes apply to the payments you make or receive, and it is solely your responsibility to assess, collect, report and remit the correct taxes to the appropriate authority. The New Reserve is not responsible for determining whether any taxes apply to your transaction, or for calculating, collecting, reporting or remitting taxes arising from any transaction.</p>
            <p>You acknowledge that we may make certain reports to tax authorities regarding transactions that we process.</p>

            <div className="fw-bold">Payment review</div>
            <p>The New Reserve reviews certain potentially high-risk transactions. If The New Reserve determines, in its sole discretion, that a transaction is high-risk, we place a hold on the payment. The New Reserve will conduct a review and either complete or cancel the payment. We will notify you about payment reviews by email and/or through your NPS wallet.</p>

            <h5>ACH Payments</h5>

            <div className="fw-bold">ACH return policy</div>
            <p>An ACH return is a transfer failure wherein a transfer is unable to be completed, or is returned after being completed, for a number of possible reasons. An automatic ACH return is possible for up to 60 days after the transaction was initiated, and a request to allow a return may be received up to two years after the original transaction.</p>

            <p>The New Reserve has an automated process which will usually resolve ACH return issues. However, in cases where the automated process cannot be used, we have the following policy:</p>
            <ol>
              <li>We receive notification from our bank partner of ACH return on your NPS wallet</li>
              <li>We assess how the return should be resolved - either resubmitted or reversed</li>
              <li>If resubmitted, we will resubmit the ACH transfer</li>
              <li>If reversed, we will debit your NPS wallet by the returned amount and email you explaining what has occurred. If your balance goes negative, we will freeze your account and possibly take further action to recover the negative account balance</li>
            </ol>

            <h5>Restricted Activities, Holds, and Liquidated Damages</h5>

            <div className="fw-bold">Restricted Activities</div>
            <p>In connection with your use of our websites, your NPS wallet, the Numey Payment System, or in the course of your interactions with The New Reserve, other The New Reserve customers, or third parties, you must not:</p>
            <p>
              <ul>
              <li>Breach this user agreement or any other agreement between you and The New Reserve;</li>
              <li>Violate any law, statute, ordinance, or regulation (for example, those governing financial services, consumer protections, unfair competition, anti-discrimination or false advertising);</li>
              <li>Access the Numey Payment System from a country that is not the United States</li>
              <li>Infringe The New Reserve’s or any third party’s copyright, patent, trademark, trade secret or other intellectual property rights, or rights of publicity or privacy;</li>
              <li>Sell counterfeit goods;</li>
              <li>Act in a manner that is defamatory, trade libelous, threatening or harassing;</li>
              <li>Provide false, inaccurate or misleading information;</li>
              <li>Send or receive what we reasonably believe to be potentially fraudulent funds;</li>
              <li>Refuse to cooperate in an investigation or provide confirmation of your identity or any information you provide to us;</li>
              <li>Control an wallet that is linked to another wallet that has engaged in any of these restricted activities;</li>
              <li>Conduct your business or use the Numey Payment System in a manner that results in or may result in;
                <ul>
                  <li>complaints;</li>
                  <li>requests by buyers (either filed with us or card issuers) to invalidate payments made to you;</li>
                  <li>fees, fines, penalties or other liability or losses to The New Reserve, other The New Reserve customers, third parties or you;</li>
                </ul>
              </li>
              <li>Allow your NPS wallet to have a negative balance;</li>
              <li>Take any action that imposes an unreasonable or disproportionately large load on our websites, software, systems (including any networks and servers used to provide any of the Numey Payment System) operated by us or on our behalf;</li>
              <li>Facilitate any viruses, trojan horses, malware, worms or other computer programming routines that attempts to or may damage, disrupt, corrupt, misuse, detrimentally interfere with, surreptitiously intercept or expropriate, or gain unauthorized access to any system;</li>
              <li>Use an anonymizing proxy; use any robot, spider, other automatic device, or manual process to monitor or copy our websites without our prior written permission; or use any device, software or routine to bypass our robot exclusion headers;</li>
              <li>Interfere or disrupt or attempt to interfere with or disrupt our websites, software, or systems operated by us or on our behalf;</li>
              <li>Take any action that may cause us to lose any of the services from our Internet service providers, payment partners, or other suppliers or service providers;</li>
              <li>Circumvent any The New Reserve policy; or</li>
              <li>Harass and/or threaten our employees, agents, or other users.</li>
              </ul>
            </p>

            <div className="fw-bold">Actions We May Take if You Engage in Any Restricted Activities</div>
            <p>If we believe that you’ve engaged in any of these activities, we may take a number of actions to protect The New Reserve, its customers and others at any time in our sole discretion. The actions we may take include, but are not limited to, the following:</p>
            <p>
              <ul>
                <li>Terminate this user agreement, limit your NPS wallet (and any linked NPS wallet), and/or deactivate or suspend your NPS wallet (and any linked NPS wallet), immediately and without penalty to us;</li>
                <li>Refuse to provide the Numey Payment System to you in the future;</li>
                <li>Limit your access to our websites, software, systems (including any networks and servers used to provide any of the Numey Payment System) operated by us or on our behalf, your NPS wallet or any of the Numey Payment System;</li>
                <li>Update inaccurate information you provided us;</li>
                <li>Take legal action against you; or</li>
                <li>If you’ve violated our policies, then you’re also responsible for damages to The New Reserve caused by your violation of this policy.</li>
              </ul>
            </p>
            <p>
              If we deactivate your NPS wallet or terminate your use of the Numey Payment System for any reason, we’ll provide you with notice of our actions.
            </p>
            <p>
              You are responsible for all reversals, chargebacks, claims, fees, fines, penalties and other liability incurred by The New Reserve, any The New Reserve customer, or a third party caused by or arising out of your breach of this agreement, and/or your use of the Numey Payment System.
            </p>

            <div className="fw-bold">Court Orders, Regulatory Requirements or Other Legal Process</div>
            <p>
              If we are notified of a court order or other legal process affecting you, or if we otherwise believe we are required to do so in order to comply with applicable law or regulatory requirements, we may be required to take certain actions, including holding payments to/from your NPS wallet, placing a reserve or limitation on your NPS wallet, or releasing your funds. We will decide, in our sole discretion, which action is required of us. Unless the court order, applicable law, regulatory requirement or other legal process requires otherwise, we will notify you of these actions. We do not have an obligation to contest or appeal any court order or legal process involving you or your NPS wallet. When we implement a hold, reserve or limitation as a result of a court order, applicable law, regulatory requirement or other legal process, the hold, reserve or limitation may remain in place longer than 180 days.
            </p>

            <h5>Other Legal Terms</h5>
            <div className="fw-bold">The New Reserve’s Rights</div>
            <p>
              The New Reserve, in its sole discretion, reserves the right to suspend or terminate this user agreement, access to or use of its websites, software, systems (including any networks and servers used to provide any of the Numey Payment System) operated by us or on our behalf or some or all of the Numey Payment System for any reason and at any time upon notice to you and, upon termination of this user agreement, the payment to you of any unrestricted funds held in your NPS wallet.
            </p>

            <div className="fw-bold">Security interest</div>
            <p>As security for the performance of your obligations under this user agreement, you grant to The New Reserve a lien on, and security interest in and to, funds held in your NPS wallet.</p>

            <div className="fw-bold">Amounts owed to The New Reserve</div>
            <p>
              If the balance in your NPS wallet becomes negative for any reason, that negative balance represents an amount that you owe to The New Reserve. The New Reserve may deduct these amounts from funds that are added to your NPS wallet later, either by you or from payments you receive. If you have more than one NPS wallet, we may set off a negative balance in one NPS wallet against a balance in your other NPS wallet(s), including a NPS wallet. If you continue using your NPS wallet when it has a negative balance, you authorize The New Reserve to combine the negative balance with any debit or transaction sent from your wallet when that combination is disclosed to you in advance of initiating the debit or transaction.
            </p>

            <div className="fw-bold">Insolvency proceedings</div>
            <p>If any proceeding by or against you is commenced under any provision of the United States Bankruptcy Code, as amended, or under any other bankruptcy or insolvency law, we’ll be entitled to recover all reasonable costs or expenses (including reasonable legal fees and expenses) incurred in connection with the enforcement of this user agreement.</p>

            <div className="fw-bold">Assumption of rights</div>
            <p>If The New Reserve invalidates and reverses a payment that you made to a recipient (either at your initiative or otherwise), you agree that The New Reserve assumes your rights against the recipient and third parties related to the payment, and may pursue those rights directly or on your behalf, in The New Reserve’s discretion.</p>

            <div className="fw-bold">No waiver</div>
            <p>
              Our failure to act with respect to a breach of any of your obligations under this user agreement by you or others does not waive our right to act with respect to subsequent or similar breaches.
            </p>

            <h5>Indemnification and Limitation of Liability</h5>
            <p>In this section, we use the term “The New Reserve” to refer to The New Reserve LLC and our affiliates, and each of their respective directors, officers, employees, agents, joint venturers, service providers and suppliers. Our affiliates include each entity that we control, we are controlled by or we are under common control with.</p>

            <div className="fw-bold">Indemnification</div>
            <p>
              <b>You must indemnify The New Reserve for actions related to your NPS wallet and your use of the Numey Payment System.</b> You agree to defend, indemnify and hold The New Reserve harmless from any claim or demand (including reasonable legal fees) made or incurred by any third party due to or arising out of your breach of this user agreement, your improper use of the Numey Payment System, your violation of any law or the rights of a third party and/or the actions or inactions of any third party to whom you grant permissions to use your NPS wallet or access our websites, software, systems (including any networks and servers used to provide any of the Numey Payment System) operated by us or on our behalf, or any of the Numey Payment System on your behalf.
            </p>

            <div className="fw-bold">Limitation of liability</div>
            <p>
              <b>The New Reserve’s liability is limited with respect to your NPS wallet and your use of the Numey Payment System.</b> In no event shall The New Reserve be liable for lost profits or any special, incidental or consequential damages (including without limitation damages for loss of data or loss of business) arising out of or in connection with our websites, software, systems (including any networks and servers used to provide any of the Numey Payment System) operated by us or on our behalf, any of the Numey Payment System, or this user agreement (however arising, including negligence), unless and to the extent prohibited by law.
            </p>

            <p>
              Our liability to you or any third parties in any circumstance is limited to the actual amount of direct damages. In addition, to the extent permitted by applicable law, The New Reserve is not liable, and you agree not to hold The New Reserve responsible, for any damages or losses (including, but not limited to, loss of money, goodwill, or reputation, profits, or other intangible losses or any special, indirect, or consequential damages) resulting directly or indirectly from:
            </p>
            <p>
              <ol>
                <li>your use of, or your inability to use, our websites, software, systems (including any networks and servers used to provide any of the Numey Payment System) operated by us or on our behalf, or any of the Numey Payment System;</li>
                <li>delays or disruptions in our websites, software, systems (including any networks and servers used to provide any of the Numey Payment System) operated by us or on our behalf and any of the Numey Payment System;</li>
                <li>viruses or other malicious software obtained by accessing our websites, software, systems (including any networks and servers used to provide any of the Numey Payment System) operated by us or on our behalf or any of the Numey Payment System or any website or service linked to our websites, software or any of the Numey Payment System;</li>
                <li>glitches, bugs, errors, or inaccuracies of any kind in our websites, software, systems (including any networks and servers used to provide any of the Numey Payment System) operated by us or on our behalf or any of the Numey Payment System or in the information and graphics obtained from them;</li>
                <li>the content, actions, or inactions of third parties;</li>
                <li>a suspension or other action taken with respect to your NPS wallet; or</li>
                <li>your need to modify your practices, content, or behavior, or your loss of or inability to do business, as a result of changes to this user agreement or The New Reserve’s policies.</li>
              </ol>
            </p>
            <h5>Disclaimer of Warranty and Release</h5>

            <div className="fw-bold">No warranty</div>
            <p>
              The Numey Payment System are provided “as-is” and without any representation or warranty, whether express, implied or statutory. The New Reserve specifically disclaims any implied warranties of title, merchantability, fitness for a particular purpose and non-infringement.
            </p>

            <p>
              The New Reserve does not have any control over the products or services provided by sellers who accept The New Reserve as a payment method, and The New Reserve cannot ensure that a buyer or a seller you are dealing with will actually complete the transaction or is authorized to do so. The New Reserve does not guarantee continuous, uninterrupted or secure access to any part of the Numey Payment System, and operation of our websites, software, or systems (including any networks and servers used to provide any of the Numey Payment System) operated by us or on our behalf may be interfered with by numerous factors outside of our control. The New Reserve will make reasonable efforts to ensure that requests for electronic debits and credits involving bank wallets, debit cards, credit cards, and check issuances are processed in a timely manner but The New Reserve makes no representations or warranties regarding the amount of time needed to complete processing because the Numey Payment System are dependent upon many factors outside of our control, such as delays in the banking system or the US or international mail service. Some states do not allow the disclaimer of implied warranties, so the foregoing disclaimers may not apply to you.
            </p>

            <div className="fw-bold">Release of The New Reserve</div>
            <p>
              If you have a dispute with any other NPS wallet holder, you release The New Reserve from any and all claims, demands and damages (actual and consequential) of every kind and nature, known and unknown, arising out of or in any way connected with such disputes. In entering into this release you expressly waive any protections (whether statutory or otherwise, for example, California Civil Code § 1542) that would otherwise limit the coverage of this release to include only those claims which you may know or suspect to exist in your favor at the time of agreeing to this release.
            </p>

            <div className="fw-bold">Agreement to Arbitrate</div>

            <p>
              In the event a dispute shall arise between you and The New Reserve LLC, it is hereby agreed that the dispute shall be referred to United States Arbitration & Mediation for arbitration in accordance with United States Arbitration & Mediation Rules of Arbitration. The arbitrator’s decision shall be final and binding and judgment may be entered thereon. In the event a party fails to proceed with arbitration, unsuccessfully challenges the arbitrator’s award, or fails to comply with arbitrator’s award, the other party is entitled of costs of suit including a reasonable attorney’s fee for having to compel arbitration or defend or enforce the award.
            </p>

            <p>You and The New Reserve agree that each of us may bring claims against the other only on an individual basis and not as a plaintiff or class member in any purported class or representative action or proceeding. Unless both you and The New Reserve agree otherwise, the arbitrator(s) may not consolidate or join more than one person’s or party’s claims and may not otherwise preside over any form of a consolidated, representative or class proceeding.</p>

            <h5>Intellectual Property</h5>

            <div className="fw-bold">The New Reserve’s trademarks</div>

            <p>
              "The New Reserve LLC," "The New Reserve," “Numey,” "The Numey Payment System," "NPS," "ǂ," “PayWithNumey.com,” and all logos related to Numey and the Numey Payment System are either trademarks or registered trademarks of The New Reserve LLC or The New Reserve’s licensors. You may not copy, imitate, modify or use them without The New Reserve’s prior written consent. In addition, all page headers, custom graphics, button icons, and scripts are service marks, trademarks, symbols, and/or trade dress of The New Reserve. You may not copy, imitate, modify or use them without our prior written consent. You may use HTML logos provided by The New Reserve for the purpose of directing web traffic to the Numey Payment System. You may not alter, modify or change these HTML logos in any way, use them in a manner that mischaracterizes The New Reserve or the Numey Payment System or display them in any manner that implies The New Reserve’s sponsorship or endorsement. All right, title and interest in and to the The New Reserve websites, any content thereon, the Numey Payment System, the technology related to the Numey Payment System, and any and all technology and any content created or derived from any of the foregoing is the exclusive property of The New Reserve.
            </p>

            <h5>Miscellaneous</h5>
            <div className="fw-bold">Assignment</div>
            <p>
              You may not transfer or assign any rights or obligations you have under this user agreement without The New Reserve’s prior written consent. The New Reserve may transfer or assign this user agreement or any right or obligation under this user agreement at any time.
            </p>

            <div className="fw-bold">Business Days</div>
            <p>
              “Business Day(s)” means Monday through Friday, excluding holidays when The New Reserve’s offices are not considered open for business in the U.S. Holidays include New Year’s Day (January 1), Martin Luther King, Jr.’s Birthday (the third Monday in January), George Washington’s Birthday (the third Monday in February), Memorial Day (the last Monday in May), Independence Day (July 4), Labor Day (the first Monday in September), Columbus Day (the second Monday in October), Veterans Day (November 11), Thanksgiving Day (the fourth Thursday in November) and Christmas Day (December 25). If a holiday falls on a Saturday, The New Reserve observes the holiday on the prior Friday. If the holiday falls on a Sunday, The New Reserve observes the holiday on the following Monday.
            </p>

            <div className="fw-bold">Governing law</div>
            <p>You agree that, except to the extent inconsistent with or preempted by federal law and except as otherwise stated in this user agreement, the laws of the State of Delaware, without regard to principles of conflict of laws, will govern this user agreement and any claim or dispute that has arisen or may arise between you and The New Reserve.</p>

            <div className="fw-bold">Identity authentication</div>
            <p>
              You authorize The New Reserve, directly or through third parties, to make any inquiries we consider necessary to verify your identity. This may include:
            </p>
            <p>
              <ul>
                <li>asking you for further information, such as your date of birth, a taxpayer identification number, your physical address and other information that will allow us to reasonably identify you;</li>
                <li>requiring you to take steps to confirm ownership of your email address or financial instruments;</li>
                <li>ordering a credit report from a credit reporting agency, or verifying your information against third-party databases or through other source; or</li>
                <li>requiring you to provide your driver’s license or other identifying documents.</li>
              </ul>
            </p>
            <p>
              Anti-money laundering and counter-terrorism financing laws may require that The New Reserve verify the​ required identifying information. The New Reserve reserves the right to deactivate, suspend, or limit access to your NPS wallet and/or the Numey Payment System in the event that, after reasonable enquiries, we are unable to obtain information about you required to verify your identity.
            </p>

            <div className="fw-bold">The New Reserve is only a payment service provider</div>

            <p>We act as a payment service provider only. We do not:</p>
            <p>
              <ul>
                <li>Act as an escrow agent with respect to any funds kept in your wallet;</li>
                <li>Act as your agent or trustee;</li>
                <li>Enter into a partnership, joint venture, agency or employment relationship with you;</li>
                <li>Guarantee the identity of any buyer or seller;</li>
                <li>Determine if you are liable for any taxes; or</li>
                <li>Unless otherwise expressly set out in this agreement, collect or pay any taxes that may arise from your use of our services.</li>
              </ul>
            </p>
            <div className="fw-bold">Privacy</div>
            <p>
              Protecting your privacy is very important to us. Please review our <Link to="/privacy">Privacy Statement</Link> in order to better understand our commitment to maintaining your privacy, as well as our use and disclosure of your information.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}
export default UserAgreement

// PersonalInfo.js

import React, { useContext, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import InputMask from "react-input-mask";
import axios from "axios";
import AuthService from "../AuthService";
import { UserContext } from "../UserContext";
import { toast } from "react-toastify";
import moment from "moment";

// function isMinor(date) {
//   const eighteenYearsAgo = moment().subtract(18, "years");
//   const birthday = moment(date);
//   if (eighteenYearsAgo.isBefore(birthday)) {
//     return true;
//   } else {
//     return false;
//   }
// }

function PersonalInfo(props) {
  const { user, setUser, saveData, formData } = UserContext();
  const { getCurrentUser } = AuthService();

  const [username, setUsername] = React.useState("");
  const [first, setFirst] = React.useState("");
  const [middle, setMiddle] = React.useState("");
  const [last, setLast] = React.useState("");
  const [suffix, setSuffix] = React.useState("");
  const [address, setAddress] = React.useState("");
  const [city, setCity] = React.useState("");
  const [state, setState] = React.useState("");
  const [postalCode, setPostalCode] = React.useState("");
  const [dob, setDob] = React.useState("");
  const [ssn, setSsn] = React.useState("");

  const [loading, setLoading] = React.useState(false);

  const navigate = useNavigate();
  const { signUp } = AuthService();

  const email = formData.data.email;
  const password = formData.data.password;
  const phone = formData.data.phone;
  const agreedToTOS = formData.data.agreedToTOS
    
  useEffect(()=>{
    function isMinor(date) {
      const eighteenYearsAgo = moment().subtract(18, "years");
      const birthday = moment(date);
      if (eighteenYearsAgo.isBefore(birthday)) {
        toast.warning(
          "You must be over 18 years of age to create a wallet."
        );
      } 
    }
     isMinor(dob);
  },[dob])

  const handleFirstNameChange = (e) => {
    setFirst(e.target.value);
    const fullName = e.target.value + last;
    const username = fullName.replace(/\s+/g, "").toLowerCase();
    setUsername(username);
  };
  const handleLastNameChange = (e) => {
    setLast(e.target.value);
    const fullName = first + e.target.value;
    const username = fullName.replace(/\s+/g, "").toLowerCase();
    setUsername(username);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    console.log("email",email)
    const user = {
      first: first,
      middle: middle,
      last: last,
      suffix: suffix,
      username: username,
      dob:dob,
      address: address,
      city: city,
      state: state,
      postalCode: postalCode,
      ssn: ssn,
      email:email,
      password: password,
      phone:phone,
      agreedToTOS:agreedToTOS,
    };

    signUp(user)
      .then(() => {
        toast.success("Welcome to Numey!");
        navigate("/two-factor-auth")
      })
      .catch((err) => {
        if (err.response) {
          toast.warning(err.response.data.message);
        } else {
          toast.warning("There was a problem signing up. Please try again.");
        }
        setLoading(false);
      });
  };

  return (
    <>
      <div className="row mb-3">
        <div className="col-6">
          <label className="form-label" htmlFor="first">
            First Name<span className="text-danger">*</span>
          </label>
          <input
            className="form-control"
            id="first"
            name="first"
            placeholder="Enter Your First Name"
            value={first}
            onChange={handleFirstNameChange}
            required="required"
          />
        </div>
        <div className="col-6">
          <label className="form-label" htmlFor="last">
            Last Name<span className="text-danger">*</span>
          </label>
          <input
            className="form-control"
            id="last"
            name="last"
            placeholder="Enter Your Last Name"
            value={last}
            onChange={handleLastNameChange}
            required="required"
          />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-12">
          <label className="form-label" htmlFor="middle">
            Middle Name
          </label>
          <input
            className="form-control"
            id="middle"
            name="middle"
            placeholder="Enter Your Middle Name"
            value={middle}
            onChange={(e) => setMiddle(e.target.value)}
          />
        </div>

        <div className="col-12">
          <label className="form-label" htmlFor="suffix">
            Suffix
          </label>
          <input
            className="form-control"
            id="suffix"
            name="suffix"
            placeholder="Enter Your Suffix"
            value={suffix}
            onChange={(e) => setSuffix(e.target.value)}
          />
        </div>
      </div>
      <div className="col-12 mb-3">
        <label className="form-label" htmlFor="username">
          Username<span className="text-danger">*</span>
        </label>
        <div className="input-group mb-3">
          <input
            className="form-control"
            id="username"
            name="username"
            placeholder="Enter Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required="required"
          />
        </div>
      </div>
      <div className="row">
        <div className="col-12 mb-3">
          <label className="form-label" htmlFor="dob">
            Address<span className="text-danger">*</span>
          </label>
          <input
            className="form-control"
            id="Address"
            type="Address"
            name="Address"
            placeholder="Enter Your Address"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            required="required"
          />
        </div>
        <div className="col-12 mb-3">
          <label className="form-label" htmlFor="dob">
            Date of Birth<span className="text-danger">*</span>
          </label>
          <input
            className="form-control"
            id="dob"
            type="date"
            name="dob"
            placeholder=""
            value={dob}
            onChange={(e) => setDob(e.target.value)}
            required="required"
          />
        </div>
      </div>

      {/*          <div className="col-12 mb-3">
                  <label className="form-label" htmlFor="ssn">Social Security Number</label>
                  <InputMask
                    className="form-control"
                    id="ssn"
                    name="ssn"
                    placeholder="444-33-5555"
                    value={ssn}
                    onChange={e => setSsn(e.target.value)}
                    mask="999-99-9999"
                    maskplaceholder={""}
                    maskchar=" "/>
                  <div className="form-text">SSN only required for carrying a balance over <span className="nmy">ǂ</span>25,000 NMY. We encrypt your SSN for your security.</div>
                </div>*/}
      {/* 
                <div className="row">
                  <div className="col-12 mb-3">
                    <label className="form-label" htmlFor="address">
                      Street Address & Unit Number
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      className="form-control"
                      id="address"
                      name="address"
                      value={address}
                      onChange={(e) => setAddress(e.target.value)}
                      required="required"
                    />
                    <div className="form-text">
                      Numey wallet users must be individuals who reside within
                      the United States.
                    </div>
                  </div>
                </div> */}
      <div className="row">
        <div className="col-12">
          <label className="form-label" htmlFor="city">
            City<span className="text-danger">*</span>
          </label>
          <input
            className="form-control"
            id="city"
            name="city"
            value={city}
            placeholder="Enter Your City "
            onChange={(e) => setCity(e.target.value)}
            required="required"
          />
        </div>
        <div className="col-12">
          <label className="form-label" htmlFor="state">
            State<span className="text-danger">*</span>
          </label>
          <select
            className="form-select form-control"
            id="state"
            name="state"
            value={state}
            placeholder="Enter Your State"
            onChange={(e) => setState(e.target.value)}
            required="required"
          >
            <option value=""></option>
            <option value="AL">AL</option>
            <option value="AK">AK</option>
            <option value="AS">AS</option>
            <option value="AZ">AZ</option>
            <option value="AR">AR</option>
            <option value="CA">CA</option>
            <option value="CO">CO</option>
            <option value="CT">CT</option>
            <option value="DE">DE</option>
            <option value="DC">DC</option>
            <option value="FL">FL</option>
            <option value="GA">GA</option>
            <option value="GU">GU</option>
            <option value="HI">HI</option>
            <option value="ID">ID</option>
            <option value="IL">IL</option>
            <option value="IN">IN</option>
            <option value="IA">IA</option>
            <option value="KS">KS</option>
            <option value="KY">KY</option>
            <option value="LA">LA</option>
            <option value="ME">ME</option>
            <option value="MD">MD</option>
            <option value="MA">MA</option>
            <option value="MI">MI</option>
            <option value="MN">MN</option>
            <option value="MS">MS</option>
            <option value="MO">MO</option>
            <option value="MT">MT</option>
            <option value="NE">NE</option>
            <option value="NV">NV</option>
            <option value="NH">NH</option>
            <option value="NJ">NJ</option>
            <option value="NM">NM</option>
            <option value="NY">NY</option>
            <option value="NC">NC</option>
            <option value="ND">ND</option>
            <option value="MP">MP</option>
            <option value="OH">OH</option>
            <option value="OK">OK</option>
            <option value="OR">OR</option>
            <option value="PA">PA</option>
            <option value="PR">PR</option>
            <option value="RI">RI</option>
            <option value="SC">SC</option>
            <option value="SD">SD</option>
            <option value="TN">TN</option>
            <option value="TX">TX</option>
            <option value="UT">UT</option>
            <option value="VT">VT</option>
            <option value="VA">VA</option>
            <option value="VI">VI</option>
            <option value="WA">WA</option>
            <option value="WV">WV</option>
            <option value="WI">WI</option>
            <option value="WY">WY</option>
          </select>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-12 mb-3">
          <label className="form-label" htmlFor="postalCode">
            Postal Code<span className="text-danger">*</span>
          </label>
          <input
            className="form-control"
            id="postalCode"
            name="postalCode"
            value={postalCode}
            placeholder="Enter Your Postal Code"
            onChange={(e) => setPostalCode(e.target.value)}
            required="required"
          />
        </div>
      </div>
      <div className="col-12 mt-4">
        <div className="text-end"></div>
      </div>
      <button
        className="btn btn-primary"
        // disable={loading}
        onClick={(e)=>handleSubmit(e)}
        type="button"
      >
        Save & Continue
      </button>
    </>
  );
}

export default PersonalInfo;

import React from "react";
import { useState } from "react";
import { Link, NavLink } from "react-router-dom";

import { CiBank } from "react-icons/ci";
import { FaArrowsRotate, FaUsers, FaWallet, FaLocationArrow, FaUser, FaList } from "react-icons/fa6";
// import { MdPhoneAndroid } from "react-icons/md";
import { IoCopyOutline } from "react-icons/io5";

import InputMask from "react-input-mask";
import axios from 'axios';
import Cookies from 'universal-cookie';
import { toast } from 'react-toastify';
// import playSvg from '../images/play.svg'
import logoHorizontal from '../images/logo-horizontal.jpg';

import Modal from "react-bootstrap/Modal";
import { UserContext } from "../UserContext";

// import ChargeButton from '../Coinbase/ChargeButton';

function Sidebar() {
  // Invite friends Modal
  const {user} = UserContext();
  const [Inviteshow, setInviteShow] = useState(false);
  const handleInviteClose = () => setInviteShow(false);
  const handleInviteShow = () => setInviteShow(true);
  const [phone, setPhone] = React.useState("");

  const [GetAppshow, setGetAppshow] = useState(false);
  const handleGetAppClose = () => setGetAppshow(false);
  const handleGetAppshow = () => setGetAppshow(true);

  const [conversionRateToDollars, setConversionRateToDollars] = React.useState(0);
  const [prettyConversionRateToDollars, setPrettyConversionRateToDollars] = React.useState("");

  const nameImgUrl = `https://ui-avatars.com/api/?background=0D8ABC&color=fff&name=${user?.first}+${user?.last}`

  const sendAppStoreUrl = (e) => {
    e.preventDefault();
    
    const url = process.env.REACT_APP_BASE_DOMAIN + '/api/send-app-store-url';

    axios.get(url, { params: { phone: phone } }).then(response => {
      toast.success("Link to iOS app store sent!");
      }).catch(err => {
        toast.warning("There was a problem sending you the link. Try again.")
      })  
  }

  React.useEffect(() => {
    // GET CONVERSION RATE
    const url = process.env.REACT_APP_BASE_DOMAIN + "/api/exchange-rates/current";

    axios.get(url)
      .then(response => {
        setConversionRateToDollars(response.data.conversionRateToDollars);
        setPrettyConversionRateToDollars(response.data.prettyConversionRateToDollars);
      })
      .catch(err => {
        toast.warning(err.response.data.message)
      });
  }, [])

  const resendEmailConfirmation = (e) => {
    e.preventDefault();
    
    const cookies = new Cookies();
    const authToken = cookies.get('nJWT');
    const url = process.env.REACT_APP_BASE_DOMAIN + '/api/resend-confirm-email';
    const config = { headers: { Authorization: `Bearer ${authToken}` } };

    axios.put(url, {}, config).then(response => {
      toast.success("Confirm email email resent.");
      }).catch(err => {
        toast.warning("There was a problem resending your email confirmation email. Please try again.")
      })  
  }


  return (

    <div className="sidebar">
      {user &&(<>
        <div className="sidebarblock">
          <div className="logobar">
            <Link to="/">
              <img src={ logoHorizontal } className="logo" alt="logo" />
            </Link>
          </div>
          <div className="tophead">
            <div className="userdetail">
              <h5>{user.first} {user.last}</h5>
              <Link to="/me" className="btnprofile">@{user.username}</Link>
              <div className="text-light">
                <b className="mt-4 me-2">ǂ{user.balanceInScript}</b> 
                <small>(${(parseFloat(user.balanceInScript)*conversionRateToDollars).toFixed(2)})</small>
              </div>
            </div>
          </div>
          <div className="sidebar-menubar">
            <ul>
              <li><NavLink to="/wallet" className="btnprofile"><FaList /> Activity</NavLink></li>
              <li><NavLink to="/me" className="btnprofile"><FaWallet /> Profile </NavLink></li>
              {user.verified &&
                <>
                  <li><NavLink to="/send" className="btnprofile mt-1"><FaLocationArrow /> Send Numey</NavLink></li>
                  {!user.externals[0] &&
                    <>
                      <li><a className="btnprofile disabled pe-none mt-1" disabled><CiBank /> Buy Numey</a></li>
                      <li><a className="btnprofile disabled pe-none mt-1" disabled><FaArrowsRotate /> Sell Numey</a></li>
                      <div className="card mt-3">
                        <div className="card-body">
                          <div className="mb-2 text-sm">Complete Registration</div>
                          <p class="card-text">
                            {!user.identityVerifiedAt ? <Link to="/verify-identity" className="btn btn-primary w-100 mt-1" disabled>Verify Your Identity</Link> : <button className="btn btn-secondary mt-1 w-100" disabled>Identity Verified</button> }
                            {!user.emailConfirmedAt ? <span onClick={resendEmailConfirmation} className="btn btn-primary w-100 mt-1">Resend Confirm Email</span> : <button className="btn btn-secondary mt-1 w-100" disabled>Email Verified</button> }
                            {!user.externals[0] ? <Link to="/add-funding-source" className="btn btn-primary mt-1 w-100">Add a Funding Source</Link> : <button className="btn btn-secondary mt-1 w-100" disabled>Funding Source Present</button> }
                          </p>
                        </div>
                      </div>
                    </>
                  }
                </>
              }
              {!user.verified &&
                <>
                  <li><a className="btnprofile pe-none disabled mt-1" disabled><FaLocationArrow /> Send Numey</a></li>
                  <li><a className="btnprofile pe-none disabled mt-1" disabled><CiBank /> Buy Numey</a></li>
                  <li><a className="btnprofile pe-none disabled mt-1" disabled><FaArrowsRotate /> Sell Numey</a></li>
                  <div className="card mt-3">
                    <div className="card-body">
                      <div className="mb-2 text-sm">Complete Registration</div>
                      <p class="card-text">
                        {!user.identityVerifiedAt ? <Link to="/verify-identity" className="btn btn-primary w-100 mt-1" disabled>Verify Your Identity</Link> : <button className="btn btn-secondary mt-1 w-100" disabled>Identity Verified</button> }
                        {!user.emailConfirmedAt ? <span onClick={resendEmailConfirmation} className="btn btn-primary w-100 mt-1">Resend Confirm Email</span> : <button className="btn btn-secondary mt-1 w-100" disabled>Email Verified</button> }
                        {!user.externals[0] ? <Link to="/add-funding-source" className="btn btn-primary mt-1 w-100">Add a Funding Source</Link> : <button className="btn btn-secondary mt-1 w-100" disabled>Funding Source Present</button> }
                      </p>
                    </div>
                  </div>
                </>
              }
              {user.verified && user.externals[0] &&
                <>
                  <li><NavLink to="/buy" className="btnprofile mt-1"><CiBank /> Buy Numey</NavLink></li>
                  <li><NavLink to="/sell" className="btnprofile mt-1"><FaArrowsRotate /> Sell Numey</NavLink></li>
                </>
              }
              <div className="ms-3 mt-2"><small className="text-light pe-none text-sm">ǂ1 NMY / ${prettyConversionRateToDollars} USD</small></div>
            </ul>
            
            <div className="ms-3 bottombar">
              <ul>
                <li><Link className="menulink" to="/ourmission">Our Mission</Link></li>
                <li><Link className="menulink" to="/howitworks">How it Works</Link></li>
                <li><Link className="menulink" to="/faq">FAQ</Link></li>
                <li><Link className="menulink" to="/blog">Blog</Link></li>
                <li><Link type="button" onClick={handleInviteShow} className="btnprofile"><FaUsers /> Invite Friends</Link></li>
                {/*
                <li><Link type="button" className="btnprofile" onClick={handleGetAppshow}><MdPhoneAndroid /> Download App</Link></li>
                */}
              </ul>
            </div>
          </div>
        </div>

        <div className="mobilebottombar-widget">
          <ul>
            <li><NavLink to="/wallet" className="btnprofile"><FaWallet /> My Wallet</NavLink></li>
            <li><NavLink to="/send" className="btnprofile"><FaLocationArrow /> Send Numey</NavLink></li>
            <li><NavLink to="/buy" className="btnprofile"><CiBank /> Buy Numey</NavLink></li>
            <li><NavLink to="/sell" className="btnprofile"><FaArrowsRotate /> Sell Numey</NavLink></li>
            <li><NavLink to="/me" className="btnprofile"><FaUser /> Profile</NavLink></li>
          </ul>
        </div>

        {/* Invite friends Modal */}
        <Modal
          show={Inviteshow}
          onHide={handleInviteClose}
          centered
          className="NewuiModal"
        >
          <Modal.Header closeButton>
            <h5>Invite Friends</h5>
            <p>Invite your friends to join Numey</p>
          </Modal.Header>
          <Modal.Body>
            <div className="shadowboxes">
              <ul>
                <li><h5 className="srno">I'm using a new inflation-linked payment system called Numey. https://www.paywithnumey.com/</h5></li>
              </ul>
            </div>
            <button className="btncopy" type="button"><IoCopyOutline /> Copy Invite Link</button>
          </Modal.Body>
        </Modal>

        {/* Get App Modal  */}
        <Modal
          show={GetAppshow}
          onHide={handleGetAppClose}
          centered
          className="NewuiModal"
        >
          <Modal.Header closeButton>
            <h5>Get App</h5>
            <p>Enter your phone number and we will text you a link to the apps</p>
          </Modal.Header>
          <Modal.Body>
            <div className="getappbtns">
              <p>
                <InputMask
                  className="form-control"
                  id="phone"
                  type="tel"
                  name="phone"
                  placeholder="+1(555) 333-4444"
                  value={phone}
                  onChange={e => setPhone(e.target.value)}
                  mask="+1\(999) 999-9999"
                  maskplaceholder={""}
                  maskchar=" "
                  required="required" />
              </p>
              <button className="btn btn-primary" onClick={sendAppStoreUrl} type="button" id="button-addon2">Receive App Link</button>
            </div>
          </Modal.Body>
        </Modal>
      </>
     )}
    </div>
  );
}
export default Sidebar;

import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import axios from 'axios';
import Cookies from 'universal-cookie';
import { toast } from 'react-toastify';

function AdminTotals() {
  const [totalScript, setTotalScript] = useState("");
  const [totalDollars, setTotalDollars] = useState("");
  const [totalReserve, setTotalReserve] = useState("");
  const [usersCount, setUsersCount] = useState("");
  const [usersVerifiedCount, setUsersVerifiedCount] = useState(0);
  const [txsCount, setTxsCount] = useState("");
  const [buysCount, setBuysCount] = useState("");
  const [sellsCount, setSellsCount] = useState("");
  const [sendsCount, setSendsCount] = useState("");
  const [reconciliationDiff, setReconciliationDiff] = useState("");
  const [totalBuyMinusSell, setTotalBuyMinusSell] = useState("");
  const [totalFees, setTotalFees] = useState("");
  const [reserveDifference, setReserveDifference] = useState("");
  const [reserveMargin, setReserveMargin] = useState("");

  React.useEffect(() => {
    const cookies = new Cookies();
    const authToken = cookies.get('nJWT');
    const config = { headers: { Authorization: `Bearer ${authToken}` } };

    const totalsUrl = process.env.REACT_APP_BASE_DOMAIN + "/api/admin/totals";
    axios.get(totalsUrl, config)
      .then(totals => {
        setTotalScript(totals.data.totalScript)
        setTotalDollars(totals.data.totalDollars)
        setTotalReserve(totals.data.totalReserve)
        setUsersCount(totals.data.usersCount)
        setUsersVerifiedCount(totals.data.usersVerifiedCount);
        setTxsCount(totals.data.txsCount);
        setBuysCount(totals.data.buysCount);
        setSellsCount(totals.data.sellsCount);
        setSendsCount(totals.data.sendsCount);
        setReconciliationDiff(totals.data.reconciliationDiff);
        setTotalBuyMinusSell(totals.data.totalBuyMinusSell);
        setTotalFees(totals.data.totalFees);
        console.log(totals.data.totalReserve)
        setReserveDifference((parseFloat(totals.data.totalReserve) - parseFloat(totals.data.totalDollars)).toFixed(2))
        // setReserveMargin((totals.data.totalReserve - total.data.totalDollars)/total.data.totalDollars)
      }).catch(err => {
        console.log(err)
        toast.warning(err.response.data.message)
      });
  }, []);


  return (
    <Row>
      <Col sm={4}>
        <div className="card mb-4">
          <div className="card-body">
            <div className="card-text">
              <h5>Reserve</h5>
              <div>
                <b>Total Numey</b>: ǂ{totalScript}
              </div>
              <div>
                <b>Total $ Value</b>: ${totalDollars}
              </div>
              <div>
                <b>Total Reserve</b>: ${totalReserve}
              </div>
              <div>
                <b>Diff $</b>: <span className={`${reserveDifference > 0 ? 'text-success' : 'text-danger'}`}>${reserveDifference}</span>
              </div>
              {/*<div>
                <b>Diff %</b>: {((totalReserve - totalDollars)/totalDollars*100).toFixed(4)}%
              </div>*/}
            </div>
          </div>
        </div>
      </Col>
      <Col sm={4}>
        <div className="card mb-4">
          <div className="card-body">
            <div className="card-text">
              <h5>Numey</h5>
              <div>
                <b>Total Buys - Sells</b>: ǂ{totalBuyMinusSell}
              </div>
              <div>
                <b>Reconciliation</b>: ǂ{reconciliationDiff}
              </div>
              <div>
                <b>Total Fees</b>: ǂ{totalFees}
              </div>
            </div>
          </div>
        </div>
      </Col>
      <Col sm={4}>
        <div className="card mb-4">
          <div className="card-body">
            <div className="card-text">
              <h5>Users</h5>
              <div>
                <b>Total</b>: {usersCount}
              </div>
              <div>
                <b>Verified</b>: {usersVerifiedCount}
              </div>
              <div>
                <b>Total Txs</b>: {txsCount}
              </div>
              <div>
                <b>Total Buys</b>: {buysCount}
              </div>
              <div>
                <b>Total Sells</b>: {sellsCount}
              </div>
              <div>
                <b>Total Sends</b>: {sendsCount}
              </div>
            </div>
          </div>
        </div>
      </Col>
    </Row>
  )
}
export default AdminTotals;

//- HOME
import React from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';

function Home() {
  const [phone] = React.useState("");

  const sendAppStoreUrl = (e) => {
    e.preventDefault();

    const url = process.env.REACT_APP_BASE_DOMAIN + '/api/send-app-store-url';

    axios.get(url, { params: { phone: phone } }).then(response => {
      toast.success("Link to iOS app store sent!");
    }).catch(err => {
      toast.warning("There was a problem sending you the link. Try again.")
    })
  }

  return(
    <div className="py-lg-5 m-lg-0">
      <section className="px-lg-5">
        <div className="text-center">
          <div className="col-4 offset-4">
            <img src="/icons/android-chrome-512x512.png" className="img-fluid" />
          </div>
          <h1 className="mb-4">The First Hyperstable Currency</h1>
          <div className="lead">
            <b>The Numey (NMY)</b> is an Asset Reserve Electronic Currency
            <br />Secure, Non-Inflationary, Off Chain, and Fully Independent.
          </div>
        </div>
      </section>

      <hr className="mt-5 mb-5" />

      <section className="container">
        <hr className="mt-5 mb-5" />
        <div className="row">
          <div className="col-lg-10 offset-lg-1">
            <h1 className="my-5 text-center">Numey is Money <br/> Not an Investment</h1>
            <div className="row">
              <div className="col-lg-6">
                <p>Numey is also built to, theoretically, never experience inflation. A US Dollar in 1956 was worth 10x what is worth today. And in 1991 a US Dollar was worth double what it is today. This is not a secret. Those who manage national currencies deliberately reduce their value each year.</p>
                <p>Numey is different.</p>
                <p>You might think, when you look at it, that Numey's value is "increasing" — but do not be decieved by graphs and numbers. The value of Numey is not going up in real terms. What is happening is the US Dollar, and all other national fiat currencies, are losing value while the Numey stay's level.</p>
                <p>Numey is not an investment. It will never "go up" really. Only its "nominal value" goes up. Its real value stays the same.</p>
              </div>
              <div className="col-lg-6">

                <p>For example, if you bought enough Numey to buy a new car in in 1991, today you would have enough money in your Numey wallet to buy the same new car even though the price tag (in US Dollars) would have doubled.</p>
                <p>Do not be decieved. You would not have made even one penny in real terms. Only in nominal terms, would you have apparently doubled your money.</p>
                <p>Instead of thinking of the value of Numey "going up," always think, instead, that the US Dollar and other national currencies are going down (because they are).</p>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-5 text-center">
          <Link className="btn btn-primary mb-3 mb-lg-0" to="/about-money">Explore the Numey White Paper</Link>
        </div>
      </section>

      <hr className="mt-5 mb-5" />

      <section className="container">
        <div className="row">
          <div className="col-8 offset-2 text-center mb-5">
            <h1>The Fine Print</h1>
            <p>When you build something new, its important to be clear about what it is and what it isn't. Here's some important pieces of information about what the Numey is and how it works.</p>
          </div>
          <div className="col-12">
            <div className="row">
              <div className="col-lg-6">
                <h3>⚠️ Financial Risks to Using Numey</h3>
                <ul>
                  <li><b>There are risks to purchasing and transacting in Numey</b>.</li>
                  <li>The value of the Numey is independent from the value of the US Dollar.</li>
                  <li>The value of the Numey can fluctuate compared to the value of the US Dollar.</li>
                  <li>The value of the Numey can fluctuate compared to the value of its asset reserve.</li>
                  <li>The value of the Numey can even fall relative to the value of the US Dollar.</li>
                  <li>The value of the Numey can even fall relative to its asset reserve.</li>
                  <li>The New Reserve LLC cannot provide assurances that you will be able to sell Numey for the same amount of equivalent US Dollars you bought it for.</li>
                </ul>
              </div>
              <div className="col-lg-6">
                <h3>❓ What is the Numey?</h3>
                <ul>
                  <li>The Numey is an asset reserve electronic currency.</li>
                  <li>Neither the Numey nor the Numey payment network it operates is a bank.</li>
                  <li>The Numey is not a cryptocurrency like Bitcoin or Ethereum.</li>
                  <li>Numey are not mined or produced cryptographically.</li>
                  <li>Numey are not tracked on a block chain or public ledger.</li>
                  <li>Numey come into existence when someone buys them from the Numey payment system.</li>
                  <li>Numey go out of existence when someone sells them back to the Numey payment system.</li>
                  <li>Numey transactions are private and certified by the Numey payment network system.</li>
                </ul>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-lg-6">
                <h3>💳 Your Electronic Numey Wallet</h3>
                <ul>
                  <li>Your Numey wallet is not a bank account.</li>
                  <li>Your Numey wallet is a storage of electronic currency.</li>
                  <li>You do not earn interest from holding Numey in your electronic Numey wallet.</li>
                  <li>Balances of Numey held in your Numey wallet are not insured by the FDIC or any federal agency or program.</li>
                  <li>The Numey payment network is managed by The New Reserve LLC.</li>
                  <li>The New Reserve LLC is not a bank nor a member of the Federal Reserve bank system.</li>
                  <li>The Numey payment network is independent from the Federal Reserve banking system.</li>
                  <li>The New Reserve LLC complies with all state and federal law regarding money exchange and transmission including the Bank Secrecy Act (BSA), Know Your Customer, and Anti-Money Laundering (KYC/AML) regulations.</li>
                  <li>Your Numey wallet is protected by bank-level security and encryption.</li>
                  <li>Even with bank-level security and encryption, secure access to Numey wallets cannot guaranteed 100%. Therefore there are risks holding Numey in your Numey wallet.</li>
                </ul>
              </div>
            </div>
            <div className="mt-5 text-center">
              <Link className="btn btn-outline-secondary me-3 mb-3 mb-lg-0" to="/the-numey">Learn More</Link>
              <Link to="/subscribe" className="btn m-auto btn-primary py-3 px-4">
                Subscribe for Updates
              </Link>
              {/*<Link className="btn btn-primary mb-3 mb-lg-0" to="/sign-up">Join Now for Free</Link>
              <div className="form-text mt-4">Sign up today.</div>*/}
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Home;

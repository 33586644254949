import React,{useEffect} from 'react';
import {ThemeProvider} from './hooks/useThemeContext';
import AppRouter from './AppRouter';
import {BrowserRouter as Router} from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer} from 'react-toastify';
import AuthService from "./AuthService";
import axios from 'axios';

import {UserContext} from './UserContext';

function App () {
  const { getCurrentUser } = AuthService();
  const { setUser, setExchangeRate } = UserContext();

  useEffect(() => {
    const fetchCurrentUser = async () => {
      try {
        await getCurrentUser().then(user => {
          console.log('setting current user')
          setUser(user)
        });
      } catch(err){
        console.error('Error fetching current user:', err);
      }
    }
    fetchCurrentUser()

    const fetchCurrentExchangeRate = async () => {
      const url = process.env.REACT_APP_BASE_DOMAIN + "/api/exchange-rates/current";

      axios.get(url)
        .then(response => {
          console.log('setting exchange rate')
          // setPrettyConversionRateToDollars(response.data.prettyConversionRateToDollars);
          setExchangeRate(response.data.conversionRateToDollars)
        })
        .catch(err => {
          console.error('Error fetching exchange rate:', err);
        });
    }
    fetchCurrentExchangeRate();
  }, []);

  return (
    <div className="App">
      <ToastContainer />
      <ThemeProvider>
        <Router>
          <AppRouter />
        </Router>
      </ThemeProvider>
    </div>
  );
}
export default App;

import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import { toast } from 'react-toastify';
import axios from 'axios';
import { Chart as ChartJS, LineElement, PointElement, CategoryScale, LinearScale } from 'chart.js'
import { Line } from 'react-chartjs-2';

import useInterval from '../useInterval'

ChartJS.register(
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement
)

function NumeyValueChart() {

  const [rate, setRate] = useState();
  const [sneakerPriceInDollars, setSneakerPriceInDollars] = useState();
  const [heelPriceInDollars, setHealPriceInDollars] = useState();

  useEffect(() => {
    // Fetch Exchange Rates
    const url = process.env.REACT_APP_BASE_DOMAIN + "/api/exchange-rates/current";

    axios.get(url)
      .then(response => {
        setRate(response.data.conversionRateToDollars)
        setSneakerPriceInDollars((130 * response.data.conversionRateToDollars).toFixed(8));
        setHealPriceInDollars((55 * response.data.conversionRateToDollars).toFixed(8));
      }).catch(err => {
        console.log(err);
        toast.warning(err.response.data.message)
      });

  }, []);

  const secondsInAMonth = 30*24*60*60
  const avgInflationRate = 0.07;

  const increaseDollarPrices = () => {
    const increase = 1 + avgInflationRate/secondsInAMonth;
    setSneakerPriceInDollars((prevState) => (prevState * increase).toFixed(8))
    setHealPriceInDollars((prevState) => (prevState * increase).toFixed(8))
  }

  useInterval(increaseDollarPrices, 1000);

  return (
    <section className="container pdd">
      <div className="row mb-5">
        <h2 className="fw-bolder fs-38 h-after mb-4">Inflation In Real Time</h2>
        <div className="col-lg-10 offset-lg-1 mb-5">
          <small>In an inflationary currency, every second, prices are going up, up, up like a clock ticking. Every second, your money can buy you less and less and less. That means your <b>Purchasing Power</b> is shrinking little by little. But, by being <b>Inflation-Linked</b> the Numey (ǂNMY) protects the purchasing power of your income and savings. Start your inflation-linked wallet today.</small>
        </div>
        <div className="col-lg-8 offset-lg-2">
          <div className="row mb-5">
            <div className="col-sm-5 offset-sm-1">
              <div className="text-center">
                <h4 className="mb-4">A Pair of Sneakers</h4>
                <h3><span className="nmy">ǂ</span>130.00 NMY</h3>
                <div className="text-muted lead mb-4">${sneakerPriceInDollars} USD</div>
                <img src="/images/home/sneakers.jpg" className="img-fluid mb-5" />
              </div>
            </div>
            <div className="col-sm-5 offset-sm-1">
              <div className="text-center">
                <h4 className="mb-4">A Tube of Lipstick</h4>
                <h3><span className="nmy">ǂ</span>55.00 NMY</h3>
                <div className="text-muted lead mb-3">${heelPriceInDollars} USD</div>
                <img src="/images/home/lipstick.jpg" className="img-fluid mb-5" />
              </div>
            </div>
            <div className="d-flex justify-content-center mt-5">
            <Link className="btn btn-primary" to="/subscribe">Subscribe for Updates</Link>
{/*               <Link className="btn btn-primary" to="/sign-up">Try Numey Out For Free</Link> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
export default NumeyValueChart;

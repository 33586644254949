import React from "react";
import { Link } from "react-router-dom";
import { Outlet } from "react-router-dom";

import AdminTotals from "./AdminTotals";

function Admin() {
  return (
    <div className="row mx-1">
      <div className="col-12">
        <AdminTotals />
        <ul className="nav">
          <li className="nav-item">
            <Link className="nav-link" to="/admin-dashboard/users">Users</Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/admin-dashboard/txs">Txs</Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/admin-dashboard/transfers">Transfers</Link>
          </li>
        </ul>
        <Outlet />
      </div>
    </div>
  )
}
export default Admin;

import React from "react";
import { Outlet } from "react-router-dom";
import Navbar from "../Navbar";
import Footer from "../Footer";
const GuestLayout = () => {
  return (
    <>
      <div className="landingpage">
        <Navbar />
        <Outlet />
        <Footer />
      </div>
    </>
  );
};
export default GuestLayout;

// AdminTx.js

import React from "react";
import moment from 'moment'
import Source from "./Source";
import Target from "./Target";


function AdminTx(props) {
  // const [showConfirmReverse, setShowConfirmReverse] = React.useState(false)
  // const [reversedAt, setReversedAt] = React.useState(props.tx.reversedAt)
  
  // function reverseTx(tx) {
  //   const url = process.env.REACT_APP_BASE_DOMAIN + `/api/admin/txs/${tx.id}/reverse`;
  //   const body = { id: user.id };

  //   axios.put(url, body, config)
  //     .then(user => {
  //       setShowConfirmReverse(false)
  //       setReversedAt(response.data.reversedAt);
  //       toast.success("Tx reversed.")
  //     }).catch(err => {
  //       toast.warning("There was a problem reversing this transaction.")
  //     });
  //     return
  //   }
  // }

  return (
    <tr>
      <td>{moment(props.tx.createdAt).format('MM-DD-YY hh:mma')}</td>
      <td><Source tx={props.tx}/></td>
      <td><Target tx={props.tx}/></td>
      <td>{props.tx.amount}</td>
      <td>
        {props.tx.processedAt &&
          <span>{moment(props.tx.processedAt).format('MM-DD-YY hh:mma')}</span>
        }
        {props.tx.failedAt &&
          <span>{moment(props.tx.failedAt).format('MM-DD-YY hh:mma')}</span>
        }
        {!props.tx.processedAt && !props.tx.failedAt &&
          <span>Not Processed</span>
        }
      </td>
     {/* <td>
        {props.tx.processedAt && !props.tx.reversedAt &&
          <span>
            {!showConfirmReverse && 
              <a href="#" className="btn btn-outline-danger btn-sm" onClick={() => setShowConfirmReverse(true)}>Reverse</a> 
            }
            {showConfirmProcess &&
              <span>
                <a href="#" className="btn btn-sm btn-outline-info" onClick={() => reverseTx(props.tx)}>Confirm</a>
                <a href="#" className="btn btn-sm btn-outline-secondary" onClick={() => setShowConfirmReverse(false)}>Cancel</a>
              </span>
            }
          </span>
        }
      </td>*/}
    </tr>
  )
}

export default AdminTx;

import React from "react";
import { Outlet } from "react-router-dom";
import Header from "./Header";
import Sidebar from "./Sidebar";

const AuthLayout = () => {
  return (
    <>
      <div className="auth-layout">
        <Header/>
        <Sidebar/>
        <div className="wrapper">
          <div className="content-wrapper">
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
};
export default AuthLayout;

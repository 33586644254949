import Cookies from 'universal-cookie';
import axios from 'axios';

function AuthService() {
  return {
    signUp(user) {
      const expiryDate = new Date(Date.now() + 60 * 60 * 1000 * 24 * 30); // 30 days

      return new Promise((resolve, reject) => {
        const url = process.env.REACT_APP_BASE_DOMAIN + "/api/sign-up";
        axios.post(url, user)
          .then(response => {
            const cookies = new Cookies();
            const cookieConfig = {
              secure: process.env.REACT_ENV === 'production',
              path: '/',
              sameSite: true,
              expires: expiryDate
            }
            cookies.set('nJWT', response.data.nJWT, cookieConfig);
            resolve();
          })
          .catch(err => {
            reject(err)
           
          });
      });
    },

    signIn(user) {
      const expiryDate = new Date(Date.now() + 60 * 60 * 1000 * 24 * 30); // 30 days

      return new Promise((resolve, reject) => {
        const url = process.env.REACT_APP_BASE_DOMAIN + "/api/sign-in";
        axios.post(url, user)
          .then(response => {
            const cookies = new Cookies();
            const cookieConfig = {
              secure: process.env.REACT_ENV === 'production',
              path: '/',
              sameSite: true,
              expires: expiryDate
            }
            cookies.set('nJWT', response.data.nJWT, cookieConfig);
            resolve(response.data);
          })
          .catch(err => {
            reject(err)
          });
      });
    },

    getCurrentUser() {
      return new Promise((resolve, reject) => {
        const cookies = new Cookies();
        const authToken = cookies.get('nJWT');
        const url = process.env.REACT_APP_BASE_DOMAIN + "/api/profile";
        const config = { headers: { Authorization: `Bearer ${authToken}` } };
        if (authToken) {
          axios.get(url, config)
            .then(response => {
              return resolve(response.data);
            })
            .catch(err => {
              return reject(err?err:"error");
            });
        } else {
          return reject('Not authenticated')
        }
      })
    },

    twoFactorAuthenticate(token) {
      return new Promise((resolve, reject) => {
        const url = process.env.REACT_APP_BASE_DOMAIN + "/api/two-factor-auth/submit";
        const cookies = new Cookies();
        const authToken = cookies.get('nJWT');
        const config = { headers: { Authorization: `Bearer ${authToken}` } };
         axios.put(url, {
          token
         }, config)
          .then(response => {
            resolve(response.data);
          })
          .catch(err => {
            return reject(err?err:"error");

          });
      });
    },
    

    resendToken() {
      return new Promise((resolve, reject) => {
        const url = process.env.REACT_APP_BASE_DOMAIN + "/api/resend-two-factor-auth";
        const cookies = new Cookies();
        const authToken = cookies.get('nJWT');
        const config = { headers: { Authorization: `Bearer ${authToken}` } };

        axios.put(url, {}, config)
          .then(response => {
            resolve();
          })
          .catch(err => {
            reject(err);
          });
      })
    },

    confirmEmail(token) {
      return new Promise((resolve, reject) => {
        const url = process.env.REACT_APP_BASE_DOMAIN + `/api/confirm-email?token=${token}`;

        axios.put(url)
          .then(response => {
            resolve();
          })
          .catch(err => {
            reject(err);
          });
      })
    },

    signOut() {
      return new Promise((resolve, reject) => {
        const url = process.env.REACT_APP_BASE_DOMAIN + "/api/sign-out";
        const cookies = new Cookies();
        const authToken = cookies.get('nJWT');
        const config = { headers: { Authorization: `Bearer ${authToken}` } };

        axios.get(url, config)
          .then(response => {
            const cookies = new Cookies();
            cookies.remove('nJWT');
            console.log("response jwt removed",response.data)
            resolve();
          })
          .catch(err => {
            const cookies = new Cookies();
            cookies.remove('nJWT');
            reject(err);
          });
      });
    },

    getNewAccessToken() {
      return new Promise((resolve, reject) => {
        const url = process.env.REACT_APP_BASE_DOMAIN + "/api/identity-verification-token";
        const cookies = new Cookies();
        const authToken = cookies.get('nJWT');
        const config = { headers: { Authorization: `Bearer ${authToken}` } };


        axios.get(url, config)
          .then(response => {
            resolve(response.data);
          })
          .catch(err => {
            reject(err);
          });

      })
    }
  }
}

export default AuthService;

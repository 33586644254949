import React from 'react';

function Target(props) {
  if (props.tx.target) {
    return <div className="">{props.tx.target.first} {props.tx.target.last}</div>
  } else if (props.tx.externalTarget) {
    return <div className="">{props.tx.externalTarget.accountInfo} {props.tx.externalTarget.accountNumberLastFour}</div>
  } else {
    return <div></div>
  }
}

export default Target;

import React from "react";
import { Link } from "react-router-dom";
// import logo from './images/logo.svg';
// import logoHorizontal from './images/logo-horizontal.jpg';

const Footer = () => {
  // function handleClick() { 
    // _gaq.push(['_trackEvent', 'emails', 'clicked', 'lead']); //first this
    // window.open = 'anotherpage.html'; //or window.location for redirection
 // }
  return (
    <>
      <section className="pdd foot-bg">
        <div className="container flex-wrap d-flex align-items-center justify-content-between">
          <div className="mb-4 mb-md-0">
            {/*<img src={ logoHorizontal } className="logo" alt="logo" />*/}
            <p className="my-3">© 2021 The New Reserve LLC</p>
{/*            <div className="d-flex social align-items-center gap-3 mt-2">
              <AiFillInstagram size={28} />
              <AiFillTwitterSquare size={28} />
              <AiFillFacebook size={28} />
            </div>*/}
          </div>
          <div className="d-flex flex-wrap align-items-start gap-5">
            <ul className="d-flex flex-column align-items-start gap-4">
              <li>
                <strong>Company</strong>
              </li>
              <li>
                <Link to="/ourmission">Our Mission</Link>
              </li>
              <li>
                <Link to="/howitworks">How It Works</Link>
              </li>
              <li>
                <Link to="/faq">FAQ</Link>
              </li>
            </ul>
            <ul className="d-flex flex-column align-items-start gap-4">
              <li>
                <strong>Your Account</strong>
              </li>
              <li>
                {/*<Link to="/sign-in">Sign In</Link>*/}
              </li>
              <li>
                <Link to="/subscribe">Get Invited</Link>
                {/*<Link to="/sign-up">Sign Up</Link>*/}
              </li>
            </ul>
            <ul className="d-flex flex-column align-items-start gap-4">
              <li>
                <strong>Company & Support</strong>
              </li>
              <li>
                <Link to="/user-agreement">User Agreement</Link>
              </li>
              <li>
                <Link to="/privacy">Privacy</Link>
              </li>
              <li>
                <a href="mailto:hello@paywithnumey.com">Ask a Question</a>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </>
  );
};

export default Footer;

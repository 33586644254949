import React from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { UserContext } from "../UserContext";

const HowItWorks = () => {
  // const {user} = UserContext();

  return (
    <>
      {/* ----------------hero--------------------- */}
      <section className="mb-5">
        <div>
          <Row className="mx-0">
            <Col md={6} className="px-0">
              <img
                src="images/howitworks.png"
                style={{ width: "100%" }}
                alt="howitworks"
              />
            </Col>
            <Col md={6} className="px-0">
              <div className="howitworks  d-flex align-items-center justify-content-start">
                <div className="max-w d-flex align-items-start justify-content-center gap-3 flex-column pe-md-5">
                  <h2 className="fs-38 fw-bold">How It Works</h2>
                  <p className="fw-light">
                    We keep things simple. A strong reserve makes a strong currency. A strong currency will beat inflation. It's as simple as that.
                  </p>
                  <Link to="/subscribe" className="btn m-auto btn-primary py-3 px-4">
                    Get an Invite
                  </Link>
{/*                 {!user &&  <Link to="/sign-up" className="btn btn-primary px-4 py-3">
                    Start Your Free Wallet
                  </Link>} */}
                  
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </section>
      {/* ------------------------how numey work------------------------- */}
      <section className="pdd">
        <div className="circle-right bottom-20 ">
          <img src="images/right.png" alt="right-circle" />
        </div>
        <div className="square left top-20 ">
          <img src="images/square-left.png" alt="right-circle" />
        </div>
        <div className="container">
          <h2 className="fw-bold h-after fs-38 text-center mb-5">
            How the Numey Works
          </h2>
          <div className="shadow bg-white rounded-3 p-3 p-xl-5">
            <Row className="mx-0 align-items-center">
              <Col md={12} lg={4}>
                <img src="images/how-works-1.jpg" alt="antt" />
              </Col>
              <Col
                md={12}
                lg={4}
                className="pe-3 my-4 my-md-0 pe-md-0 workboxblue"
              >
                <div className="bg-primary py-5 px-4 flex-column rounded-3 gap-3 text-white d-flex align-items-center justify-content-center ">
                  <img src="images/logo.png" alt="logo" />
                  <small className="text-center d-block lh-lg">
                    The NPS is a secure payment system that allows you to
                    transact with anyone with Numey wallet instantaneously and
                    with zero fees.
                  </small>
                  <img src="images/antt2.png" alt="ant2" />
                </div>
              </Col>
              <Col md={12} lg={4} className="ps-3 ps-md-0  brdr reservebox">
                <div className="revrserboxin px-4 flex-column gap-3 d-flex align-items-center justify-content-center ">
                  <img src="images/antt3.png" alt="antt3" />
                  <h2 className="">The <span className="nmy">ǂ</span>NMY reserve</h2>
                  <small className="text-center d-block lh-lg px-3">
                    Every time someone buys Numey, the reserve holds an equivalent amount of total
                    market index funds in reserve. A valuable reserve enables the
                    Numey to maintain its inflation-linked value.
                  </small>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </section>
      {/* ---------------------------trust Numey------------------------ */}
      {/*<section className="pdd bg-primary trust">
        <div className="container">
          <h2 className="fw-bold h-after fs-38 fs-1 text-white text-center pb-3 mb-2 mb-md-5">
            Why Can I Trust Numey?
          </h2>
          <Row className="mx-0">
            <Col md={5}>
              <small className="text-white d-block lh-lg fw-light mb-5">

              </small>
              <img src="images/whynumey.png" alt="illustration" />
            </Col>
            <Col md={7}>
              <Row className="mx-0">
                <Col md={6}>
                  <article>
                    <div className="d-flex align-items-center mt-3 mt-md-0 gap-4 mb-2">
                      <div className="white-box">
                        <img src="/images/contact.svg" alt="contact" />
                      </div>
                      <h2 className="fw-bold text-white fs-5 mb-0">
                        A Valuable Asset Reserve
                      </h2>
                    </div>
                    <small className="text-white d-block lh-lg fw-light mt-3">
                      BitCoin has a 0% reserve. Ethereum has a 0% reserve.
                      Nearly all cryptos have no reserve at all. FTX lied about
                      its reserve. Even banks, like Silicon Valley Bank, are
                      only required to have a 20% reserve. The Numey maintains a
                      strong reserve made up of total market index funds—arguably
                      the most reliable assets in the world. This reserve is
                      verified by IRS audit and by our bank and financial
                      partners.
                    </small>
                  </article>
                </Col>
                <Col md={6}>
                  <article>
                    <div className="d-flex align-items-center mt-3 mt-md-0 gap-4 mb-2">
                      <div className="white-box">
                        <img src="/images/percent.svg" alt="contact" />
                      </div>
                      <h2 className="fw-bold text-white fs-5 mb-0">
                        Regulated by the IRS
                      </h2>
                    </div>
                    <small className="text-white d-block lh-lg fw-light mt-3">
                      The Numey is not a shady cryptocurrency that escapes regulation from the government by operating in the Bahamas (like FTX). The New Reserve LLC is proud to be a Delaware corporation with its headquarters in San Francisco, California. Numey is an American company that serves only Americans. Numey is happy to be regulated by the IRS. We open our books to the IRS audits once per year.
                    </small>
                  </article>
                </Col>
                <Col md={12}>
                  <article className="mt-4">
                    <div className="d-flex align-items-center gap-4 mb-2">
                      <div className="white-box">
                        <img src="/images/ourmission-icons/capitalism.svg" alt="contact" />
                      </div>
                      <h2 className="fw-bold text-white fs-5 mb-0">
                        Financial Partners
                      </h2>
                    </div>
                    <small className="text-white d-block lh-lg fw-light mt-3">
                      Unlike fraudulent and scammy cryptos, the Numey operates with the support and trust of various fiancial partners and banks.
                    </small>
                  </article>
                </Col>
                {!user && <Link to="/sign-up" className="btn bg-white text-primary px-4 py-3 mt-4 mx-2">
                  Start Your Free Wallet
                </Link>}
              </Row>
            </Col>
          </Row>
        </div>
      </section>*/}
      {/* -------------------------100% asset----------------------- */}
      <section className="pdd">
        <div className="circle-right ">
          <img src="images/right.png" alt="right-circle" />
        </div>
        <div className="container">
          <h2 className="fw-bold h-after fs-38  text-center mb-2 mb-md-5">
            An Asset Reserve Currency
          </h2>
          <h4 className="fw-bold fs-4 text-center mb-2 mb-md-5">
            The Numey preserves its value by holding an inflation-resistant
            assets reserve.
          </h4>
          <div>
            <Row>
              <Col md={{span: 8, offset: 2}}>
                <div className="numbering">
                  <span>1</span>
                  <p>
                    No one can print Numey or make more Numey out of thin air.
                  </p>
                </div>
                <div className="numbering">
                  <span>2</span>
                  <p>
                    The only way to make Numey is purchase it from the Numey
                    payment system (NPS).
                  </p>
                </div>
                <div className="numbering">
                  <span>3</span>
                  <p>
                    Every time a Numey is purchased from the Numey payment
                    system, the system purchases an equivalent amount of index funds to maintain the strong asset reserve.
                  </p>
                </div>
                <div className="numbering">
                  <span>4</span>
                  <p>
                    Every time a Numey is sold back to the Numey payment system,
                    the system sells an equivalent amount of the Numey asset
                    reserve.
                  </p>
                </div>
                <div className="numbering">
                  <span>5</span>
                  <p>
                    The total number of Numey in existence are equivalent to the
                    number of Numey that have been purchased from the Numey
                    payment system.
                  </p>
                </div>
                <div className="numbering">
                  <span>6</span>
                  <p>
                    The Numey's current asset reserve strategy is to hold 100% of its reserve in
                    total-market index funds.
                  </p>
                </div>
                <div className="numbering">
                  <span>7</span>
                  <p>
                    The Numey's asset reserve is reviewed by our bank partner and the IRS.
                  </p>
                </div>
              </Col>
            </Row>
          </div>
          <div className="pdx">
            <div className="p-5 blue-quote bg-primary rounded-3 text-center text-white">
              <small className="d-block mb-3 text-uppercase">
                The Numey reserve's strategy is dead simple:
              </small>
              <blockquote>
                “Consistently buy an S&P 500 low-cost index fund. Keep buying it
                through thick and thin, and especially through thin.”
              </blockquote>
              <small className="d-block my-2">
                ~Warren Buffet, CNBC Make It (2017)
              </small>
              <Link to="/subscribe" className="btn m-auto btn-primary py-3 px-4">
                Subscribe for Updates
              </Link>
{/*             { !user &&  <Link to="/sign-up" className="btn m-auto mt-4 bg-white px-4 py-3">
                Start Your Free Wallet
              </Link> } */}
            </div>
          </div>
        </div>
      </section>
      {/* --------------------problem money----------------- */}
      <section className="pdd bg-primary">
        <div className="container">
          <h2 className="fw-bold h-after fs-38 fs-1 text-white text-center pb-3 mb-5">
            The Problems with Money Today
          </h2>
          <Row className="mx-0 align-items-center ">
            <Col md={7} className="text-center text-md-end text-sec text-white">
              <h5>The Problems with Inflationary Currencies</h5>
              <div>
                <h6>They Lose Their Value</h6>
                <p>
                  National fiat currencies like the US or Australian Dollar or
                  the Euro all gradually lose their value over time. Their
                  central banks intentionally produce too much money each year
                  to manipulate interest rates and stimulate big business. Its
                  not evil, exactly, but it does hurt wage-earners and savers
                  and help employers and big debtors like large corporations and
                  the federal government.
                </p>
              </div>
              <div>
                <h6>Their Reserves are Not Safe</h6>
                <p>
                  Look at the Federal Reserve's actual reserve. It is mostly
                  T-bills, some gold, and a ton of toxic mortgage backed
                  securities it bought up in 2008 to bail out the housing
                  market. Why do we trust a currency with such poor assets on
                  its balance sheet? In contrast, the Numey's reserve is 100%
                  low-cost, total-market ETFs — by many accounts some of the
                  best investments you can make.
                </p>
              </div>
              <div>
                <h6>Unsustainable Govn't Spending</h6>
                <p>
                  National fiat currencies like the US Dollar enable central
                  governments to borrow an infinite amount of money. The
                  national debt is eventually paid by workers and citizens
                  through inflation and future taxes. While this national debt
                  can be used for good things, like helping out in a hurricane,
                  it is more often used for unnecessary and harmful things like
                  tax breaks for the wealthy and endless wars.
                </p>
              </div>
            </Col>
            <Col md={5} className="px-4 my-4 my-md-0">
              <img
                src="images/amico.png"
                style={{ maxWidth: "100%" }}
                alt="illustration"
              />
            </Col>
          </Row>
          <Row className="mx-0 align-items-center mt-5">
            <Col md={5} className="px-4 my-4 my-md-0">
              <img
                src="images/amico2.png"
                style={{ maxWidth: "100%" }}
                alt="illustration"
              />
            </Col>
            <Col
              md={7}
              className="text-center text-md-start text-sec text-white"
            >
              <h5>The Problems with Cryptocurrencies</h5>
              <div>
                <h6>Cryptos are Unstable</h6>
                <p>
                  Crypto currencies are not stable. Their prices go up and down like a rollercoaster. No one can use a money that fluctuates that much for anything except gambling. Using cryptocurrencies as money is the same as using famous works of art or collector's baseball cards as money. They may be valuable, but their value changes every day.
                </p>
              </div>
              <div>
                <h6>Cryptos are Risky</h6>
                <p>
                  Cryptocurrencies are really risky. Access to your cryptocurrencies depends on access keys that can be stolen. No one can reverse a fraudulent charge. Criminals use cryptocurrencies deliberately to evade the police and FBI to deal drugs and launder money. 
                </p>
              </div>
              <div>
                <h6>Cryptos Pollute</h6>
                <p>
                  Cryptocurrencies unnecessarily take an enormous amount of electricity to run. The BitCoin network alone uses about as much electricity as the state of Washington or the country of New Zealand each day. And for what? It's a waste and truly evil in a time of environmental catastrophe.
                </p>
              </div>
            </Col>
          </Row>
        </div>
      </section>
      {/* -------------------------use numey------------------------- */}
      <section className="pdd usenumey">
        <div className="circle-left bottom ">
          <img src="images/left.png" alt="right-circle" />
        </div>
        <div className="square right ">
          <img src="images/square.png" alt="right-circle" />
        </div>
        <div className="container px-2 px-md-5">
          <h2 className=" fw-bold h-after fs-38 text-center mb-5">
            How to Start Using Numey
          </h2>
          <div className="row">
            <div className="col-lg-6 offset-lg-3">
              <article className="mt-5">
                <div className="d-flex align-items-center gap-4">
                  <div className="round-numbering">1</div>
                  <h5 className="mb-0">Create a Wallet in NPS</h5>
                </div>
                <p className="">
                  Sign up to create a Numey Wallet in the Numey Payment System.
                </p>
              </article>
              <article className="mt-5">
                <div className="d-flex align-items-center gap-4">
                  <div className="round-numbering">2</div>
                  <h5 className=" mb-0">Verify Your Identity</h5>
                </div>
                <p className="">
                  To comply with federal law, we require all users to verify their identities using a government issued photo id.
                </p>
              </article>
              <article className="mt-5">
                <div className="d-flex align-items-center gap-4">
                  <div className="round-numbering">2</div>
                  <h5 className=" mb-0">Buy Numey</h5>
                </div>
                <p className="">
                  Add Numey to your wallet balance securely through your bank with
                  a 0% purchase fee.
                </p>
              </article>
              <article className="mt-5">
                <div className="d-flex align-items-center gap-4">
                  <div className="round-numbering">3</div>
                  <h5 className=" mb-0">Build an Emergency Fund</h5>
                </div>
                <p className="">
                  Your Numey wallet is the ideal place to build an emergency fund of 3-6 months of expenses. It won't go down with inflation, nor is it at risk of the market.
                </p>
              </article>
              <article className="mt-5">
                <div className="d-flex align-items-center gap-4">
                  <div className="round-numbering">4</div>
                  <h5 className="mb-0">Send and Spend</h5>
                </div>
                <p className="">
                  Use Numey to pay for singing lessons, burritos, nights out, or
                  whatever you like. It is free to send Numey to any other Numey wallet.
                </p>
              </article>
              <article className="mt-5">
                <div className="d-flex align-items-center gap-4">
                  <div className="round-numbering content-none">5</div>
                  <h5 className=" mb-0">Convert Numey to USD Whenever You Want</h5>
                </div>
                <p className="">
                  Anytime you need USD, you can sell your Numey and have USD within 3-5 days. There is a 1% out-of-network transaction fee when you sell Numey
                  back for USD. That's many times cheaper than crypto or credit card fees!
                </p>
              </article>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HowItWorks;

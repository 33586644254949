import React, { useState } from "react";
import AdminTransfer from "./AdminTransfer";

import axios from 'axios';
import Cookies from 'universal-cookie';

import { toast } from 'react-toastify';

function AdminTransfersList() {
  const [txs, setTxs] = useState([]);

  React.useEffect(() => {
    const cookies = new Cookies();
    const authToken = cookies.get('nJWT');
    const config = { headers: { Authorization: `Bearer ${authToken}` } };

    const txsUrl = process.env.REACT_APP_BASE_DOMAIN + "/api/admin/transfers";
    axios.get(txsUrl, config)
      .then(txs => {
        setTxs(txs.data);
      }).catch(err => {
        toast.warning(err.response.data.message)
      });
  }, []);

  return (
    <div className="card mb-4">
      <div className="card-body">
        <div className="card-text">
          <h4>Transfers</h4>
          <table className="table table-striped">
            <thead>
              <tr>
                <th>Created At</th>
                <th>Source</th>
                <th>Target</th>
                <th>NMY</th>
                <th>ACHed At</th>
                <th>Processed At</th>
                <th>Failed At</th>
              </tr>
            </thead>
            <tbody>
              {txs.map((tx) => (
                <AdminTransfer tx={tx} key={tx.id}></AdminTransfer>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}
export default AdminTransfersList;


// USERCONTEXT.JSX

import React from "react";
import Cookies from 'universal-cookie';

const UserContextApi = React.createContext();

export function UserContext(){
  return React.useContext(UserContextApi);
}

export const UserProvider = ({ children }) => {
  const [user, setUser] = React.useState(null);
  const [exchangeRate, setExchangeRate] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [formData, setFormData] = React.useState(null);
  const cookies = new Cookies();
  // const authToken = cookies.get('nJWT');

  const saveData = (data )=>{
    setFormData((prev)=> ({...prev, data}))
  }

  const contextValue = React.useMemo(() => ({
    user,
    exchangeRate,
    setExchangeRate,
    formData,
    setUser,
    saveData,
    loading,
    setLoading
  }), [user, setUser, exchangeRate, setExchangeRate, loading, setLoading,formData]);

  return (
    <UserContextApi.Provider value={contextValue}>
      {children}
    </UserContextApi.Provider>
  );
};



// AdminTxsList.js

import React, { useState } from "react";

import axios from 'axios';
import Cookies from 'universal-cookie';
import { toast } from 'react-toastify';

import AdminTx from "./Admin-Tx";

function AdminTxsList() {
  const [txs, setTxs] = useState([]);

  React.useEffect(() => {
    const cookies = new Cookies();
    const authToken = cookies.get('nJWT');
    const config = { headers: { Authorization: `Bearer ${authToken}` } };

    const txsUrl = process.env.REACT_APP_BASE_DOMAIN + "/api/admin/txs";
    axios.get(txsUrl, config)
      .then(txs => {
        setTxs(txs.data);
      }).catch(err => {
        toast.warning(err.response.data.message)
      });
  }, []);

  function Source(props) {
    const source = props.tx.source || props.tx.externalSource
    if (source) {
      return <div className="">{source.first} {source.last} {source.institution}: {source.name}</div>
    } else {
      return <div></div>
    }

  }

  function Target(props) {
    const target = props.tx.target || props.tx.externalTarget
    if (target) {
      return <div className="">{ `${target.first} ${target.last}` || `${target.institution}: ${target.name}`}</div>
    }
    return <div></div>
  }

  function reverseTx() {
    // TODO
    // console.log('reversing transaction');
    return
  }

  return (
    <div className="card mb-4">
      <div className="card-body">
        <div className="card-text">
          <h4>Txs</h4>
          <table className="table table-striped">
            <thead>
              <tr>
                <th>Created At</th>
                <th>Source</th>
                <th>Target</th>
                <th>ǂ Amount</th>
                <th>Processed/Failed</th>
              </tr>
            </thead>
            <tbody>
              {txs.map((tx) => (
                <AdminTx tx={tx} key={tx.id}></AdminTx>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}
export default AdminTxsList;


import React, { useState } from "react";
import { Col, Form, InputGroup, Row, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import axios from 'axios';
import InputMask from "react-input-mask";
import { toast } from 'react-toastify';
import NumeyValueChart from "../ExchangeRates/NumeyValueChart";
import InflationTicker from "../ExchangeRates/InflationTicker";
import { AiOutlineArrowUp } from "react-icons/ai";
import { Rating } from "react-simple-star-rating";
import Slider from "react-slick";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa6";
import "animate.css";
import appStore from '../images/app-store.svg';
import playStore from '../images/android.svg';

const Home = () => {
  const [housing, setHousing] = React.useState(1400);
  const [food, setFood] = React.useState(600);
  const [transport, setTransport] = React.useState(200);
  const [entertainment, setEntertainment] = React.useState(400);
  const [lowTotalInDollars, setLowTotalInDollars] = React.useState(7800);
  const [highTotalInDollars, setHighTotalInDollars] = React.useState(15600);
  const [lowTotalInScript, setLowTotalInScript] = React.useState(0);
  const [highTotalInScript, setHighTotalInScript] = React.useState(0);

  const data = [
    { year: 2016, inflation: "1.26%", avgint: "0.75%", diff: "-0.51%" },
    { year: 2017, inflation: "2.12%", avgint: "0.60%", diff: "-1.52%" },
    { year: 2018, inflation: "2.44%", avgint: "0.10%", diff: "-2.34%" },
    { year: 2019, inflation: "1.81%", avgint: "0.00%", diff: "-1.81%" },
    { year: 2020, inflation: "1.23%", avgint: "0.00%", diff: "-1.23%" },
    { year: 2021, inflation: "4.70%", avgint: "0.46%", diff: "-4.24%" },
    { year: 2022, inflation: "8.34%", avgint: "1.50%", diff: "-6.84%" },
  ];

  const testimonials = [
    { 
      name: "Fatima Khan",
      loc: "Los Angeles, CA",
      test: "With the Numey, I don't care what the news says about the economy. It's like a financial safety net." 
    },
    { 
      name: "Emily Johnson",
      loc: "Albany, NY",
      test: "Numey's innovative concept is incredible. It's the only currency I know is built to retain its value in this ever-changing world."  
    },
    { 
      name: "Alessandro Ross",
      loc: "San Francisco, CA",
      test: "Numey's inflation peg makes it the perfect currency for everyday use. It's stable and reliable, allowing me to manage my finances effortlessly." 
    },
    { 
      name: "Christopher Miller",
      loc: "Kansas City, MO",
      test: "Numey has simplified my financial planning. With its inflation-proof design, I have peace of mind, no matter the market is doing."  
    },
    { 
      name: "Carlos Silva",
      loc: "Portland, OR",
      test: "Numey is a breath of fresh air in the financial world. It's the smart choice for anyone who wants a currency that actually keeps up with the times."  
    },
    { 
      name: "Max Diamond",
      loc: "Santa Barbara, CA",
      test: "Numey has been a game-changer for my financial peace of mind. It's like having a financial superhero that protects my purchasing power no matter what."  
    },
    { 
      name: "Owen Raisch",
      loc: "Sacramento, CA",
      test: "Numey has made managing my finances a breeze. Its inflation-pegged stability is a true lifesaver, ensuring my money holds its value throughout the years."  
    },
  ];
  const phoneScreens = [
    {
      heading: "Start Your numey Wallet Today For Free",
      src: "images/iphone.png",
      padd: "top",
      direction: "flex-column",
    },
    {
      heading: "Send And Receive Money Protected Against Inflation",
      src: "images/iphone3.png",
      padd: "bottom",
      direction: "flex-column-reverse",
    },
    {
      heading: "Secure, Fast And Private Transactions With No Or Low Fees",
      src: "images/iphone2.png",
      padd: "top",
      direction: "flex-column",
    },
    {
      heading:
        "A New, Revolutionary Currency Without The Cost And Hassle Of Crypto",
      src: "images/iphone4.png",
      padd: "bottom",
      direction: "flex-column-reverse",
    },
  ];
  const partners = [
    // { 
    //   data: "First Business Bank: Banking Services", 
    //   desc: "Our bank is not in the Bahamas or the Caymans like some questionable crypto. Our bank is located in the heartland of the US of A: Madison, Wisconsin. We are very happy to be working with this 111 year old, publicly traded institution (FBIZ).",
    //   src: "images/crd1.png" 
    // },
    { 
      data: "Alpaca Securities: Brokerage Services", 
      desc: "Alpaca is one of the only stock brokers who makes it possible to send buy and sell orders using a web API. We are happy to use Alpaca, since it means we can use their low-fee service to entirely automate managing our reserve.",
      src: "images/crd2.png" 
    },
    { 
      data: "SumSub: Identity Verification", 
      desc: "Sumsub enables us to comply with federal law that requires any service that handles electronic money to verify each of our customers' identities. This process can be a pain in the neck, but Sumsub's reliable and easy-to-use interface makes this process as smooth as possible.",
      src: "images/crd3.png" 
    },
    { 
      data: "Plaid Inc: Bank Credential Integration", 
      desc: "Plaid is a revolutionary little piece of software that helps you securely connect your Numey Wallet to your bank account. You are probably already familiar with their elegant, white pop-up dialog box from other applications.",
      src: "images/crd4.png" 
    },
  ];

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <FaAngleRight color="black" />,
    prevArrow: <FaAngleLeft color="black" />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };

  const [first, setFirst] = React.useState("");
  const [last, setLast] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [subPhone, setSubPhone] = React.useState("");
  
  const [loading, setLoading] = React.useState(false);
  const [submitted, setSubmitted] = React.useState(false);

  const [phone, setPhone] = React.useState("");


  const submitNewSub = (e) => {
    e.preventDefault();
    setLoading(true)

    const user = {
      first: first,
      last: last,
      email: email,
      phone: subPhone
    }
    
    const url = process.env.REACT_APP_BASE_DOMAIN + '/api/subs';

    axios.post(url, user).then(response => {
      setEmail("")
      setFirst("")
      setLast("")
      setSubPhone("")
      toast.success("Subscribed to the Numey newsletter");
    }).catch(err => {
      console.log(err.message)
      toast.warning("There was a problem with your form. Please try again.")
    })
  }

  const sendAppStoreUrl = (e) => {
    e.preventDefault();
    
    const url = process.env.REACT_APP_BASE_DOMAIN + '/api/send-app-store-url';

    axios.get(url, { params: { phone: phone } }).then(response => {
      toast.success("Link to iOS app store sent!");
    }).catch(err => {
      toast.warning("There was a problem sending you the link. Try again.")
    })  
  }


  const updateTotals = () => {
    setLowTotalInDollars((housing + food + entertainment + transport) * 3)
    setHighTotalInDollars((housing + food + entertainment + transport) * 6)
  }

  const handleHousingChange = (e) => {
    const value = parseInt(e.target.value)
    setHousing(value || 0);
    updateTotals();
  }
  const handleFoodChange = (e) => {
    const value = parseInt(e.target.value)
    setFood(value || 0);
    updateTotals();
  }
  const handleTransportChange = (e) => {
    const value = parseInt(e.target.value)
    setTransport(value || 0);
    updateTotals();
  }
  const handleEntertainmentChange = (e) => {
    const value = parseInt(e.target.value)
    setEntertainment(value || 0);
    updateTotals();
  }

  return (
    <>
      <section className="bg-hero">
        <div className="hero container">
          <Row className="align-items-center mx-0 px-5">
            <Col md={7}>
              <div className="gap-3 d-flex flex-column justify-content-center align-items-center align-items-md-start text-center text-md-start max-w-hero text-white">
                <h1>Escape Inflation.</h1>
                <h4 className="fw-medium">
                  We make it so your money keeps up with the cost of living.
                  {/*<br/> And payments were free and instantaneous? */}
                </h4>
                <p className="fw-lighter">
                  On average, inflation costs American households over $500 Billion in lost purchasing power every year. We believe that needs to stop. So we built the Numey, a complementary currency that preserves its inflation-linked value by maintaining a strong asset reserve. <br/> (The Numey is not a cryptocurrency.)
                </p>
                <Link to="/subscribe" className="btn btn-light w-fit px-4 text-primary py-3">
                  Get an Invite
                </Link>
{/*                 <Link to="/sign-up" className="btn btn-light w-fit px-4 text-primary py-3">
                  Start Saving and Paying for Free
                </Link> */}
              </div>
              {/* 
              <div className="gap-3 d-flex flex-column justify-content-center align-items-center align-items-md-start text-center text-md-start max-w-hero text-white">
                <h1>America's First Inflation-Linked Currency</h1>
                <h4 className="fw-medium">
                  The Numey (ǂNMY) Keeps Up with the Cost of Living.
                </h4>
                <p className="fw-lighter">
                  The Numey is instantaneous, frictionless, and inflation-linked. The Numey maintains its value by virtue of its valuable asset reserve. It is free to buy, send, and receive Numey. There is a 1% fee when you sell Numey for Dollars.
                </p>
                <Link to="/sign-up" className="btn btn-light w-fit px-4 text-primary py-3">
                  Start Your Free Wallet
                </Link>
              </div>
              */}
            </Col>
            <Col md={5}>
              <figure className="d-flex justify-content-center mt-5 mt-md-0">
                <img src="images/iphn.svg" alt="iphone" />
              </figure>
            </Col>
          </Row>
        </div>
      </section>
      {/* 
      <div className="d-flex my-5 flex-wrap gap-4 align-items-center justify-content-center">
        <h2 className="fs-4 fw-bold mb-0">
          Download Our Free App Now
        </h2>
        <div className="d-flex flex-column gap-4 flex-sm-row align-items-center justify-content-center">
          <a href="https://apps.apple.com/us/app/numey/id1607605765" target="_blank">
            <img src={ appStore } width="160em" alt="appstore" />
          </a>
          <b className="text-dark">Or</b>
          <a href="https://play.google.com/store/apps/details?id=com.numey&hl=en&gl=US" target="_blank">
            <img src={ playStore } alt="playstore" width="180em" />
          </a>
        </div>
      </div>
      */}
      {/* --------------chart------------------------ */}
      <section className=" pdd mt-2 mt-md-5">
        <div className="circle-right bottom-20 ">
          <img src="images/right.png" alt="right-circle" />
        </div>
        <div className="square bottom-20 ">
          <img src="images/square-left.png" alt="right-circle" />
        </div>

        <div className="container">
          <div className="text-center">
            <h2 className="fw-bolder fs-38 h-after mb-4">
              A Proven Track Record Fighting Inflation
            </h2>
            <p className="col-lg-8 offset-lg-2">
              The value of the US Dollar has fallen over 20% since January 2020. Did you get a 20% pay raise? Did you earn 20% interest on your bank account? 
              <br/> 
              <br/> 
              Over that same time the Numey has lost 0% of its value because the Numey's value is tied to the CPI and protected by a valuable asset reserve.
            </p>
            <div className="mt-5 px-4 py-5 bg-light">
              <NumeyValueChart />
            </div>
            <div className="d-flex justify-content-center mt-5">
            <Link className="btn btn-primary" aria-current="page" to="/subscribe">Get Invited and a Free E-Book</Link>
{/*               <Link className="btn btn-primary" aria-current="page" to="/sign-up">Start Your Free Wallet</Link> */}
            </div>
          </div>
        </div>
      </section>
      {/*<InflationTicker/>*/}

      {/* -----------------------wallet blue------------------------ */}
      <section className="pdd emergency bg-primary text-white">
        <div className="container px-2 px-md-5">
          <div>
            <h2 className="text-center fs-38 heading h-after">
              Smart Savers Use Their Numey Wallet 
            </h2>
          </div>
          <Row>
            <Col md={6}>
              <div>
                <h5>Inflation > Interest</h5>
                <small>
                  It is almost impossible to get a savings account interest rate that is equal to or exceeds inflation. So if you are putting your emergency fund in a savings account, you are necessarily going to lose a chunk of it each month to inflation, some years a very big chunk.
                  In the below chart you can see for the past 7 years how inflation has always been larger than savings interest rates. 
                </small>
              </div>
            </Col>
            <Col md={6}>
              <div>
                <h5>Investments are Too Volatile</h5>
                <small>
                  With an emergency fund, it is important to avoid volatility. What if you need the emergency fund right when the stock market is in a dip? Using the Numey provides you with an optimal spot to stash your emergency fund. At the same time, you have a free form of electronic cash you can use with friends and family.
                </small>
              </div>
            </Col>
          </Row>
          <Table className="my-4" responsive hover>
            <thead>
              <tr>
                <th>Year</th>
                <th>Inflation Rate</th>
                <th>Avg. Savings Interest</th>
                <th>Loss on Savings Acct.</th>
              </tr>
            </thead>
            <tbody>
              {data.map((data, index) => (
                <tr key={index}>
                  <td>{data.year}</td>
                  <td>{data.inflation}</td>
                  <td>{data.avgint}</td>
                  <td className="text-danger">{data.diff}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </section>
      

      {/* -------------------------Ray Dalio ---------------------------- */}

      <section className="pdd mt-5">
        <div className="p-md-2">
          <div className="row">
            <div className="text-center">
              <h2 className="fw-bolder fs-38 h-after mb-4">
                Ray Dalio Endorses an Inflation-Linked Currency
              </h2>
            </div>
            <div className="col-lg-6">
              <div className="ratio ratio-16x9 mt-5">
                <video src="/videos/dalio-landscape.mp4" allowFullScreen controls></video>
              </div>  
            </div>  
            <div className="col-lg-6 py-lg-5 mt-4">
              
              <p className='mt-4'>Ray Dalio is the Chief investment officer of Bridgewater Associate, one of the largest hedgefunds in the world. Bloomberg News reported Dalio to be the 123rd richest person. He started working on Wall Street in 1971 and witnessed Nixon's decision to <a href="https://www.wtfhappenedin1971.com" target="_blank">go off the gold standard</a> sparking the Great Inflation of the 1970s. While being interviewed on CNBC, he suggested that what the world really needs is an inflation-linked currency. Sounds familiar!</p>
              <div className="mt-3 d-flex justify-content-center">
                <Link to="/howitworks" className="btn fs-6 mt-4 btn-primary py-3">
                  Learn How Numey Works
                </Link>
              </div>
            </div>  
          </div>  
        </div>
      </section>
      {/* -------------------------free e book ---------------------------- */}
      <section className="pdd mt-large">
        <div className="free-e-book ">
          <div className="container d-flex d-xl-block flex-column flex-xl-row align-items-center justify-content-center position-relative">
            <div className="text-white text-s">
              <h2 className="fs-38">Free E-Book</h2>
              <p>
                Stay up to date on Numey news, and we'll send you a free
                e-book <i>The Denationalization of Money</i> by FA Hayek (1976). We
                keep all emails and information private.
              </p>
            </div>
            <div className="bg-white p-abs shadow max-w rounded-4 p-4">
              <form onSubmit={submitNewSub}>  
                <Row>
                  <Col md={12}>
                    <Form.Label htmlFor="inlineFormInputGroup">Email<span className="text-danger">*</span></Form.Label>
                    <InputGroup className="mb-3">
                      <Form.Control
                        className="py-3"
                        id="inlineFormInputGroup"
                        placeholder="Enter Email"
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                        required="required" 
                      />
                    </InputGroup>
                  </Col>
                  <Col md={6}>
                    <Form.Label htmlFor="inlineFormInputGroup">
                      First Name
                    </Form.Label>
                    <InputGroup className="mb-3">
                      <Form.Control
                        className="py-3"
                        id="inlineFormInputGroup"
                        placeholder="Enter First Name"
                        value={first}
                        onChange={e => setFirst(e.target.value)}
                      />
                    </InputGroup>
                  </Col>
                  <Col md={6}>
                    <Form.Label htmlFor="inlineFormInputGroup">
                      Last Name
                    </Form.Label>
                    <InputGroup className="mb-3">
                      <Form.Control
                        className="py-3"
                        id="inlineFormInputGroup"
                        placeholder="Enter Last Name"
                        value={last}
                        onChange={e => setLast(e.target.value)}
                      />
                    </InputGroup>
                  </Col>
                  <Col md={12}>
                    <Form.Label htmlFor="inlineFormInputGroup">
                      Phone number
                    </Form.Label>
                    <InputGroup className="mb-3">
                      <InputMask
                        className="form-control"
                        id="phone"
                        type="tel"
                        name="phone"
                        placeholder="+1(555) 333-4444"
                        value={subPhone}
                        onChange={e => setSubPhone(e.target.value)}
                        mask="+1\(999) 999-9999"
                        maskplaceholder={""}
                        maskchar=" "
                        />
                    </InputGroup>
                  </Col>
                  <Col md={12}>
                    <button type="submit" className="btn fs-6 mt-4 btn-primary w-100 py-3">
                      Get Your Free E-Book
                    </button> 
                  </Col>
                </Row>
              </form>
            </div>
          </div>
        </div>
      </section>
      {/* ---------------------------AS SEEN IN----------------- */}

{/*      <section className="container pdd">
        <h2 className="fw-bolder fs-38 h-after mb-4">Featured On</h2>
        <Row>
          <Col xs={6}>
            <Link target="_blank" to="https://marginalrevolution.com/marginalrevolution/2023/02/sunday-assorted-links-407.html">
              <img src="/images/press/mru-logo-450.png" className="img-fluid" />
            </Link>
            <h5 className="text-center mt-5">Marginal Revolution</h5>
          </Col>
          <Col xs={6}>
            <Link target="_blank" to="https://www.solutionsfromthemultiverse.com/2032704/12231881-putting-an-end-to-inflation-w-the-numey-sfm-e30">
              <img src="/images/press/sfm-logo.jpg" className="img-fluid" />
            </Link>
            <h5 className="text-center mt-5">Solutions From the Multiverse</h5>
          </Col>
        </Row>
      </section>
*/}

      {/* ---------------------------FBI Scammers----------------- */}
     {/* <section className="container pdd">
        <div className="row mb-5">
          <h1 className="mb-5 text-center">Criminals Beware</h1>
          <Row>
            <Col xs={{ span:8, offset:2 }}>
              <p className="mb-5">Unlike many cryptocurrencies that are built to protect criminals and scammers, The New Reserve works directly with law enforcement to help them find and prosecute scammers, fraudsters, terrorists, money launderers, and human traffickers. We actively work with the following enforcement agencies:</p>
              <Row>
                <Col xs={4}>
                  <img src="/images/criminals/fbi.png" className="img-fluid" />
                  <h5 className="text-center mt-3">FBI</h5>
                </Col>
                <Col xs={4}>
                  <img src="/images/criminals/fincen.svg.png" className="img-fluid" />
                  <h5 className="text-center mt-3">FinCEN</h5>
                </Col>
                <Col xs={4}>
                  <img src="/images/criminals/law-enforcement.png" className="img-fluid" />
                  <h5 className="text-center mt-3">Local Law Enforcement</h5>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </section>*/}
      {/* ---------------------------calculator----------------- */}
      <section className="pdd">
        <div className="square bottom ">
          <img src="images/square-left.png" alt="right-circle" />
        </div>
        <div className="container">
          <h2 className="fw-bold h-after fs-38 fs-1 my-3 my-md-5 text-center ">
            Start an Inflation-Linked Emergency Fund
          </h2>
          <div className="inflation py-5 px-2 px-md-4">
            <Row className="mx-0 align-items-end justify-content-between">
              <Col lg={5}>
                <div className="text-white">
                  <strong className="fs-3">
                    Perfect for an Emergency Fund
                  </strong>
                  <p className="mt-2">
                    Experts recommend keeping 3-6 months worth of expenses as an
                    emergency fund in case of unexpected costs. If you keep this
                    in cash or in a savings account, the value is eaten away by
                    inflation. Numey fixes that. By keeping your emergency fund
                    in Numey you have an inflation-proof emergency fund.
                  </p>
                </div>
              </Col>
              <Col lg={6}>
                <div className="bg-white rounded-3 p-4">
                  <h2 className="fs-4 my-2 fw-bold text-center">
                    Emergency Fund Calculator
                  </h2>
                  <p className="mb-4 text-center">
                    Enter your monthly expenses
                  </p>
                  <Row>
                    <Col md={6}>
                      <Form.Label htmlFor="inlineFormInputGroup">
                        Housing
                      </Form.Label>
                      <InputGroup className="mb-3 ">
                        <Form.Control
                          className="py-3 bg-light"
                          id="inlineFormInputGroup"
                          placeholder="$1400"
                          value={housing}
                          onChange={handleHousingChange}
                        />
                        <InputGroup.Text className="bg-light">
                          $ USD
                        </InputGroup.Text>
                      </InputGroup>
                    </Col>
                    <Col md={6}>
                      <Form.Label htmlFor="inlineFormInputGroup">
                        Food
                      </Form.Label>
                      <InputGroup className="mb-3">
                        <Form.Control
                          className="py-3 bg-light"
                          id="inlineFormInputGroup"
                          placeholder="$600"
                          value={food}
                          onChange={handleFoodChange}
                        />
                        <InputGroup.Text className="bg-light">
                          $ USD
                        </InputGroup.Text>
                      </InputGroup>
                    </Col>
                    <Col md={6}>
                      <Form.Label htmlFor="inlineFormInputGroup">
                        Transportation
                      </Form.Label>
                      <InputGroup className="mb-3 ">
                        <Form.Control
                          className="py-3 bg-light"
                          id="inlineFormInputGroup"
                          placeholder="$200"
                          value={transport}
                          onChange={handleTransportChange}
                        />
                        <InputGroup.Text className="bg-light">
                          $ USD
                        </InputGroup.Text>
                      </InputGroup>
                    </Col>
                    <Col md={6}>
                      <Form.Label htmlFor="inlineFormInputGroup">
                        Entertainment
                      </Form.Label>
                      <InputGroup className="mb-3">
                        <Form.Control
                          className="py-3 bg-light"
                          id="inlineFormInputGroup"
                          placeholder="$400"
                          value={entertainment}
                          onChange={handleEntertainmentChange}
                        />
                        <InputGroup.Text className="bg-light">
                          $ USD
                        </InputGroup.Text>
                      </InputGroup>
                    </Col>
                 {/*   <Col md={12}>
                      <button className="btn fs-6 mt-4 btn-primary w-100 py-3">
                        Submit
                      </button>
                    </Col>*/}
                  </Row>
                  <div className="text-center">
                    <h2 className="fs-3 text-primary fw-bold my-2">
                      ${lowTotalInDollars} - ${highTotalInDollars} USD
                    </h2>
                    {/*<h5 className="text-muted">ǂ{lowTotalInDollars} - <span className="nmy">ǂ</span>{highTotalInDollars}</h5>*/}
                  </div>
                  <div className="text-center fs-6 mt-4 fw-bold">
                    Your Estimated Emergency Fund (3-6 Months)
                  </div>
                  <div className="d-flex justify-content-center mt-3">
                    <Link className="btn btn-primary" aria-current="page" to="/subscribe">Subscribe for Updates</Link>
{/*                     <Link className="btn btn-primary" aria-current="page" to="/sign-up">Start Your Emergency Fund Today</Link> */}
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div className="wallet bg-white">
            <Row className="mx-0 align-items-center">
              <Col md={6}>
                {/*<a className="text-muted" href="https://www.nerdwallet.com/article/banking/emergency-fund-why-it-matters" target="_blank">*/}
                <img src="images/nerd.png" alt="Nerdwallet: Emergency Fund: What It Is and Why It Matters" />
                {/*</a>*/}

              </Col>
              <Col md={6}>
                <blockquote>
                  “Emergency funds create a financial buffer that can keep you
                  afloat in a time of need.”
                </blockquote>
              </Col>
              <Col md={6}>
                <img src="images/invest.png" alt="nerd" />
              </Col>
              <Col md={6}>
                <blockquote>
                  “Even a modest safety net can help buy you a little time
                  should you face an unforeseen financial crisis.”
                </blockquote>
              </Col>
              <Col md={6}>
                <img src="images/cnbc.png" alt="nerd" />
              </Col>
              <Col md={6}>
                <blockquote>
                  ”Building an emergency fund can give you the peace of mind.”
                </blockquote>
              </Col>
            </Row>
            <div className="text-center mt-5">
              <Link to="/subscribe" className="btn m-auto btn-primary py-3 px-4">
                Subscribe for Updates
              </Link>
{/*               <Link to="/sign-up" className="btn m-auto btn-primary py-3 px-4">
                Start Your Emergency Fund For Free
              </Link> */}
            </div>
          </div>
        </div>
      </section>
      {/* ---------------------------testimonial----------------------------- */}
      <section className="pdd overflow-hidden">
        <div className="circle-left ">
          <img src="images/left.png" alt="left-circle" />
        </div>

        <div className="container">
          <h2 className="fs-38 fw-bold text-center h-after mb-5">
            User Testimonials
          </h2>

          <Slider {...settings}>
            {testimonials.map((d, index) => (
              <article
                key={index}
                className=" text-left bg-white p-4 rounded-2 shadow"
              >
                <Rating
                  initialValue={5}
                  readonly
                  fillColor="#012E95"
                  size={20}
                />
                <p className="mt-2">{d.test}</p>
                <div className="d-flex align-items-start gap-3">
                  {/* <figure className="fig">
                    <img src="images/usr.png" alt="user" />
                  </figure> */}
                  <div>
                    <strong>{d.name}</strong>
                    <p>{d.loc}</p>
                  </div>
                </div>
              </article>
            ))}
          </Slider>
        </div>
      </section>

      
      {/* ------------------------payment system cards----------------------- */}
      <section className="pdd">
        <div className="square left ">
          <img src="images/square-left.png" alt="square" />
        </div>
        <div className="circle-right  ">
          <img src="images/right.png" alt="right-circle" />
        </div>
        <div className="container">
          <h2 className="fs-38 mb-5 fw-bold text-center h-after">
            Save And Pay with Numey
          </h2>
          <div className="gridbox">
            <div className="text-center bg-white shadow p-4 rounded-3">
              <img src="images/security.svg" alt="security" />
              <small className="text-primary text-uppercase d-block">
                Secure & Private 
              </small>
              <strong>Cutting Edge Security</strong>
              <p>
                Numey wallets use state-of-the-art web security to secure its wallets and transactions. Mandatory 2-factor authentication as well as a state-of-the-art encryption strategy keep your wallets securely under your control.
              </p>
            </div>
            <div className="text-center bg-white shadow p-4 rounded-3">
              <img src="images/stable.svg" alt="Stable" />
              <small className="text-primary text-uppercase d-block">
                Stable & Strong
              </small>
              <strong>Bye, Bye Inflation</strong>
              <p>
                Good moneys have a stable price. The more stable the price, the better the money. Bitcoin and other cryptos will never be stable enough to use as money. Even the US Dollar experiences constant inflation and periodic deflation. The Numey, however, maintains a constant, inflation-defensive value through its asset reserve.
              </p>
            </div>
            <div className="text-center bg-white shadow p-4 rounded-3">
              <img src="images/frictionless.svg" alt="Frictionless" />
              <small className="text-primary text-uppercase d-block">
                Frictionless
              </small>
              <strong>Instantaneous Transactions</strong>
              <p>
              Don't sit around waiting for your money. In-network Numey transactions will always be near instantaneous, clocking in around 2 seconds. Compare this with bank-to-bank transactions that take 1-3 days or Bitcoin transactions that can take between 10 minutes up to a week.
              </p>
            </div>
            <div className="text-center bg-white shadow p-4 rounded-3">
              <img src="images/lowfee.svg" alt="Low Fees" />
              <small className="text-primary text-uppercase d-block">
                Low Fees
              </small>
              <strong>The Most Affordable Money</strong>
              <p>
              It is free to buy, send, and receive Numey. There is a 1% fee when you sell Numey back for US Dollars. Compare this with traditional debit/credit card or PayPal fees range between 0.5-3%, and crypto currency payment systems like Coinbase can be anywhere from 0.5% to as much as 7%. 
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* ----------------------------get app today------------------------- */}
      <section className="pdd">
        <div className="square top50 right ">
          <img src="images/square.png" alt="square" />
        </div>
        <div className="circle-left  ">
          <img src="images/left.png" alt="square" />
        </div>
        <div>
          <h2 className="fs-38 fw-bold text-center h-after mb-5">
            The Numey App
          </h2>
        </div>
        <div className="flexbox pt-5">
          {phoneScreens.map((data, index) => (
            <div key={index} className={`cardd ${data.direction} ${data.padd}`}>
              <h2>{data.heading}</h2>
              <img src={data.src} alt="iphone" />
            </div>
          ))}
        </div>
        {/*<div className="d-flex my-5 flex-wrap gap-4 align-items-center justify-content-center">*/}
        <div className="text-center my-5">
          <h2 className="fs-4 fw-bold mb-0">
            Our App will be available when we launch. Subscribe for launch updates.
          </h2>
          <div className="my-4">
          {/*<div className="d-flex flex-column gap-4 flex-sm-row align-items-center justify-content-center">*/}
            {/*<a href="https://apps.apple.com/us/app/numey/id1607605765" target="_blank">*/}
              <img src={ appStore } width="160em" alt="appstore" />
            {/*</a>*/}
            <b className="text-dark mx-3">Or</b>
            {/*<a href="https://play.google.com/store/apps/details?id=com.numey&hl=en&gl=US" target="_blank">*/}
              <img src={ playStore } alt="playstore" width="180em" />
            {/*</a>*/}
          </div>
        </div>

        {/* 
        <div className="code-invite d-flex flex-column align-items-center">
          <p className="fs-4 fw-bold text-center">
            Enter your phone number to receive links to the Numey Apple and
            Android apps
          </p>
          <p>
            <InputMask
              className="form-control"
              id="phone"
              type="tel"
              name="phone"
              placeholder="+1(555) 333-4444"
              value={phone}
              onChange={e => setPhone(e.target.value)}
              mask="+1\(999) 999-9999"
              maskplaceholder={""}
              maskchar=" "
              required="required" />
          </p>
          <button className="btn btn-primary" onClick={sendAppStoreUrl} type="button" id="button-addon2">Receive App Link</button>
        </div>
        */}
      </section>
      
      {/* ------------------partner--------------------------------- */}
      <section className="pdd">
        <div className="square right ">
          <img src="images/square.png" alt="right-circle" />
        </div>
        <div className="circle-left">
          <img src="images/left.png" alt="right-circle" />
        </div>
        <div>
          <h2 className="fs-38 fw-bold text-center h-after mb-5">
            Our Partners & Services
          </h2>
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              <p className="text-center">
                Cryptocurrencies and other scams hide from regulation by basing
                themselves offshore and banks and other services won’t work
                with them because they are too risky and operate in the shadows. Not
                the Numey. The Numey is based in the US, headquartered in
                San Francisco, regulated by the IRS, and we work with multiple
                trusted banks and financial partners.
              </p>
            </div>
          </div>
          
        </div>
        <div className="cardsbox my-5">
          {partners.map((bs, index) => (
            <div className="flip-card " key={index}>
              <div className="flip-card-inner rounded-3">
                <div className="flip-card-front rounded-3 d-flex align-items-center justify-content-center">
                  <img
                    src={bs.src}
                    className="px-3"
                    alt="Avatar"
                    style={{ objectFit: "contain" }}
                  />
                </div>
                <div className="flip-card-back rounded-3">
                  <h3>{bs.data}</h3>
                  <p>{bs.desc}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="d-flex align-items-center justify-content-center">
          <Link to="/subscribe" className="btn m-auto btn-primary py-3 px-4">
            Get Your Free E-Book
          </Link>
{/*           <Link to="/sign-up" className="btn btn-primary p-3">
            Join The Numey Payment Network
          </Link> */}
        </div>
      </section>
      
      {/* ------------------------------------pricing---------------------------- */}
      <section className="pdd">
        <div className="circle-right ">
          <img src="images/right.png" alt="right-circle" />
        </div>
        <div>
          <h2 className="fs-38 fw-bold text-center h-after mb-5">
            Pricing & Fees
          </h2>
        </div>
        <div className="container bg-white shadow px-0">
          <div className="p-3 p-md-5">
            <Row className="mx-0 px-3 justify-content-between">
              <Col md={5}>
                <h2 className="fs-5 ">
                  Free for Individuals
                </h2>
                <p>
                  On the Numey Payment System individuals pay nothing to use
                  this amazing inflation-resistant and instantaneous payment and saving
                  system. Compare that to the fees associated with cryptocurrencies which are, in most
                  cases, exorbitantly high. Sometimes greater than the amount
                  you want to send! 
                </p>
              </Col>
              <Col md={5}>
                <h2 className="fs-5 ">Competitive Fees for Vendor Wallets</h2>
                <p>
                  Businesses will soon be able to get access to Numey's web and mobile payment SDKs. Business wallets
                  pay a small transaction fee of 0.5% + ǂ0.10. This is almost 6x less than standard credit/debit card processing
                  fees (2.9% + $0.30).
                </p>
              </Col>
            </Row>
            <div className="d-flex mt-4 align-items-center justify-content-center">
              <Link to="/subscribe" className="btn m-auto btn-primary py-3 px-4">
                Subscribe for Updates
              </Link>
{/*               <Link to="/sign-up" className="btn px-4 py-3 btn-primary">
                Start Your Free Wallet
              </Link> */}
            </div>
          </div>
          <div className="bg-primary px-5">
            <div className="col-g py-4 text-white d-flex justify-content-center align-items-center">
              <div className="text-center">
                <h2 className="fw-light fs-4 fs-lg-4 opac">Buy:</h2>
                <h2 className="fs-6 fs-md-5 fs-lg-4">0%</h2>
              </div>
              <div className="text-center">
                <h2 className="fw-light fs-4 fs-lg-4 opac">Receive:</h2>
                <h2 className="fs-6 fs-md-5 fs-lg-4">0%</h2>
              </div>
              <div className="text-center">
                <h2 className="fw-light fs-4 fs-lg-4 opac">Send:</h2>
                <h2 className="fs-6 fs-md-5 fs-lg-4">0%</h2>
              </div>
              <div className="text-center">
                <h2 className="fw-light fs-4 fs-lg-4 opac">Sell:</h2>
                <h2 className="fs-6 fs-md-5 fs-lg-4">0%</h2>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Home;

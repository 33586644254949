import React from 'react';
import { useEffect } from "react";

import { Routes, Route, useNavigate, useLocation, Navigate } from "react-router-dom";
import * as Fathom from "fathom-client";

import Navbar from "./Navbar";
import Footer from "./Footer";

import Home from "./Pages/Home";
import Faq from "./Pages/Faq";
import Token from "./Pages/Token";
import HowItWorks from "./Pages/HowItWorks";
import OurMission from "./Pages/OurMission";
import AboutMoney from "./Pages/AboutMoney";
import UserAgreement from "./Pages/UserAgreement";
import Privacy from "./Pages/Privacy";
import ChatGPT from "./Pages/ChatGPT";

import PasswordChange from "./Auth/PasswordChange";
import PasswordForgot from "./Auth/PasswordForgot";
import SignIn from "./Auth/SignIn";
import SignUp from "./Auth/SignUp";

import NewSub from "./Subs/NewSub";

import NewExternal from "./Externals/NewExternal";
import VerifyExternal from "./Externals/VerifyExternal";

import AgreeTOS from "./Users/AgreeTOS";
import ConfirmEmail from "./Users/ConfirmEmail";
import Profile from "./Users/Profile";
import TwoFactorAuth from "./Users/TwoFactorAuth";
import UsersEdit from "./Users/UsersEdit";

import Wallet from "./Txs/Wallet";

import Admin from "./Admin/Admin";
import AdminUsersList from "./Admin/AdminUsersList";
import AdminTxsList from "./Admin/AdminTxsList";
import AdminTransfersList from "./Admin/AdminTransfersList";

import Blog from "./Blog/Blog";
import PostInfaltionAtXmas from "./Blog/PostInflationAtXmas";
import PostFedWithOneMandate from "./Blog/PostFedWithOneMandate";
import PostFedPaymentSystem from "./Blog/PostFedPaymentSystem";
import PostFiveBenefits from "./Blog/PostFiveBenefits";
import PostInflationSurvivorsGuide from "./Blog/PostInflationSurvivorsGuide";
import PostFinancialCrises from "./Blog/PostFinancialCrises";
import PostIsraelPalastine from "./Blog/PostIsraelPalastine";

import NumeyValueChart from "./ExchangeRates/NumeyValueChart";

import AuthService from "./AuthService";
import { UserContext } from "./UserContext";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "./App.scss";
import MainLayout from "./layouts/Layout";
import GuestLayout from "./layouts/GuestLayout";
import AuthLayout from "./layouts/AuthLayout";
import Buynumey from "./Txs/BuyNumey";
import Sendnumey from "./Txs/SendNumey";
import Sellnumey from "./Txs/SellNumey";
import EditProfile from "./Users/UserProfile/EditProfile";
import VerifyIdentity from "./Auth/VerifyIdentity";
import PersonalInfo from "./Auth/PersonalInfo";

function AppRouter() {
  // const { user } = UserContext();
  return (
    <>
      <Routes>
        <Route element= {<GuestLayout />}>
          <Route path="/" element={<Home />} />
          <Route path="/about-money" element={<AboutMoney />} />
          <Route path="/ourmission" element={<OurMission />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/howitworks" element={<HowItWorks />} />
          
          <Route path="/the-numey" element={<Token />} />
          <Route path="/user-agreement" element={<UserAgreement />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/chatgpt-inflation" element={<ChatGPT />} />
          <Route path="/chart" element={<NumeyValueChart />} />

          <Route path="/funding-source/:externalId/verify" element={<VerifyExternal />} />

          <Route path="/admin-dashboard" element={<Admin />} >
            <Route index element={<Navigate to="/admin-dashboard/users" replace />} />
            <Route index path="/admin-dashboard/users" element={<AdminUsersList />} />
            <Route index path="/admin-dashboard/txs" element={<AdminTxsList />} />
            <Route index path="/admin-dashboard/transfers" element={<AdminTransfersList />} />
          </Route>

          <Route path="/blog" element={<Blog />}>
            <Route index element={<Navigate to="/blog/inflation-linked-currencies-in-financial-crises" replace />} />
            <Route path="/blog/inflation-linked-currencies-in-financial-crises" element={<PostFinancialCrises />} />
            <Route path="/blog/how-inflation-ruins-the-holidays" element={<PostInfaltionAtXmas />} />
            <Route path="/blog/inflation-in-israel-and-palastine" element={<PostIsraelPalastine />} />
            <Route path="/blog/the-federal-reserve-with-one-mandate" element={<PostFedWithOneMandate />} />
            <Route path="/blog/federal-reserve-fails-to-update-payment-system" element={<PostFedPaymentSystem />} />
            <Route path="/blog/five-benefits-to-an-inflation-proof-economy" element={<PostFiveBenefits />} />
            <Route path="/blog/the-ultimate-inflation-survival-guide" element={<PostInflationSurvivorsGuide />} />
          </Route>
        </Route>
        <Route element={<AuthLayout />}>
          <Route path="/wallet" element={<Wallet />} />
          <Route path="/me" element={<Profile />} />
          <Route path="/buy" element={<Buynumey />} />
          <Route path="/send" element={<Sendnumey />} />
          <Route path="/sell" element={<Sellnumey />} />
          <Route path="/profile/edit" element={<EditProfile />} />
          <Route path="/add-funding-source" element={<NewExternal />} />
          <Route path="/verify-identity" element={<VerifyIdentity />} />
          {/* <Route path="/ourmission" element={<OurMission />} />
          <Route path="/howitworks" element={<HowItWorks />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/blog" element={<Blog />} /> */}
        </Route>
        <Route element={<MainLayout />}>
          {/* // AUTHENTICATION */}
          <Route path="/sign-in" element={<SignIn />} />
          <Route path="/sign-up" element={<SignUp />} />
          <Route path="/subscribe" element={<NewSub />} />
          <Route path="/personal-information" element={<PersonalInfo />} />
          <Route path="/forgot-password" element={<PasswordForgot />} />
          <Route path="/change-password" element={<PasswordChange />} />
          <Route path="/two-factor-Auth" element={<TwoFactorAuth />} />
          <Route path="/confirm-email/:token" element={<ConfirmEmail />} />
        </Route>
      </Routes>
    </>
  );
}
export default AppRouter;

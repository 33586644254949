import React, { useState } from "react";
import Cookies from "universal-cookie";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import AuthService from "../AuthService";
import { toast } from "react-toastify";

import { UserContext } from "../UserContext";

function SellNumey(props) {
  const { user, setUser } = UserContext();
  const { getCurrentUser } = AuthService();

  const [amount, setAmount] = React.useState("0.00");
  const [externalTargetId, setExternalTargetId] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [isOpen, setIsOpen] = React.useState(false);
  const [selectedItem, setSelectedItem] = React.useState("");

  const [feeInScript, setFeeInScript] = React.useState(0);
  const [feeInDollars, setFeeInDollars] = React.useState(0);
  const [amountTotal, setAmountTotal] = React.useState(0);
  const [amountTotalInDollars, setAmountTotalInDollars] = React.useState(0);
  const [conversionRateToDollars, setConversionRateToDollars] =
    React.useState(0);
  const [prettyConversionRateToDollars, setPrettyConversionRateToDollars] =
    React.useState("");

  const [amountInDollars, setAmountInDollars] = React.useState("");

  const navigate = useNavigate();

  React.useEffect(() => {
    // GET CONVERSION RATE
    const url =
      process.env.REACT_APP_BASE_DOMAIN + "/api/exchange-rates/current";
    axios
      .get(url)
      .then((response) => {
        setConversionRateToDollars(response.data.conversionRateToDollars);
        setPrettyConversionRateToDollars(
          response.data.prettyConversionRateToDollars
        );
      })
      .catch((err) => {
        toast.warning(err.response.data.message);
      });
  }, []);

  const maxScript = (e) => {
    // balance - fee for transfering whole balance.
    const max = (100 * user.balance) / 101;
    const feeInScript = (max * 0.01).toFixed(2);
    const feeInDollars = (feeInScript * conversionRateToDollars).toFixed(2);
    const amountTotal = (parseFloat(max) + parseFloat(feeInScript)).toFixed(2);
    const amountTotalInDollars = (
      amountTotal * conversionRateToDollars
    ).toFixed(2);

    setAmount(max.toFixed(2));
    setFeeInScript(feeInScript);
    setFeeInDollars(feeInDollars);
    setAmountTotal(amountTotal);
    setAmountTotalInDollars(amountTotalInDollars);
  };

  const handleAmountChange = (e) => {
    const value = e.target.value.slice(0, -1); // input box value before keyup
    const input = e.target.value[e.target.value.length - 1]; // input char
    const intValue = parseInt(value.replace(/\D/g, "")); // replace the specail char
    const finalVal = intValue + input; // add like string and format it
    var formattedVal = (finalVal / 100).toFixed(2); // 12.34
    if (finalVal == "00" || isNaN(intValue)) {
      // if 00 or NaN reset
      formattedVal = "0.00";
    }

    const calcAmount = parseFloat(formattedVal || 0);
    const amountInDollars = (calcAmount * conversionRateToDollars).toFixed(2);
    const feeInScript = (calcAmount * 0.01).toFixed(2);
    const feeInDollars = (feeInScript * conversionRateToDollars).toFixed(2);
    const amountTotal = (calcAmount + parseFloat(feeInScript)).toFixed(2);
    const amountTotalInDollars = (
      amountTotal * conversionRateToDollars
    ).toFixed(2);

    setAmount(formattedVal);
    setAmountInDollars(amountInDollars);
    setFeeInScript(feeInScript);
    setFeeInDollars(feeInDollars);
    setAmountTotal(amountTotal);
    setAmountTotalInDollars(amountTotalInDollars);
  };

  const handleSubmit = (e) => {
    try{
      e.preventDefault();
      setLoading(true);
    
      if(!externalTargetId) {
        toast.warning("Please choose the funding source");
        setLoading(false);
        return
      }

      if(amount <= 0) {
        toast.warning("Amount must be greater then ǂ0.00");
        setLoading(false);
        return
      }
      
      const feeInNumey = +amount*0.01
      const amountWithFeeInNMY = (+amount+feeInNumey).toFixed(2);
      const amountWithFeeInDollors = (+amountTotalInDollars+ +amountTotalInDollars*0.01).toFixed(2);

      const body = {
        amount: amountWithFeeInNMY,
        amountInDollars: amountWithFeeInDollors,
        externalTargetId: externalTargetId,
      };
  
      if (amountTotal > user.balance) {
        toast.warning("You have insufficient funds to complete this transaction.");
        setLoading(false);
        return false;
      }
  
      const cookies = new Cookies();
      const authToken = cookies.get("nJWT");
      const url = process.env.REACT_APP_BASE_DOMAIN + "/api/txs/sell";
      const config = { headers: { Authorization: `Bearer ${authToken}` } };
  
      axios
        .post(url, body, config)
        .then((response) => {
          getCurrentUser().then((user) => {
            setUser(user);
            toast.success("Numey sold!");
            navigate("/wallet");
          });
        })
        .catch((err) => {
          toast.warning(err.response.data.message);
          setLoading(false);
        });
      } catch(error){
        console.log("error while selling nummy", error)
      }
 
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleItemClick = (item) => {
    setSelectedItem(item);
    setIsOpen(false); // Close the dropdown after selecting an item
  };

  return (
    <>
      {user && (
        <>
          <div className="breadcrumbar">
            <h4>Sell Numey</h4>
          </div>
          <div className="wallet-bar row">
            <form onSubmit={handleSubmit}>
              <div className="col-lg-8 offset-lg-2">
                <div className="text-center main_balance my-5">
                  <span className="nmy">ǂ</span>
                  <input
                    name="amount"
                    value={amount}
                    onChange={handleAmountChange}
                    type="number"
                    placeholder="0.00"
                    step="0.01" min="1" max="10000"
                    required="required"
                    className="form-control"
                    autoFocus
                  />
                  <div className="mt-3">
                    <small className="text-muted">{`($${(parseFloat(amount)*conversionRateToDollars).toFixed(2)})`}</small>
                    <div className="mt-2">
                      <small className="text-muted">Fee (1%): ǂ{parseFloat(amount * 0.01).toFixed(2)} NMY (${parseFloat((amountTotalInDollars * 0.01).toFixed(2))})</small>
                    </div>
                  </div>
                </div>
                <div className="mb-4">
                  <label className="form-label d-flex justify-content-between">
                    <>Total</>
                    <a className="text-muted" onClick={maxScript}>Maximum</a>
                  </label>
                  <div className="my-2">ǂ{parseFloat(amountTotal).toFixed(2)} NMY (${parseFloat(amountTotalInDollars).toFixed(2)})</div>
                </div>
                
                <label className="form-label">Transfer to</label>
                <div className={`select-buy-numey-dropdown ${isOpen ? "open" : ""}`}>
                  <select
                    className="form-select"
                    id="external"
                    name="externalTargetId"
                    value={externalTargetId || setExternalTargetId(user.externals[0].id)}
                    defaultValue={user.externals[0].id}
                    required="required"
                    onChange={e => setExternalTargetId(e.target.value)}>
                    <option></option>
                    {user.externals.filter(e => { return !!e.verifiedAt }).map((e) => {
                      return <option selected={user.externals[0].id == e.id ? true : false} key={e.id} value={e.id}>{e.accountInfo} ({e.name})</option>
                    })}
                  </select>
                </div>
                <div className="my-2">
                  <p className="form-text">Your funds will be available once the transaction processes, which may take upto 3-5 days.</p>
                </div>
                <div className="">
                  <button
                    type="submit"
                    className="btn btn-primary w-100" 
                    disabled={loading}
                  >
                    Confirm and Sell
                  </button>
                </div>
              </div>
            </form>
          </div>
        </>
      )}
    </>
  );
}
export default SellNumey;

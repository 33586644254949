import React from 'react';
import { Link } from 'react-router-dom';

function Privacy() {
  return (
    <div className="about">
      <div className="container mt-5 mb-5">
        <h2 className="quote text-center mb-5">Privacy Statement</h2>
        <div className="row">
          <div className="col-lg-8 offset-lg-2">
            <p className="lead text-center">Last updated April 5 2022</p>
            <p>Welcome!</p>
            <p>
              The New Reserve LLC ("The New Reserve,” "Numey", “we,” “us,” “our”) takes your privacy seriously. To make this privacy policy easy to find, we make it available from the home page of our website at www.paywithnumey.com (the "website") and through our mobile applications (together with the website, the “Services”). You may also email us with any privacy-related questions you have to hello@paywithnumey.com. We may periodically post changes to this Privacy Statement on this page, and it is your responsibility to review this Privacy Statement frequently and we encourage you to visit this page often. When required by law, we will notify you of any changes to this Privacy Statement. By using or accessing the Services in any manner, you acknowledge that you accept the practices and policies outlined in this Privacy Statement, and you hereby consent that we will collect, use and share your personal information as set forth below.
            </p>

            <div className="fw-bold">Applicability of Privacy Statement</div>
            <p>
              This privacy policy applies to all personal information we collect through our web applications. This policy does not apply to the practices of companies that Numey does not own or control, or to individuals that Numey does not employ or manage.
              Throughout this policy, we use the term "personal information" to describe information that can be associated with a specific person or company and can be used to identify that person, including Account Information, Identification Information, Financial Information and KYC Information, each as described below. We do not consider personal information to include information that has been anonymized so that it does not identify a specific user.
            </p>

            <div className="fw-bold">The Information we Collect</div>
            <p>
              When you visit the website or use one of our mobile applications, we collect your IP address, and standard web log information, such as your browser type and the pages you accessed on our website. We also may collect certain geo-location information. If you do not agree to our collection of this information, you may not be able to use our Service.
            </p>

            <p>We may also collect the below information with you.</p>
            <ol>
              <li>“Account Information” meaning text-enabled cellular/wireless telephone number, machine or mobile device ID.</li>
              <li>“Identification Information” meaning your name, street address, email address, date of birth, and SSN (or other governmental issued verification numbers).</li>
              <li>"Device Information" meaning we also may obtain information about you: (a) from your mobile device such as your device type, machine or mobile device identification number, geo-location, time zone, language setting, browser type, and IP address, and (b) from third parties for purposes of transaction processing, identity verification, fraud detection or prevention and other similar purposes. For fraud prevention purposes, we also may link your machine ID with the machines of others who use your same payment cards.</li>
              <li>“Financial Information” meaning bank wallet and routing numbers and credit cards linked to your Numey wallet.</li>
              <li>"Know Your Customer Information" (“KYC Information”) meaning information collected through a W-9 and other Identification Information.</li>
            </ol>
            <p>
              Before permitting you to use our Services, we may require additional information from you. We may also obtain personal information about you from third parties such as identity verification, fraud prevention and similar services.
            </p>
            <p>
              When you are using our Services, we collect information about your wallet transactions and trust relationships and we may collect information about your computer or other access device for fraud prevention and other similar purposes. We may also collect personal information related to your contact with our customer support team.
            </p>
            <p>
              We do not seek or knowingly collect any personal information about children under 13 years of age. If we become aware that we have unknowingly collected information about a child under 13 years of age, we will make commercially reasonable efforts to delete such information from our database.
            </p>

            <div className="fw-bold">How we use Cookies and other Tracking Technologies</div>
            <p>
              When you access our website, we, or companies we hire to track how our website is used, may place small data files called cookies or pixel tags on your computer or other device. Users are free to decline our cookies if your browser permits, but doing so may interfere with your use of our website.
            </p>

            <div className="fw-bold">How we Protect and Store Personal Information</div>
            <p>
              We store and process your personal information using third-party servers located in secure data centers in the United States. This information is protected by physical, electronic and procedural safeguards in compliance with applicable U.S. federal and state regulations. We also use computer safeguards such as firewalls and data encryption, we enforce physical access controls to our office and files, and we authorize access to personal information only for those employees who require it to fulfill their job responsibilities. By using Numey, you are consenting to have your personal information stored, transferred and processed in the United States.
            </p>
            <p>
              We make reasonable efforts to ensure security on our systems. Despite our efforts, we cannot guarantee that personal information may not be accessed, disclosed, altered or destroyed by breach of our administrative, managerial and technical safeguards. Therefore, we urge you to take adequate precautions to protect your personal information as well, including never sharing your password with anyone.
            </p>
            <p>
              If Numey learns of a systems security breach, we may attempt to notify you electronically so that you can take appropriate protective steps. By using our services, you agree that Numey may communicate with you electronically. Numey may post a notice on the Website if a security breach occurs. Numey may also send you an email to you at the email address you have provided to us.
            </p>

            <div className="fw-bold">How we use the Personal Information we Collect</div>

            <p>Our primary purpose in collecting personal information is to provide you with a good user experience.</p>

            <p>We may use your personal information to:</p>
            <ul>
              <li>provide the services and customer support you request;</li>
              <li>process transactions and send notices about your transactions or your network activity;</li>
              <li>resolve disputes, collect fees, and troubleshoot problems;</li>
              <li>prevent potentially fraudulent, prohibited or illegal activities;</li>
              <li>compare information for accuracy and verify it with third parties; and</li>
              <li>perform other duties as required by law.</li>
            </ul>

            <div className="fw-bold">Retention Policy</div>
            <p>We only store personal information as long as it is needed for the purpose for which it was collected.</p>
            <p>All personal information processed in connection with cookies are stored in accordance with our cookie policy.</p>

            <div className="fw-bold">How we Share Personal Information with other Numey Users</div>

            <p>To process your payments on Numey, we need to share some of your personal information with the person or company that you are paying or is paying you. Regardless, we will not disclose your credit card number or bank wallet number to anyone you have paid or who has paid you through Numey, except with your express permission or if we are required to do so to comply with a subpoena or other legal process.</p>

            <div className="fw-bold">How we Share Personal Information with other Parties</div>

            <p>Numey does not share your email address or phone number with third parties for promotional or marketing purposes.</p>

            <p>We may share your personal information with:</p>
            <ul>
              <li>Companies that we plan to merge with or be acquired by or, in the event of any bankruptcy, a bankruptcy estate. Should such a combination occur, we will require that the new combined entity follow this privacy policy with respect to your personal information. If your personal information could be used contrary to this policy, you will receive prior notice and the opportunity to communicate preferences you may have, if applicable.</li>
              <li>Financial institutions with which we partner;</li>
              <li>Law enforcement, government officials, or other third parties when we are compelled to do so by a subpoena, court order or similar legal procedure, when we need to do so to comply with law we believe in good faith that the disclosure of personal information is necessary to prevent physical harm or financial loss, to report suspected illegal activity, or to investigate violations of our User Agreement, or as otherwise required by law;</li>
              <li>Third party service providers who assist us in providing services to you or who provide fraud detection or similar services on our behalf;</li>
              <li>Service providers under contract who help with parts of our business operations such as marketing and technology services;</li>
              <li>Other third parties with your consent or direction to do so;</li>
              <li>Numey may use Plaid Technologies, Inc. to verify your bank wallet. Numey only shares your information with Plaid in accordance with this Privacy Statement. Information shared with Plaid is treated by Plaid in accordance with its Privacy Statement, available at https://plaid.com/legal/.</li>
              <li>We also share your personal information with Sift Science for fraud detection and prevention. Information shared with Sift is treated by Sift in accordance with its Privacy Statement, available at https://sift.com/service-privacy.</li>
              <li>We may also use Onfido for Identification Verification. Information shared with Onfido is treated by Onfido in accordance with its Privacy Statement, available at https://onfido.com/privacy/.</li>
              <li>We may also use third-party KYC and anti-money laundering tools to verify the identity of users to help combat fraud and improve user experiences.</li>
            </ul>
            <p>
              If you establish a Numey wallet indirectly on a third party website or via a third party application, any information that you enter on that website or application (and not directly on Numey's website) will be shared with the owner of the third party website or application and your information may be subject to their privacy policies.
            </p>

            <div className="fw-bold">Your Rights and Choices as it relates to your Personal Information</div>
            <p>You have the right to ask us not to process your personal information where we process it on the basis of your legitimate interests. You also can review and update your personal information at any time by logging into your paywithnumey.com wallet. You should promptly update your personal information if it changes or is inaccurate.</p>

            <p>You can deactivate your wallet from within the app. In addition, you may opt-out of providing personal information via the mobile application at any time by uninstalling the mobile application using the standard uninstall process available on your mobile device or via the mobile application marketplace or store. We may retain your personal information in accordance with our retention policy and to the extent required to comply with (or we deem it reasonable in light of) any laws or regulations.</p>
            <p>You have the right to receive confirmation on whether or not we process personal information concerning you, and in such cases get access to such personal information and also information regarding the personal information and how we process it.</p>
            <p>You can request a copy of your personal information which we hold, relating to you and that you have provided us, in a commonly used electronic format. If you would like a copy of some or all of it, please contact us and let us know the information you want a copy of, including any wallet or reference numbers, if you have them. Any subject access request may be subject to a reasonable fee to cover the cost of providing you with details of the information we hold about you.</p>
            <p>You can require us to correct any mistakes in your information which we hold without charge or undue delay. Taking into wallet the purposes of the processing, you also have the right to have incomplete personal information about you completed. If you would like to do this, please contact us and let us know the information that is incorrect and the information you want it replaced with.</p>
            <p>
              You have the right to withdraw a consent provided by you at any time by contacting us. If your consent is withdrawn, we will no longer process your personal information for the purpose that you had given your consent to. In some circumstances, you have the right to obtain restriction of the processing of your personal information. For example, if you contest the accuracy of the personal information, you can also require that we restrict the processing of your personal information for such a period that enables us to verify the accuracy of the personal information. You have the right to object to processing of your personal information that is based on our legitimate interests. If this is done, we must provide compelling legitimate grounds for the processing which overrides your interests, rights and freedoms, in order to proceed with the processing of your personal information.
            </p>
            <p>You may always contact Numey at hello@paywithnumey.com if you have any questions or comments regarding our processing of your personal information. You also have the right to complain about the processing of your personal information by lodging a complaint to the relevant data protection authority.</p>

            <div className="fw-bold">Links to other Sites</div>

            <p>The Website may contain links to other sites. Numey does not control the information collection of sites that can be reached through links from the website.</p>

            <div className="fw-bold">Changes to our Privacy Statement</div>

            <p>
              We may occasionally update this Privacy Statement. If we modify this privacy policy, we will post the revised privacy policy to the website, and we will also revise the "last updated date" stated above. If we make material changes in the way we use personal information, we will notify you by posting an announcement on our service or by sending you an email. It is your responsibility to periodically review this privacy policy and users are bound by any changes to the privacy policy by using the service after such changes have been first posted.
            </p>

            <div className="fw-bold">How to Contact us</div>
            <p>
              If you have questions or concerns regarding this policy, or any feedback that you would like us to consider, please email us at hello@paywithnumey.com.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Privacy

import React from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";
import Cookies from "universal-cookie";
import { toast } from "react-toastify";
import logoHorizontal from '../images/logo-horizontal.jpg';

function PasswordChange() {
  const [password, setPassword] = React.useState("");
  const [passwordShown, setPasswordShown] = React.useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const navigate = useNavigate();
  const resetToken = searchParams.get("resetToken");

  const handleSubmit = (e) => {
    e.preventDefault();
    const user = {
      password: password,
      resetToken: resetToken,
    };

    const url = process.env.REACT_APP_BASE_DOMAIN + "/api/change-password";
    axios
      .put(url, user)
      .then((response) => {
        toast.success("Password successfully reset!");
        navigate("/sign-in");
      })
      .catch((err) => {
        toast.warn(
          "There was a problem resetting your password. Please try again."
        );
        console.log(err);
      });
  };

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  return (
    <>
      <div className="authentication-block">
        <div className="sidebar">
          <div className="logobar">
            <img alt="logo" src={ logoHorizontal } className="logo" />
          </div>
          <div className="ilustrationbar">
            <img src="images/cuate.png" alt="ilustrationbar" />
          </div>
          <div className="sidebar-bottomside">
            <h5>Get Numey Mobile App</h5>
            <p>
              Numey mobile app is now also available on Google play and App
              store
            </p>
          </div>
        </div>
        <div className="Auth-bar centeredbox">
        <div className="logobar mobenable" onClick={()=>navigate("/")} style={{cursor:"pointer"}}>
            <img alt="logo" src={ logoHorizontal } className="logo" />
          </div>
          <form onSubmit={handleSubmit}>
          <div className="formtitle">
              <h3>Change Password</h3>
              <p>You can change the password.</p>
            </div>
            <input type="hidden" name="resetToken" />
            <div className="mb-3">
              <label className="form-label" htmlFor="password">
                Password<span className="text-danger">*</span>
              </label>
              <div className="input-group">
                <input
                  className="form-control"
                  id="password"
                  type={passwordShown ? "text" : "password"}
                  name="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  autoComplete="off"
                  required="required"
                />
                <button
                  className="btn btn-light btn-sm border"
                  type="button"
                  onClick={togglePassword}
                >
                  {passwordShown ? "Hide" : "Show"}
                </button>
              </div>
            </div>
            <div className="text-end">
            <button
                className="me-3 btn btn-link link-secondary text-decoration-none w-100"
                onClick={() => navigate(-1)}
              >
                Cancel
              </button>
              <button className="btn w-100 btn-primary" type="submit">
                Update Password
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
export default PasswordChange;

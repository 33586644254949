import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: true,
      text: "The Value of the US Dollar (1940-2020)",
      font: {
        size: 22,
        color: "#040815",
      },
      padding: {
        bottom: 30,
      },
    },
  },
};

const labels = [
  1940, 1945, 1950, 1955, 1960, 1965, 1970, 1975, 1980, 1985, 1990, 1995, 2000, 2005, 2010, 2015, 2020
];

export const data = {
  labels,
  datasets: [
    {
      label: "",
      data: [
        728, 714, 551, 424, 373, 340, 262, 190, 122, 94, 78, 66, 59, 52, 46, 42, 38,
      ],
      borderColor: "#012E95",
      backgroundColor: "#012E95",
    },
  ],
};
export default function App() {
  return <Line options={options} data={data} />;
}


// VerifyIdentity.js

import React from "react";
import { useNavigate } from "react-router-dom";
import AuthService from '../AuthService'
import { UserContext } from "../UserContext";
import { toast } from 'react-toastify';
import logoHorizontal from '../images/logo-horizontal.jpg';

import SumsubWebSdk from '@sumsub/websdk-react'
import Navbar from "../Navbar";

function VerifyIdentity(props) {
  const [token, setToken] = React.useState("");

  const { user, setUser } =UserContext();
  const { getCurrentUser } = AuthService();

  const { getNewAccessToken } = AuthService();

  const navigate = useNavigate();

  React.useEffect(() => {
    // GET SUMSUB ACCESS TOKEN
    getNewAccessToken()
      .then(response => {
        setToken(response.token);
      })
      .catch(err => {
        toast.warning('There was an problem connecting to our identity verification system. Please refresh the page.')
      });
  }, [])

  const messageHandler = (data, payload) => {
 
    if (data == "idCheck.applicantStatus") {
      // console.log(payload)
      if (payload.reviewStatus == "pending") {
          toast.success("Wait one moment while we verify your identity.");
      } else if (payload.reviewResult) {
        const reviewAnswer = payload.reviewResult.reviewAnswer
        if (reviewAnswer == "GREEN") {
          getCurrentUser(user => {
            setUser(user)
            toast.success("Congradulations! Your identity has been verified.");
            navigate('/wallet');  
          })
        } else if (reviewAnswer == "YELLOW") {
          toast.success("Our identity partner has begun to verify your identity. This can take from two minutes to two hours to complete.");
          navigate('/wallet');
        } else if (reviewAnswer == "RED") {
          if (payload.reviewResult.reviewRejectType == "RETRY") {
            toast.success("You may resubmit your documents.");
          } else {
            toast.success("Your identity verification has been rejected. An agent will review this rejection and message you with further information.");
            navigate('/wallet');  
          }
        }
      } else {
        toast.warning("There was a problem verifying your identity. Please try again.");
        navigate('/wallet');
      }
    }
  }

  const errorHandler = (err) => {
    console.log(err);
    toast.warning(err)
  }

  const uploadErrorHandler = (err) => {
    console.log(err);
    toast.warning(err.msg)
  }


  return(
    <>
      <div className="breadcrumbar">
        <h4>Verify Your Identity</h4>
      </div>
      <div className="wallet-bar">

{/*        <div className="sidebar">
        <div className="logobar" onClick={()=>navigate("/")} style={{cursor:"pointer"}}>
          <img alt="logo" src="images/login-logo.svg" />
        </div>
        <div className="ilustrationbar">
          <img src="images/cuate.png" alt="ilustrationbar" />
        </div>
        <div className="sidebar-bottomside">
          <h5>Get Numey Mobile App</h5>
          <p>
            Numey mobile app is now also available on Google play and App
            store
          </p>
        </div>
      </div>*/}
      <div className="mx-auto">
        <div className="logobar mobenable" onClick={()=>navigate("/")} style={{cursor:"pointer"}}>
          <img alt="logo" src={ logoHorizontal } className="logo" />
        </div>
{/*        <div className="formtitle text-center">
          <h3>Verify Your Identity</h3>
          <p>Please follow the instructions to verify your identity.</p>
        </div>*/}
        <div className="container">
          {token &&
            <SumsubWebSdk
              accessToken={token}
              expirationHandler={getNewAccessToken}
              onMessage={messageHandler}
              onError={errorHandler}
              onUploadError={uploadErrorHandler}
            />
          }
        </div>
      </div>
      </div>
    </>
  );
}
export default VerifyIdentity

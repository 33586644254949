import React from "react";
import { Accordion, Col, Row } from "react-bootstrap";
import moneyBags from '../images/faq-2.png';

const Faq = () => {
  const acc = [
    {
      heading: "What is Numey?",
      data: 'The Numey is an electronic monetary token the same way that Federal Reserve Notes (USD) or any other form of money is a monetary token. Like any form of money, the Numey is not a stock or a bond or a debt instrument that earns any sort of dividend or interest. The Numey gains its value because people trust in its strong asset reserve—the only currency in the world to have a reserve made up of assets that represent the economy as a whole called "total market Exchange Traded Funds (ETFs).',
    },
    {
      heading: "Is Numey legal? How is it regulated?",
      data: 'Yes! The Numey is legal. It is legal to make private currencies inside the U.S.. In order to be a legal money, you cannot commit fraud and you have to help guard against crime and terrorism. Numey follows all state and federal financial regulations, and is an upstanding American company.',
    },
    {
      heading: "When I buy Numey, where does my money go?",
      data: 'Numey is an asset reserve currency, so when you buy Numey all of your money comes into Numey\'s reserve account and then is converted into total market index funds. Aboslutely 0% of your money goes into the operational budgets of the company. The operational budgets and the reserve are entirely and categorically separate from each other.',
    },
    {
      heading: "How does Numey make money?",
      data: 'It is free to buy and send Numey, so how does Numey make money? Numey makes money by charging a 1% fee when people buy back U.S. Federal Reserve Notes (US Dollars) from the Numey Payment System. We do not make any money or use or touch any part of the Numey reserve.',
    },
    {
      heading: "What are Numey's fees?",
      data: 'Transacting on the Numey Payment System is free, but we charges a 1% fee when you sell Numey and buy back USD.',
    },
    {
      heading: "Do I have to pay taxes on any income I get from Numey?",
      data: 'Yes. The Numey is not an investment and should not be entered into to make any real gain. If you realize any nominal gains, you must pay capital gains taxes on those.',
    },
    {
      heading: "Is Numey a cryptocurrency? Why not?",
      data: 'The Numey is NOT a cryptocurrency. Cryptocurrency technology is slow and risky and cannot protect the privacy of our customers. There is no need to use cryptocurrency technology to operate a modern and technologically advanced currency.',
    },
    {
      heading: "Can I get a loan with Numey?",
      data: 'Today there are no banks that offer loans denominated in Numey, but we hope someday they will!',
    },
    {
      heading: "Can I ask my employer to pay me in Numey?",
      data: 'Having your salary denominated in Numey protects your income from inflation, so it is a really good idea to ask your employer to pay you in Numey, but there is no guarantee they will say "Yes".',
    },
  ];
  return (
    <>
      <section>
        <div className="bg-faq pdd">
          <h2 className="fs-1 fw-bold text-center">
            Frequently Asked Questions
          </h2>
        </div>
        <div className="pdd container">
          <Row>
            <Col lg={{span: 8, offset: 2}}>
              <Accordion defaultActiveKey="0">
                {acc.map((i, index) => (
                  <Accordion.Item
                    key={index}
                    className="shadow p-2 my-3 rounded-4 border-0"
                    eventKey={index}
                  >
                    <Accordion.Header className="fw-bold">
                      {i.heading}
                    </Accordion.Header>
                    <Accordion.Body>{i.data}</Accordion.Body>
                  </Accordion.Item>
                ))}
              </Accordion>
            </Col>
          </Row>
          
        </div>
        <div className="p-2 p-md-5 shadow my-5 container">
          <h2 className="fs-1 fw-bold text-center h-after mb-5">
            Is Numey a Ponzi Scheme or a Pyramid Scheme
          </h2>
          <div>
            <Row className="mx-0 align-items-top">
              <Col lg={4}>
                <img src={ moneyBags } alt="faq" className="mt-4" />
              </Col>
              <Col lg={{span: 7, offset: 1}} className="mt-5 mt-md-0">
                <div className="text-color">
                  <strong className="fs-5 fw-bold text-black">
                    The Numey is not a Ponzi Scheme. Here's why.
                  </strong>
                  <small className="my-3 d-block">
                    A Ponzi Scheme is when grifters use the money from new
                    entrants to the scheme to pay old entrants. The fraudster
                    enriches themselves by extracting money from the scheme all
                    the while claiming the investment is doing extraordinarily
                    well (to attract new entrants). Eventually there are not
                    enough new entrants to pay the old entrants and the scheme
                    falls into a liquidity trap and is revealed. Classic Ponzi
                    Schemes throughout history included Charles Ponzi ($15M),
                    Scott Rothstein ($1.2B), Tom Petters ($3.7B), and Bernie
                    Madoff ($20B). In 2022, <a href="https://www.forbes.com/sites/barrycollins/2022/01/15/cryptocurrency-nothing-more-than-a-pyramid-scheme-says-browser-boss/?sh=75ed65fe5ed8" target="_blank" rel="noopener noreferrer">Fortune</a> magazine wrote "Cryptocurrencies like
                    BitCoin work the same way as Ponzi schemes."
                  </small>
                  <small className="d-block">
                    Since Numey maintains a strong asset reserve, people selling
                    Numey (old entrants) are paid using the part of the reserve
                    they created when they first bought Numey, not with money
                    from a recently new entrant's money. The Numey reserve is
                    untouched and hermetically isolated from the rest of the
                    revenues (from fees) and operational funds (such as
                    donations and investments) of the business. 
                  </small>
                  <br/>
                  <strong className="fs-5 fw-bold text-black">
                    The Numey is not a Pyramid Scheme. Here's why.
                  </strong>
                  <small className="my-3 d-block">
                    A Pyramid Scheme is when grifters design an organization where early entrants
                    to the system make money by requiring future entrants to pay
                    to participate in the scheme. There are always requirements
                    for any new entrant to bring in even more entrants thus
                    propping up the whole fraud. Classic Pyramid Schemes are
                    multi-level marketing frauds like Amway, Herbalife, Nu Skin
                    Enterprises, and many others.
                  </small>
                  <small className="d-block">Numey has no "required
                    minimums" or "new user down-payments" like Pyramid Schemes
                    and no requirement for users to bring in new users to the
                    Numey Payment System.
                  </small>

                  <small className="my-3 d-block">
                    Technology moving forward at a rapid pace means improvements that
                    can help a lot of people, but it also means opportunities for 
                    grifters to take advantage of people. At the Numey, however, we are 
                    hell bent on providing a currency that is upright and straight forward,
                    that doesn't rely on schemes or sliminess. Numey works because 
                    it relies on a perfectly valid way to make and operate a currency—
                    a strong currency with a strong asset reserve. We are excited to share
                    this new form of currency with everyone in the US.
                  </small>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </section>
    </>
  );
};

export default Faq;

// Tx.js

import React, { useState, useEffect } from "react";
import moment from "moment";
import ReactPaginate from "react-paginate";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { UserContext } from "../UserContext";
import { IoCopy } from "react-icons/io5";

function TxsBuySell(props) {
  const { user } = UserContext();

  const [isTarget, setIsTarget] = useState(false);
  const [isPending, setIsPending] = useState(false);
  const [isBuySell, setIsBuySell] = useState(false);

  const [TransactionDetailshow, setShow] = useState(false);

  const TransactionDetailhandleClose = () => setShow(false);
  const TransactiondetailhandleShow = () => setShow(true);

  React.useEffect(() => {
    if (
      props.tx.breed == "buy" ||
      (props.tx.target && props.tx.target.id === props.currentUserId)
    ) {
      setIsTarget(true);
    }
    if (props.tx.status == "pending") {
      setIsPending(true);
    }

    if (props.tx.breed == "buy" || props.tx.breed == "sell") {
      setIsBuySell(true);
    }
  }, []);

  function ExternalSource(props) {
    return (
      <div>
        {props.tx.externalSource.accountInfo} ({props.tx.externalSource.name})
      </div>
    );
  }
  function ExternalTarget(props) {
    return (
      <div>
        {props.tx.externalTarget.accountInfo} ({props.tx.externalTarget.name})
      </div>
    );
  }
  function Target(props) {
    return (
      <div>
        {props.tx.target.first} {props.tx.target.last}
      </div>
    );
  }
  function Source(props) {
    return (
      <div>
        {props.tx.source.first} {props.tx.source.last}
      </div>
    );
  }

  function Counterparty(props) {
    //- IF BUY/SELL
    if (props.tx.externalTarget) {
      return <ExternalTarget tx={props.tx} />;
    } else if (props.tx.externalSource) {
      return <ExternalSource tx={props.tx} />;
      //- IF TARGET
    } else if (props.tx.source && props.tx.source.id === props.currentUserId) {
      return <Target tx={props.tx} />;
      //- IF SOURCE
    } else if (props.tx.target && props.tx.target.id === props.currentUserId) {
      return <Source tx={props.tx} />;
    }
  }

  return (
    <>
      {isBuySell && (
        <>
          <div className="alltransection-table">
            <div className="listrow">
              <div className="list-left">
                <h4>
                  <b>
                    <Counterparty
                      tx={props.tx}
                      currentUserId={props.currentUserId}
                    />
                  </b>

                  {/* <span>
                    <button href="#" onClick={TransactiondetailhandleShow}>
                      More details
                    </button>
                  </span> */}
                </h4>
                <div className="d-flex align-items-center justify-content-start gap-2">
                  <p> {moment(props.tx.createdAt).format("h:mma d MMM YY")}</p>
                  <p>
                    ǂ1 /
                    {`${parseFloat(props.tx.conversionRate.toFixed(2))} USD`}
                  </p>
                </div>
                {props.tx.processedAt && (
                  <div className="text-md text-muted">
                    <span className="text-md">Processed</span>
                  </div>
                )}
                {props.tx.failedAt && <h5 className="nm">Failed</h5>}
                {!props.tx.processedAt && !props.tx.failedAt && (
                  <div className="text-muted text-md">
                    <div className="text-md text-warning">Pending</div>
                  </div>
                )}
              </div>
              <div className="list-right">
                <h5
                  className={`fs-5 ${
                    isTarget ? "text-success" : "text-danger"
                  }`}
                >
                  {isTarget ? "" : "-"} <span className="nmy">ǂ</span>{props.tx.amountInScript}
                </h5>
                <p>
                  {isTarget ? "" : "-"}${props.tx.amountInDollars}
                </p>
              </div>
            </div>
          </div>

          <Modal
            show={TransactionDetailshow}
            onHide={TransactionDetailhandleClose}
            centered
            className="New-modal_ui"
          >
            <Modal.Header closeButton>
              <Modal.Title>Transaction Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="shadowboxes">
                <ul>
                  <li>
                    <h5 className="srno">Recipient</h5>
                    {props.tx.target && (
                      <h5 className="nm">
                        {props.tx.target.first} {props.tx.target.last}
                      </h5>
                    )}
                  </li>
                  <li>
                    <h5 className="srno">Source</h5>
                    <h5 className="nm">
                      <Counterparty
                        tx={props.tx}
                        currentUserId={props.currentUserId}
                      />
                    </h5>
                  </li>
                </ul>
              </div>
              {/* <div className="shadowboxes">
             <ul>
               <li>
                 <h5 className="srno">Transaction ID</h5>
                 <h5 className="nm">
                   31d15d31c1c3d51c
                   <IoCopy />
                 </h5>
               </li>
             </ul>
           </div> */}
              <div className="shadowboxes">
                <ul>
                  <li>
                    <h5 className="srno">Receipent gets</h5>
                    <h5 className="nm">{props.tx.amountInScript}</h5>
                  </li>
                  <li>
                    <h5 className="srno">Amount in USD</h5>
                    <h5 className="nm">{`$ ${props.tx.amountTotalInDollars} USD`}</h5>
                  </li>
                  <li>
                    <h5 className="srno">Conversion Rate</h5>
                    <h5 className="nm">
                      <span className="nmy">ǂ</span>1 ={" "}
                      {`${parseFloat(props.tx.conversionRate.toFixed(2))} USD`}
                    </h5>
                  </li>
                  <li>
                    <h5 className="srno">Fees</h5>
                    <h5 className="nm">{props.tx.fee}</h5>
                  </li>
                  <li>
                    <h5 className="srno">Your total</h5>
                    <h5 className="nm">{props.tx.amountTotal}</h5>
                  </li>
                </ul>
              </div>
              <div className="shadowboxes">
                <ul>
                  <li>
                    <h5 className="srno">Status</h5>

                    {props.tx.processedAt && <h5 className="nm">Processed</h5>}
                    {props.tx.failedAt && (
                      <h5 className="nm">
                        Failed on {moment(props.tx.failedAt).format("d MMM YY")}
                      </h5>
                    )}
                    {!props.tx.processedAt && !props.tx.failedAt && (
                      <h5 className="nm">Pending</h5>
                    )}
                  </li>
                </ul>
              </div>
            </Modal.Body>
          </Modal>
        </>
      )}
    </>
  );
}
export default TxsBuySell;

import React, { useState } from "react";
import { Col, Form, InputGroup, Row, Table } from "react-bootstrap";
import FallingDollarChart from "./FallingDollarChart";
import { AiOutlineArrowUp } from "react-icons/ai";
import { Rating } from "react-simple-star-rating";
import Slider from "react-slick";
import "animate.css";
import { Link } from "react-router-dom";
import { UserContext } from "../UserContext";

const OurMission = () => {
  const {user} = UserContext();
  return (
    <>
      <section className="bg-our">
        <div className="hero container">
          <Row className="align-items-center mx-0 px-5 py-4">
            <Col md={6}>
              <div className="gap-3 d-flex flex-column justify-content-center max-w-hero text-dark">
                <h1>Our Mission: An Economy That Works for Everyone</h1>
                <p>
                  Centralized, inflation-based currencies worked for a long
                  time, but now they are causing more problems than they are
                  worth.
                </p>
                <Link to="/subscribe" className="btn m-auto btn-primary py-3 px-4">
                  Get Our Free E-Book
                </Link>
{/*                { !user && <Link to="/sign-up" className="btn btn-primary text-white w-fit px-4 py-3">
                  Start Your Free Wallet
                </Link>} */}
              </div>
            </Col>
          </Row>
        </div>
      </section>
      {/* -------------------------box-------------------------- */}
      <section className="pdd">
        <div className="square left ">
          <img src="images/square-left.png" alt="square" />
        </div>
        <div className="circle-right bottom-20 ">
          <img src="images/right.png" alt="right-circle" />
        </div>
        <div className="container">
          <h2 className="fs-38 mb-5 fw-bold text-center h-after">
            Imagine a World Where
          </h2>
          <div className="gridbox">
            <div className="text-center d-flex flex-column flex-md-row align-items-start align-items-md-center bg-white shadow px-4 py-3 py-lg-5 rounded-3">
              <img src="images/security.svg" alt="security" />

              <div className="text-start">
                <strong>Your Cash Stays Strong</strong>
                <p>
                  Everyone keeps their cash denominated in US Dollars, and many
                  of use keep an emergency fund as well, but every month
                  inflation eats away at these balances like acid. If you keep
                  your cash and emergency fund in Numey, it will stay liquid and
                  will preserve its value against inflation.
                </p>
              </div>
            </div>

            <div className="text-center d-flex flex-column flex-md-row align-items-start align-items-md-center bg-white shadow px-4 py-3 py-lg-5 rounded-3">
              <img src="images/ourmission-icons/salary.svg" alt="security" />

              <div className="text-start">
                <strong>Your Salary Doesn't Go Down</strong>
                <p>
                  Most people are paid in US Dollars — a currency that loses its
                  value little by little every month and every year. How crazy
                  is that!? If, however, you are paid in Numey, your wage never
                  goes down from inflation. In 2022 inflation was 7.83%, so if
                  your salaray was $100k, you got a roughly $8k pay cut. If your
                  salary was <span className="nmy">ǂ</span>200k (denominated in Numey), you would have
                  receieved no real pay cut and nominally your salary would now
                  be worth roughly $108k.
                </p>
              </div>
            </div>

            <div className="text-center d-flex flex-column flex-md-row align-items-start align-items-md-center bg-white shadow px-4 py-3 py-lg-5 rounded-3">
              <img
                src="images/ourmission-icons/pricesstay.svg"
                alt="security"
              />

              <div className="text-start">
                <strong>Prices Stay Level</strong>
                <p>
                  All businesses have to keep adjusting their prices every year
                  to keep up with inflation. If you are a business and you don't
                  raise your prices, even a few months, you are losing money
                  because of the inflation treadmill. However, if you post your
                  prices in Numey, you will never have to change your prices
                  again from inflation, and your prices will automatically keep
                  up with general price changes. Use the Numey symbol — <span className="nmy">ǂ</span> — to
                  post prices in Numey.
                </p>
              </div>
            </div>

            <div className="text-center d-flex flex-column flex-md-row align-items-start align-items-md-center bg-white shadow px-4 py-3 py-lg-5 rounded-3">
              <img
                src="images/ourmission-icons/capitalism.svg"
                alt="security"
              />

              <div className="text-start">
                <strong>Capitalism Works For Everyone</strong>
                <p>
                  The freedom to build things, trade, cooperate, and compete has
                  lead to amazing improvements in society throughout history.
                  However, our current central-bank-dominated form of capitalism
                  is the cause of many injustices and dangerous inequalities. We
                  built Numey to be a currency that supports a new form of
                  capitalism that works for everyone, not just the high
                  financiers, media elites, and techno-oligarchs.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="container pdd">
        <div className="row mb-5">
          <h2 className="fw-bolder fs-38 h-after mb-4">Why Can I Trust the Numey?</h2>
          <div className="col-lg-10 offset-lg-1 mb-5">
            <small>The precipitous loss of value in BitCoin and Ethereum and other cryptocurrencies along with the revelation of the FTX Ponzi scheme developed by Sam Bankman-Fried have rightly shaken the public's trust. Here are three ways Numey is different from these scams and why you can trust the Numey.</small>
          </div>
          <div className="col-lg-4">
            <h4 className="mb-4">🏛️ A Strong Reserve</h4>
            <small>The Numey carries a strong reserve made up of total market index funds. This reserve is verified by audit and available to all our financial partners. A strong reserve is what makes a strong currency and protects your buying power.</small>
          </div>
          <div className="col-lg-4">
            <h4 className="mb-4">🇺🇸 American Made</h4>
            <small>Unlike dubious cryptos that hide offshore in tax shelters, the Numey is made by an American company (New Reserve LLC) with its headquarters in San Francisco, California. Numey is happy to be regulated by the IRS and comply with federal laws fight meant to fight terrorism, money laundering, and other financial crimes.</small>
          </div>
          <div className="col-lg-4">
            <h4 className="mb-4">🤝 Financial Partners</h4>
            <small>The Numey operates in partnership with legitimate American financial partners, brokers, and banks. We do not work with any offshore or unregulated entities, brokers, or exchanges. Nor do we work with any crypto currency or exchange.</small>
          </div>
          <div className="d-flex justify-content-center mt-5">
            <Link to="/subscribe" className="btn m-auto btn-primary py-3 px-4">
              Get an Invite
            </Link>
            {/*<Link className="btn btn-primary" to="/sign-up">Try Numey Out For Free</Link>*/}
          </div>
        </div>
      </section>
      {/* --------------chart------------------------ */}
{/*      <section className="pdd mt-5">
        <div className="square bottom">
          <img src="images/square-left.png" alt="right-circle" />
        </div>

        <div className="container">
          <div className="text-center">
            <h2 className="fw-bolder fs-38 h-after">
              The Falling Value of the US Dollar
            </h2>
            <div className="mt-5 px-4 py-4 bg-white">
              <FallingDollarChart />
            </div>
            <div className="my-4">
              <small>
                Source:{" "}
                <a
                  href="https://fred.stlouisfed.org/series/CUUR0000SA0R"
                  className="text-primary text-decoration-none"
                >
                  Federal Reserve Economic Data (FRED) - St. Lewis Federal
                  Reserve
                </a>
              </small>
            </div>
            <Link to="/subscribe" className="btn m-auto px-4  btn-primary">
              Get an Invite
            </Link>
          </div>
        </div>
      </section>
     */}
      {/* -------------------------Frictionless and Instantaneous Transaction------------------------ */}
      <section className="pdd">
        <div className="circle-left top-20 ">
          <img src="images/left.png" alt="left-circle" />
        </div>
        <div className="square right bottom">
          <img src="images/square.png" alt="right-circle" />
        </div>
        <div className="container">
          <h2 className="fs-38 mb-5 fw-bold text-center h-after">
            No Fees and Blazing Fast Transaction
          </h2>
          <Row className="mx-0 align-items-center">
            <Col md={8}>
              <div className="text-end ">
                <strong>Lowest Fees In The Industry</strong>
                <p className="max-w me-0 m-auto">
                  Numey's fees are the lowest in the financial world. Compared to traditional ATM and credit card fees, Numey's transaction costs are significantly lower, allowing users to retain more of their hard-earned money. Additionally, when compared to the often unpredictable and high fees associated with cryptocurrencies, Numey stands out as a cost-effective solution for everyday transactions, making it a preferred choice.
                </p>
              </div>
            </Col>
            <Col md={4}>
              <img src="images/pana.png" alt="illustration" />
            </Col>
          </Row>
          <Row className="mx-0 flex-column-reverse flex-md-row align-items-center">
            <Col md={4}>
              <img src="images/pana2.png" alt="illustration" />
            </Col>
            <Col md={8}>
              <div className="text-start mt-5 mt-md-0">
                <strong>Blazing Fast Transactions</strong>
                <p className="max-w">
                  Numey's transaction speed is a game-changer. While a check or bank-to-bank transfers can take up to three days to complete, and cryptocurrencies often involve lengthy settlement times, Numey offers instantaneous transactions. Whether you're paying bills, sending money, or making purchases, Numey ensures that your funds are readily available, making it the go-to choice for those who value efficiency and convenience in their financial transactions.
                </p>
              </div>
            </Col>
          </Row>
        </div>
      </section>
      {/* -----------The First Hayekian Currency--------------------------------- */}
      <section className="pdd">
        <div className="circle-left bottom-20 ">
          <img src="images/left.png" alt="left-circle" />
        </div>
        <div className="square right bottom">
          <img src="images/square.png" alt="right-circle" />
        </div>
        <div className="container">
          <div className="shadow Hayekian bg-white py-4 ">
            <h2 className="fs-38 mb-5 fw-bold text-start ms-0 h-after">
              The First Hayekian Currency
            </h2>
            <strong>Fredrick August von Hayek (1899-1992)</strong>
            <p>
              FA Hayek was an Austrian-British economist and social philosopher
              is considered one of the most influential thinkers of the 20th
              century. He was friends and intellectual sparring partners with
              John Maynard Keynes as well as Milton Friedman. In 1974, he was
              awarded the Nobel Memorial Prize in Economic Sciences for his
              pioneering work in the theory of money and economic fluctuations.
            </p>
            <p>
              Hayek published{" "}
              <b>
                The Denationalization of Money: An Analysis of the Theory and
                Practice of Concurrent Currencies
              </b>{" "}
              in 1976. Hayek proposed a new system in which firms would issue
              stable currencies. This system would promote price stability and
              reduce inflation, thereby preserving wages and rewards saving.
            </p>
            <p>
              The Numey is the world's first ever currency built following
              Hayek's designs laid out in Denationalization. Hayek did not have
              the internet or modern financial systems, so there have been some
              improvements, but the overal structure of an asset-based,
              inflation-linked currency is the same.
            </p>
            <Link to="/subscribe" className="btn m-auto btn-primary py-3 px-4">
              Get Hayek's Book - Free
            </Link>
{/*            {!user && <Link to="/sign-up" className="btn btn-primary px-4 mt-5">
              Start Your Free Wallet
            </Link> */}
            <figure>
              <img src="images/oldman.png" alt="oldman" />
            </figure>
          </div>
        </div>
      </section>
    </>
  );
};

export default OurMission;

import React, { useState } from "react";
import Cookies from "universal-cookie";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import AuthService from "../AuthService";
import { toast } from "react-toastify";

import { UserContext } from "../UserContext";

function BuyNumey(props) {
  const { user, setUser } = UserContext();
  const { getCurrentUser } = AuthService();

  const [amount, setAmount] = React.useState("0.00");
  const [amountInDollars, setAmountInDollars] = React.useState("");
  const [isOpen, setIsOpen] = React.useState(false);
  const [selectedItem, setSelectedItem] = React.useState("");

  const [conversionRateToDollars, setConversionRateToDollars] = React.useState("");
  const [prettyConversionRateToDollars, setPrettyConversionRateToDollars] = React.useState("");

  const [loading, setLoading] = React.useState(false);

  // const [externalSourceId, setExternalSourceId] = React.useState("Coinbase");
  const [externalSourceId, setExternalSourceId] = React.useState("");


  const navigate = useNavigate();

  React.useEffect(() => {
    // GET CONVERSION RATE
    const url = process.env.REACT_APP_BASE_DOMAIN + "/api/exchange-rates/current";

    axios.get(url)
      .then(response => {
        setConversionRateToDollars(response.data.conversionRateToDollars);
        setPrettyConversionRateToDollars(response.data.prettyConversionRateToDollars);
      })
      .catch(err => {
        toast.warning(err.response.data.message)
      });
  }, [])

  // function toFixedWithZeros(x, d) {
  //     if (!d) return x.toFixed(d); // don't go wrong if no decimal
  //     return x.toFixed(d).replace(/\.?0+$/, '');
  // }

  const handleAmountChange = (e) => {
    const value = e.target.value.slice(0, -1);                // input box value before keyup
    const input = e.target.value[e.target.value.length - 1];  // input char
    const intValue = parseInt(value.replace(/\D/g,''));       // replace any non numeric characters
    const finalVal = intValue + input;                        // add like string and format it
    var formattedVal = (finalVal/100).toFixed(2);             // 12.34
    if (finalVal == "00" || isNaN(intValue)) {                // if 00 or NaN reset
      formattedVal = "0.00"
    }

    const calcAmount = parseFloat(formattedVal || 0);
    const amountInDollars = (calcAmount * conversionRateToDollars).toFixed(2)

    setAmount(formattedVal)
    setAmountInDollars(amountInDollars);
  }

  const handleSubmit = (e) => {
    try {
      e.preventDefault();
      if(!externalSourceId) { 
        toast.warning("Please choose the funding source") 
        setLoading(false);
        return
      };
      if(amount <= 0) { 
        toast.warning("Amount must be greater then ǂ0.00") 
        setLoading(false);
        return
      }
  
      const body = {
        amount: amount,
        externalSourceId: externalSourceId
      }
  
      const cookies = new Cookies();
      const authToken = cookies.get('nJWT');
      const url = process.env.REACT_APP_BASE_DOMAIN + "/api/txs/buy";
      const config = { headers: { Authorization: `Bearer ${authToken}` } };
  
      axios.post(url, body, config)
        .then(response => {
          getCurrentUser().then((user) => {
            setUser(user)
            toast.success('Transfer initiated. We will notify you when your funds are available (usually 3-5 days).')
            navigate('/wallet')
            console.log(response);
            // window.location.replace(response.data.hosted_url);
          });
        })
        .catch(err => {
          toast.warning(err.response.data.message)
          setLoading(false)
        });
      
    } catch (error) {
      console.log("Error while submitting", error)
    }
  }

  const toggleDropdown = () => { setIsOpen(!isOpen) };

  const handleItemClick = (item) => {
    setSelectedItem(item);
    setIsOpen(false); // Close the dropdown after selecting an item
  };
  const handleExternalChange = (e) => {
    setExternalSourceId(e.target.value);
    // getAvailableBalance(e.target.value);
  }


  return (
    <>
      {user && (
        <>
          <div className="breadcrumbar">
            <h4>Buy Numey</h4>
          </div>
          <div className="wallet-bar row">
            <div className="col-lg-8 offset-lg-2">
              <form onSubmit={handleSubmit}>
                <div className="text-center main_balance my-5">
                  <span className="nmy">ǂ</span>
                  <input
                    name="amount"
                    value={amount}
                    onChange={handleAmountChange}
                    type="number"
                    placeholder="0.00"
                    step="0.01" min="1" max="10000"
                    required="required"
                    className="form-control"
                    autoFocus
                  />
                  <div className="mt-3">
                    <small className="text-muted">{`($ ${parseFloat((amount*conversionRateToDollars).toFixed(2))})`}</small>
                  </div>
                </div>

                <label className="form-label">Funding Source</label>
                <div className={`select-buy-numey-dropdown ${isOpen ? "open" : ""}`}>
                  <select
                    className="form-select"
                    id="external"
                    name="externalSourceId"
                    defaultValue={user.externals[0].id}
                    required="required"
                    value={externalSourceId || setExternalSourceId(user.externals[0].id)}
                    onChange={handleExternalChange}
                  >
                    {/* <option value="Coinbase">Coinbase</option> */}

                    {user.externals.filter(e => { return !!e.verifiedAt }).map((e) => {
                      return <option selected={user.externals[0].id == e.id ? true : false} key={e.id} value={e.id}>{e.accountInfo} ({e.name})</option>
                    })}
                  </select>
                </div>

                <div className="my-2">
                  <p className="form-text">
                    Your funds will be available once the transaction processes,
                    which may take upto 3-5 days.
                  </p>
                </div>
                <div>
                  <button
                    type="submit"
                    className="btn btn-primary w-100" 
                    disabled={loading}
                  >
                    Confirm and Buy
                  </button>
                </div>
              </form>
            </div>
          </div>
        </>
      )}
    </>
  );
}
export default BuyNumey;

import React, { useContext, useState, useEffect } from "react";
import Cookies from "universal-cookie";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import AuthService from "../../AuthService";
import { toast } from "react-toastify";
import moment from "moment";
import Modal from "react-bootstrap/Modal";

// import signOut
import InputMask from "react-input-mask";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

import { UserContext } from "../../UserContext";

function EditProfile(props) {
  const { user, setUser } = UserContext();
  const { getCurrentUser } = AuthService();

  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");

  const [first, setFirst] = useState("");
  const [middle, setMiddle] = useState("");
  const [last, setLast] = useState("");
  const [suffix, setSuffix] = useState("");
  const [username, setUsername] = useState("");

  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [postalCode, setPostalCode] = useState("");

  const [dob, setDob] = React.useState("");
  const [ssn, setSsn] = useState("");
  const [show, setShow] = useState(false);

  const DeleteAccountModalClose = () => setShow(false);
  const DeleteAccountModalShow = () => setShow(true);

  const { signOut } = AuthService();
  const navigate = useNavigate();
  // const setClose = props.close
  useEffect(() => {
    getCurrentUser().then((user) => {
      setEmail(user.email);
      setFirst(user.first);
      setMiddle(user.middle);
      setLast(user.last);
      setSuffix(user.suffix);
      setUsername(user.username);
      setPhone(user.phone);
      setAddress(user.address);
      setCity(user.city);
      setState(user.state);
      setPostalCode(user.postalCode);
      setSsn(user.ssnComplete);
      setDob(moment(user.dob).format("yyyy-MM-DD"));
    });
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    const body = {
      email,
      first,
      middle,
      last,
      suffix,
      username,
      phone,
      address,
      city,
      state,
      ssn,
      dob,
      postalCode,
    };

    const cookies = new Cookies();
    const authToken = cookies.get("nJWT");
    const url = process.env.REACT_APP_BASE_DOMAIN + "/api/profile";
    const config = { headers: { Authorization: `Bearer ${authToken}` } };

    axios
      .put(url, body, config)
      .then((response) => {
        if (response.data.phone != user.phone) {
          setUser(null);
          const cookies = new Cookies();
          cookies.remove("nJWT");
          navigate("/sign-in");
          toast.success("Profile updated successfully.");
          toast.success("Please sign in again to reconfirm your phone number");
        } else if (
          response.data.first != user.first ||
          response.data.middle != user.middle ||
          response.data.last != user.last
        ) {
          setUser(response.data);
          navigate("/verify-identity");
          toast.success("Profile updated successfully.");
          toast.success("Please verify your identity again");
        } else if (response.data.email != user.email) {
          setUser(response.data);
          toast.success("Profile updated successfully.");
          toast.success("Please check your inbox for your email confirmation.");
          navigate("/me");
        } else {
          setUser(response.data);
          toast.success("Profile... updated successfully.");
          navigate("/me");
        }
      })
      .catch((err) => {
        console.log(err);
        // toast.warning(err.response.data.message);
      });
  };

  const deactivateWallet = () => {
    const cookies = new Cookies();
    const authToken = cookies.get("nJWT");
    const config = { headers: { Authorization: `Bearer ${authToken}` } };

    const url = process.env.REACT_APP_BASE_DOMAIN + "/api/users/archive";
    axios
      .put(url, user, config)
      .then((response) => {
        signOut().then(() => {
          setUser(null);
          toast.success(
            "Your wallet has been successfully deactivated. You may sign in again to reactivate. Hope to see you again soon."
          );
          navigate("/");
        });
      })
      .catch((err) => {
        toast.warning(err.response.data.message);
      });
  };

  return (
    <>
      <div className="breadcrumbar">
        <h4>Edit Your Profile</h4>
      </div>
      <div className="wallet-bar">
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-md-6">
              <div className="mb-2">
                <label className="form-label">First Name</label>
                <input
                  className="form-control"
                  id="first"
                  name="first"
                  placeholder="Enter Your First Name"
                  required="required"
                  value={first}
                  onChange={(e) => setFirst(e.target.value)}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-2">
                <label className="form-label">Last Name</label>
                <input
                  className="form-control"
                  id="last"
                  name="last"
                  placeholder="Enter Your Last Name"
                  value={last}
                  onChange={(e) => setLast(e.target.value)}
                  required="required"
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-2">
                <label className="form-label">Middle Name (Optional)</label>
                <input
                  className="form-control"
                  id="middle"
                  name="middle"
                  placeholder="Enter Your Middle Name"
                  value={middle}
                  onChange={(e) => setMiddle(e.target.value)}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-2">
                <label className="form-label">Suffix (Optional)</label>
                <input
                  className="form-control"
                  id="suffix"
                  name="suffix"
                  placeholder="Enter Your Suffix"
                  value={suffix}
                  onChange={(e) => setSuffix(e.target.value)}
                />
              </div>
            </div>
            <div className="col-md-12">
              <div className="mb-2">
                <label className="form-label">Email</label>
                <input
                  className="form-control"
                  id="Email"
                  name="Email"
                  type="email"
                  placeholder="Enter Your Email"
                  required="required"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
            </div>
            <div className="col-md-12">
              <div className="mb-3">
                <label className="form-label">Phone Number</label>
                <input
                  className="form-control"
                  id="phone"
                  name="phone"
                  type="tel"
                  placeholder="+1(555) 333-4444"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  mask="+1\(999) 999-9999"
                  maskplaceholder={""}
                  maskchar=" "
                  required="required"
                />
              </div>
            </div>
            <div className="col-md-12">
              <div className="mb-3">
                <label className="form-label">Address</label>
                <input
                  className="form-control"
                  id="address"
                  name="address"
                  placeholder="Enter Your Address"
                  required="required"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3">
                <label className="form-label">City</label>
                <input
                  className="form-control"
                  id="city"
                  name="city"
                  placeholder="Enter Your City "
                  required="required"
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                />
              </div>
            </div>
            <div className="col-md-3">
              <div className="mb-3">
                <label className="form-label">State</label>
                <select
                  className="form-select"
                  id="state"
                  name="state"
                  required="required"
                  value={state}
                  onChange={(e) => setState(e.target.value)}
                >
                  <option value="AL">AL</option>
                  <option value="AK">AK</option>
                  <option value="AZ">AZ</option>
                  <option value="AR">AR</option>
                  <option value="CA">CA</option>
                  <option value="CO">CO</option>
                  <option value="CT">CT</option>
                  <option value="DE">DE</option>
                  <option value="DC">DC</option>
                  <option value="FL">FL</option>
                  <option value="GA">GA</option>
                  <option value="HI">HI</option>
                  <option value="ID">ID</option>
                  <option value="IL">IL</option>
                  <option value="IN">IN</option>
                  <option value="IA">IA</option>
                  <option value="KS">KS</option>
                  <option value="KY">KY</option>
                  <option value="LA">LA</option>
                  <option value="ME">ME</option>
                  <option value="MD">MD</option>
                  <option value="MA">MA</option>
                  <option value="MI">MI</option>
                  <option value="MN">MN</option>
                  <option value="MS">MS</option>
                  <option value="MO">MO</option>
                  <option value="MT">MT</option>
                  <option value="NE">NE</option>
                  <option value="NV">NV</option>
                  <option value="NH">NH</option>
                  <option value="NJ">NJ</option>
                  <option value="NM">NM</option>
                  <option value="NY">NY</option>
                  <option value="NC">NC</option>
                  <option value="ND">ND</option>
                  <option value="OH">OH</option>
                  <option value="OK">OK</option>
                  <option value="OR">OR</option>
                  <option value="PA">PA</option>
                  <option value="RI">RI</option>
                  <option value="SC">SC</option>
                  <option value="SD">SD</option>
                  <option value="TN">TN</option>
                  <option value="TX">TX</option>
                  <option value="UT">UT</option>
                  <option value="VT">VT</option>
                  <option value="VA">VA</option>
                  <option value="WA">WA</option>
                  <option value="WV">WV</option>
                  <option value="WI">WI</option>
                  <option value="WY">WY</option>
                </select>
              </div>
            </div>
            <div className="col-md-3">
              <div className="mb-2">
                <label className="form-label">Postal Code</label>
                <input
                  className="form-control"
                  id="first"
                  name="first"
                  placeholder="Enter Your Postal Code"
                  required="required"
                  value={postalCode}
                  onChange={(e) => setPostalCode(e.target.value)}
                />
              </div>
            </div>
            <div className="col-md-12 mt-3">
              <button className="btn btn-primary w-100" type="submit">
                Save Changes
              </button>
            </div>
          </div>
        </form>

        <div className="card border border-danger mt-5">
          <div className="card-body">
            <div className="card-title">
              <legend className="text-danger">Danger Zone</legend>
              <p>
                To deactivate your wallet you must first have a zero balance.
                A deactivated wallet cannot buy, sell, receive, or send Numey.
                The past transactions of a deactivated wallet are still
                visible. You will be able to reactivate your wallet by logging
                in again.
              </p>
              <div
                className="btn btn-danger"
                onClick={DeleteAccountModalShow}
              >
                Deactive Wallet
              </div>
            </div>
          </div>
        </div>

        <Modal
          show={show}
          onHide={DeleteAccountModalClose}
          centered
          className="New-modal_ui"
        >
          <Modal.Header closeButton>
            <Modal.Title>Deactivate Wallet</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="shadowboxes d-flex justify-content-center ">
              <button
                className="btn btn-primary w-300 px-5"
                onClick={() => {
                  deactivateWallet();
                  DeleteAccountModalClose();
                }}
              >
                Confirm
              </button>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    )}
  </>
  );
}
export default EditProfile;

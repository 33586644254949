import React, { useEffect, useState, useContext } from 'react';
import { Link, useNavigate } from "react-router-dom";
import Cookies from 'universal-cookie';
import axios from 'axios';
import AuthService from '../../AuthService';
import { UserContext } from '../../UserContext';
import { toast } from 'react-toastify';
import { Link2 } from 'react-feather';
import { SiSourcegraph } from "react-icons/si";
import { usePlaidLink } from 'react-plaid-link';
import Modal from "react-bootstrap/Modal";
import NewExternal from '../../Externals/NewExternal';

function FundingSource() {
  const { user, setUser } = UserContext();
  const { getCurrentUser } = AuthService();
  const [name, setName] = React.useState("");
  const [linkToken, setLinkToken] = useState(null);
  const [loading, setLoading] = React.useState(false);
  const [plaidLinkVisible, setPlaidLinkVisible] = React.useState(false);
  const [showAddFund, setShowAddFund] = useState(false);

  const handleCloseAddFund = () => setShowAddFund(false);
  const handleShowAddFund = () => setShowAddFund(true);

  const navigate = useNavigate();

  const config = {
    token: linkToken,
    onSuccess: ((token, metadata) => {
      // send token to server to exchange for access_token
      setLoading(true)
      const cookies = new Cookies();
      const authToken = cookies.get('nJWT');
      const url = process.env.REACT_APP_BASE_DOMAIN + '/api/externals';
      const config = { headers: { Authorization: `Bearer ${authToken}`, 'Content-Type': 'application/json', } };
      const body = { public_token: token, name: document.getElementById('name').value, metadata: metadata }

      axios.post(url, body, config)
        .then(response => {
	        getCurrentUser().then((user) => {
	          setUser(user)
	          toast.success("New funding source added successfully!")
	          navigate('/wallet')
	        });
	      })
        .catch(err => {
          toast.warning("There was a problem connecting with our bank integration partner.")
          setLoading(false)
        });
    }),
  };

  const { open, ready } = usePlaidLink(config);

  const generatePlaidToken = async () => {
    const cookies = new Cookies();
    const authToken = cookies.get('nJWT');
    const url = process.env.REACT_APP_BASE_DOMAIN + '/api/plaid/create-link-token';
    const config = { headers: { Authorization: `Bearer ${authToken}` } };

    axios.post(url, {}, config)
      .then(response => {
        setLinkToken(response.data.linkToken);
      })
      .catch(err => {
        toast.warning("There was a problem connecting with our bank integration partner.")
      });
  };

  React.useEffect(() => {
    generatePlaidToken();
  }, []);

  const handleNameChange = (e) => {
  	setName(e.target.value)
  	if (name.length > 0) {
  		setPlaidLinkVisible(true);
  	} else {
  		setPlaidLinkVisible(false);
  	}
  }

  return (
    <>
      <div className="usefull-boxes my-5">
        <div className="usefull-head">
          <SiSourcegraph />
          <div className="detail">
            <h4>Funding Sources</h4>
          </div>
          <ul className="list-group my-2">
            {user.externals && user.externals.map(external =>
              <li className="list-group-item py-3">{external.accountInfo} - {external.accountNumberLastFour}</li>
            )}
          </ul>
        </div>
        <Link to="/add-funding-source" className="btn btn-primary w-100" >Add New Funding Source</Link>
      </div>
    </>
  );
}
export default FundingSource;

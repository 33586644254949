
import React, { useEffect, useState, useContext } from 'react';
import { Link, useNavigate, useParams } from "react-router-dom";
import Cookies from 'universal-cookie';
import axios from 'axios';
import AuthService from '../AuthService'
import { UserContext } from "../UserContext";
import { toast } from 'react-toastify';
import { Link2 } from 'react-feather';

import { usePlaidLink } from 'react-plaid-link';

function VerifyExternal() {
  const { user, setUser } = UserContext();
  const { getCurrentUser } = AuthService();

  const { externalId } = useParams()

  const [linkToken, setLinkToken] = useState(null);
  const [loading, setLoading] = React.useState(false);

  const navigate = useNavigate();

  const config = {
    token: linkToken,
    onSuccess: ((token, metadata) => {
      // send token to server to exchange for access_token
      setLoading(true)
      const cookies = new Cookies();
      const authToken = cookies.get('nJWT');
      const url = process.env.REACT_APP_BASE_DOMAIN + `/api/externals/${externalId}/verify`;
      const config = { headers: { Authorization: `Bearer ${authToken}`, 'Content-Type': 'application/json', } };
      const body = { public_token: token, metadata: metadata }

      axios.put(url, body, config)
        .then(response => {
	        getCurrentUser().then((user) => {
	          setUser(user)
	          toast.success("Funding source verified!")
	          navigate('/wallet')
	        });
	      })
        .catch(err => {
          toast.warning("There was a problem connecting with our bank integration partner.")
          setLoading(false)
        });
    }),
    onExit: ((err, metadata) => {
      console.log('error: ', err)
      if (err.error_message = "this account is not eligible for verification") {
        // DESTROY EXTERNAL
        axios.delete(`/api/externals/${externalId}`)
          .then(response => {
            getCurrentUser().then((user) => {
              setUser(user)
              toast.success("Funding source removed. Please try again.")
              navigate('/wallet')
            });
          })
      }
      // console.log('metadata: ', metadata)
    })
  };

  const { open, ready } = usePlaidLink(config);

  const generatePlaidToken = async () => {
    const cookies = new Cookies();
    const authToken = cookies.get('nJWT');
    const url = process.env.REACT_APP_BASE_DOMAIN + `/api/externals/${externalId}/create-link-token`;
    const config = { headers: { Authorization: `Bearer ${authToken}` } };

    axios.post(url, {}, config)
      .then(response => {
        setLinkToken(response.data.linkToken);
      })
      .catch(err => {
        toast.warning("There was a problem connecting with our bank integration partner.")
        navigate('/wallet')
      });
  };

  React.useEffect(() => {
    generatePlaidToken();
  }, []);

  return (
    <div className="row">
      {user &&
      <div className="col-md-6 offset-md-3 col-xl-4 offset-xl-4">
        <div className="bg-white p-4 m-4 border rounded-3">
          <h4 className="text-center mb-4">Verify Funding Source</h4>

          <div className='mt-4'>
            <div className="mt-4 d-grid gap-2 my-4 mx-2">
              {!loading && !ready &&
                <button className="btn btn-primary btn-block" disabled='true' disabled={!ready}>
                  <Link2 className="pb-1" /> Verify Funding Source
                </button>
              }
              {!loading && ready &&
                <button className="btn btn-primary btn-block" disabled='true' onClick={() => open()} disabled={!ready}>
                  <Link2 className="pb-1" /> Verify Funding Source
                </button>
              }
              {loading &&
                <button className="btn btn-primary btn-block" type="button" disabled>
                  <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                  Loading...
                </button>
              }
            </div>
            <div className="text-start">
              <Link className="me-3 link-secondary text-decoration-none" to="/profile">Cancel</Link>
            </div>
          </div>
        </div>
      </div>
    }
    </div>
  )
}
export default VerifyExternal;

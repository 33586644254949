
import React from 'react';

function AboutMoney() {
  return (
    <div className="container">
      <div className="mt-5 mb-2">
        <h2 className="quote text-center">About Money</h2>
      </div>
      <div className="row">
        <div className="col-md-4">
          <nav className="navbar navbar-light bg-light sticky-top bg-white p-4 border rounded mt-5 sticky-offset" id="the-economics-navbar">
            <nav className="nav nav-pills flex-column">
              <a className="nav-link link-dark fw-bold" href="#what-is-money">What is Money?</a>
              <a className="nav-link link-dark fw-bold" href="#good-vs-bad">What is Good Money?</a>
              <a className="nav-link link-dark fw-bold" href="#money-value">Where Does Money Get Its Value?</a>
              <a className="nav-link link-dark fw-bold" href="#history">A Brief History of Money</a>
              <a className="nav-link link-dark fw-bold" href="#stability">What are Monetary Inflation, Deflation, and Stability?</a>
              <a className="nav-link link-dark fw-bold" href="#cryptos">Are Cryptocurrencies the Future of Money?</a>
              <a className="nav-link link-dark fw-bold" href="#why-not-cryptos">The Problems with Cryptocurrencies</a>
              <a className="nav-link link-dark fw-bold" href="#stablecoins">The Problems with Stablecoins</a>
              <a className="nav-link link-dark fw-bold" href="#the-numey">What is the Numey?</a>
            </nav>
          </nav>
        </div>
        <div className="col-md-8">
          <div data-bs-spy="scroll" data-bs-target="#the-economics-navbar" data-bs-offset="0" tabIndex="0">
            <div className="bg-white p-5 border rounded mt-5 mb-5" id="what-is-money">
              <h4>What is Money? 💰❓</h4>
              <p>Anything can be money — glass beads, an IOU scribbled on a slip of paper, a sack of potatoes or apples, a bank note, or one's and zero's in a database — what makes something money is if it can perform the <b>Four Functions of Money</b>. To find out of something can be money, just ask yourself if it can be a ...</p>
              <ol>
                <li><b>Medium of Exchange</b>: Money can be used reliably to exchange for other goods.</li>
                <li><b>Unit of Account</b>: People and businesses can use money to keep track of their expenses, incomes, and personal savings or company profit.</li>
                <li><b>Store of Value</b>: Money preserves its value over time. You could bury money in the back yard and a few years later dig it up and buy roughly the same number of goods as when you buried it.</li>
                <li><b>Means of Deferred Payment</b>: Money can be loaned to someone else and easily be paid back in installments and at a predictable rate of interest.</li>
              </ol>
              <p>Lots of goods can perform these four functions poorly, but only a few goods have been discovered that do these four functions well. Any good that does these functions the best becomes "money" for that time in history. Throughout the history of money, money has gotten better and better at doing these four jobs for people. </p>
              <p>(Learn more about the functions of money: <a className="form-text" href="https://www.stlouisfed.org/education/economic-lowdown-podcast-series/episode-9-functions-of-money" target="_blank" rel="noreferrer">Federal Reserve Bank of St. Louis</a>)</p>
            </div>
            <div className="ratio ratio-16x9 mb-5">
              <iframe src="https://www.youtube.com/embed/yJfgWh1U-5w?si=iflkKvtu0Fc8sidS" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
            <div className="bg-white p-5 border rounded mb-5" id="good-vs-bad">
              <h4>What is Good Money? 🐮 Cows vs 💵 Paper Bills </h4>
              <p>Let's compare two kinds of money. Let's compare using cows as money and using paper bills. Which is better?</p>
              <p>We can use the four functions of money to compare moneys and determine if one money is better than another. When comparing two moneys, the one that can do the four functions of money more cheaply, reliably, and efficiently is the better money. </p>
              <p>For thousands of years (and still today in some communities) cows are a standard form of money. Because of their size and indivisibility, they are commonly used for large transactions such as buying property, dowries, or large payments between estates. </p>
              <p>Cows were a popular form of money for millennia because they are actually pretty good at doing the four functions of money:</p>
              <ol>
                  <li>You can trade them (Medium of Exchange)</li>
                  <li>You can count them (Unit of Account)</li>
                  <li>Cows live a long time and reproduce (Store of Value)</li>
                  <li>You can even borrow and loan cows (Means of Deferred Payment) </li>
              </ol>
              <p>But cows also have their drawbacks. You have to feed and maintain them. They can get sick and die. They come in a variety of nonstandard sizes and qualities. And, of course, they are huge, so you can't use them for small transactions, only big ones, and even then you have to round up or down by one whole cow's value.</p>
              <p>So what about <b>paper bills</b> or <b>bank notes</b> by comparison? </p>
              <ol>
                  <li>Paper bills are lighter weight to carry than the cows (Medium of Exchange)</li>
                  <li>Bills are perfectly divisible down to the cent (Unit of Account)</li>
                  <li>Although the value of a bill might fluctuate (while a cow is always worth one cow), paper bills cannot grow old, starve, or become lame like a cow (Store of Value)</li>
                  <li>Paper bills can very easily be lent out at interest (Means of Deferred Payment). </li>
              </ol>
              <p>Since paper money can do almost all the jobs of money better than cows, we can say paper money is a better or superior money to cows. And because it is better, in general, forms of paper money replace cow-money whenever there are the resources to develop and implement a paper money system.</p>
            </div>
            <div className="bg-white p-5 border rounded mb-5" id="money-value">
              <h4>Where Does Money Get Its Value?</h4>
              <p>When money is a cow or an ounce of gold, it is pretty clear that its value is exactly the value that a cow or an ounce of gold fetches in the market place. But what about when money is paper bills backed by gold you can't see, or fiat moneys that are really only backed by the faith that people will keep believing in it and accepting it? </p>
              <p>Money gets its value like anything else: from supply and demand. There is a supply of money, currently created and managed by national central banks, and a demand for money, by everyone in the economy.</p>

              <p>Consider a famous thought experiment. Imagine in small village with only 100 people. Now imagine that one morning everyone wakes up to find a pound of new gold coins in their stockings. The supply of money in the village has magically increased for everyone. While this might be exciting at first, no one would really be any better off since all the prices for bread, meat, and clothing would rise that very same day to take into account the increased supply of money. If, on the other hand, one man woke up with a hundred pounds of gold in his stockings, he could go about town and purchase goods and services all he wanted. It would take many months before everyone realized that there was more money to go around and prices would rise, but not before man made himself extremely wealthy.</p>
              <p>In both of these cases, the village experienced <b>inflation</b> — or a decrease in the value of money because of an increase in it's supply.</p>
            </div>
            <div className="bg-white p-5 border rounded mb-5" id="history">
              <h4>A Brief History of Money</h4>
              <p>Throughout human history, people have discovered, invented, and built better and better moneys. While it is impossible to break up the global history of money into neat little boxes, since there are salient outliers to any unified history of money; nevertheless, it is helpful to think of the history of modern money as roughly following these phases of development:</p>
              <b>Pre-Historical Moneys (Before 10000 BCE)</b>
              <p>In pre-historical times, money was made up of goods that could be found or grown, such as shells, cows, yams, beads, glass, animals, and precious gems and metals.</p>
              <b>Ancient & Medieval Moneys (Before 1500 CE)</b>
              <p>In ancient and medieval times, money was primarily gold and silver ingots and coins. These coins were minted either privately by gold and silver smiths or by a government or royalty. </p>
              <b>Pre-Modern Moneys (1500-1800 CE)</b>
              <p>In what we could call pre-modern times, money was still government minted gold and silver ingots and coins, but with a crucial addition. Most money in circulation was not precious metals themselves, but paper notes exchangeable for a set amount of gold and silver called. The amount of gold or silver a bill was worth was called its "face value." These bills were issued by banks, wealthy estates, or governments that claimed to have adequate reserves of gold and silver to redeem any notes presented to them.</p>
              <b>Modern Moneys (1800-1935 CE)</b>
              <p>As the nation-state grew in prominence and importance, national governments more and more took over the management and issuance of notes backed by gold or silver. During this time period, national governments nationalized and monopolized the issue of money entirely. This system was known as "the gold standard." The gold standard ended in the United States informally after the Great Depression in 1935 after economists concluded that pegging money to the price arbitrarily to a quantity of yellow metal was in large part to blame for the Great Depression itself.</p>
              <b>Contemporary Moneys (1935-1970)</b>
              <p>In contemporary times, money is fiat bank notes issued by national central banks such as the Federal Reserve Bank in the US, the European Central Bank in Europe, The People's Bank of China in China, etc. The word "fiat" in the context of money means "bills not backed by any asset." Money existed in these times in the form of paper cash bank notes as well as balances represented on the ledgers of banks. </p>
              <b>Electronic Moneys (1970-present)</b>
              <p>When computers were invented, those paper bank ledgers moved into electronic databases. Today only 8% of money exists as paper bills, 92% exist as electronic money in various electronic wallets and bank accounts. One type of wholly electronic moneys is cryptocurrencies invented in 2009. Some countries such as Sweden and China are even removing cash from their economy and moving to entirely electronic forms of money.</p>
              <b>The Future of Money</b>
              <p>As we can see from these rough phases, in each phase what was used as money was better at fulfilling the four functions of money than the system before. Numey, and other inflation-linked electronic currencies, can fulfill the four functions of money better than national fiat currencies and even better that cryptocurrencies. Will we see them rise in popularity?</p>
            </div>
            <div className="bg-white p-5 border rounded mb-5" id="stability">
              <h4>What are Monetary Inflation, Deflation, and Stability?</h4>
              <p>Inflation and deflation are relatively loaded terms since they are used in various ways in different contexts. Here we will define these terms deliberately in the monetary sense:</p>
              <p><b>Inflation</b>: When the supply of a money increases relative to the total number of goods and services, causing the money's value to go down, and prices of all goods to go up.</p>
              <p><b>Deflation</b>: When the supply of a money shrinks relative to the total number of goods and services, causing the money's value to go up, and prices of all goods to go down.</p>
              <p><b>Stability</b>: When the supply of a money stays constant relative to the total number of goods and services. A stable money's value does not go up or down, and prices only go up or down relative to other non-monetary causes and circumstances.</p>
              <p>By defining these words in the monetary sense, we can make various observations about how the supply of money effects the average prices of goods and services in the economy. </p>
              <p>For instance, if there is a war or natural disaster, the prices of all goods will likely go up because they are harder to come by. While this may seem like "inflation" (because prices are generally rising), this is absolutely not monetary inflation. </p>
              <p>Or imagine if over decades, technology made many goods much cheaper and prices on average fell because the goods were more plentiful. Although prices are generally falling, this is not monetary deflation.</p>
              <p>A simplistic answer to the problem of inflation and deflation is to simply "keep the quantity of money constant." This is the facile refrain of the BitCoin supporters who believe that since there is a finite and someday a nearly constant supply of BitCoin its price will become more stable over time. This could not be further from the truth.</p>
              <p>If the supply of money stays absolutely constant, there can still be inflation (or more likely deflation) since the total number of goods and services will continue to grow or shrink causing monetary instability and unpredictability. </p>
              <p>The Numey is designed and built to be a stable money in that its value stays in lock step with the real prices of goods and services.</p>
            </div>
            <div className="bg-white p-5 border rounded mb-5" id="cryptos">
              <h4>Are Cryptocurrencies the Future of Money?</h4>
              <p>Cryptocurrencies are absolutely ingenious mathematical and computational systems. Cryptocurrencies work by establishing a public ledger where everyone in the world can see who paid what to whom. These ledgers are resilient to hacking, in theory, since they are constructed of mathematically verifiable encrypted messages.</p>
              <p>The first and most popular cryptocurrency, BitCoin, was deliberately structured to only ever produce a finite number of BitCoin. The fact that you can make countless clones of BitCoin exactly the same as the original BitCoin does not seem to detract from people's exuberance for BitCoins' value.</p>
              <p>Some people believe that cryptocurrencies like BitCoin or Ethereum will be the future of money, but as we'll see these moneys are worse at the four functions of money even than fiat currencies, and are rarely used as moneys but instead are used almost exclusively as collector's items like baseball cards, vintage cars, or fine art.</p>
            </div>
            <div className="bg-white p-5 border rounded mb-5" id="why-not-cryptos">
              <h4>Cryptocurrencies and The Four Functions of Money</h4>
              <p>Some people believe that cryptocurrencies like BitCoin or Ethereum are better than current national moneys and will someday play a significant, perhaps even leading role, in money in the world. But how good of moneys are cryptocurrencies? Are they more affordable, efficient, and reliable at doing the four functions we want money to do for us?</p>
              <ol>
                <li><b>Medium of Exchange</b>: Cryptocurrencies were promised to be "frictionless" forms of payment, but are, in reality, beleaguered with "frictions." To buy cryptocurrencies people have to pay exorbitant fees to crypto gateways like Coinbase, and then, most coins, especially BitCoin, transactions can take days to fully resolve.</li>
                <li><b>Unit of Account</b>: The value of most (and the largest) Cryptocurrencies is extremely volatile. So volatile that it would be absurd to do your accounting in the currency. From one day to the next, you would have to adjust all your salaries and prices to accomodate the wild swings in value of your currency.</li>
                <li><b>Store of Value</b>: Cryptocurrencies claim to be "digital gold" or good long term stores of value, but this has not yet proven itself to be the case. Currently the crypto market does not move like gold (slowly and stodgily growing year over year), instead it moves with the riskiest and most speculative swings of the market. Who knows what the long term value of these collectible digital tokens will be?</li>
                <li><b>Means of Deferred Payment</b>: Cryptocurrencies like BitCoin or Ethereum are almost impossible to use for lending or borrowing, again, because of their volatile and erratic changes in price. What interest rate could a bank charge to accommodate a currency that one day to the next could swing 30%?</li>
              </ol>
              <p>Even a cursory rundown of the four functions of money show that traditional cryptocurrencies, such as BitCoin and Ethereum, are really quite terrible at serving as moneys. In reality these are not currencies at all, but highly liquid collectibles, sort of like electronic collectible baseball cards.</p>
            </div>
            <div className="bg-white p-5 border rounded mb-5" id="stablecoins">
              <h4>The Problems with Stablecoins?</h4>
              <p>A new form of cryptocurrency launched in the past few years called "stablecoins." These are cryptocurrencies that, unlike BitCoin or Ethereum, where there is a finite or predictably growing supply, instead a centralized authority controls the value of the currency and stabilizes it. The most popular stable coins are Tether and USDCoin (USDC). Both of these are pegged to the value of the US Dollar and hold in reserve US Dollars and other assets closely tied to the US Dollar such as treasury bills. These stablecoins launched without regulatory oversight and only recently have begun to submit to anti money-laundering and anti-terrorist regulations.</p>
              <p>In some ways, Numey is like these stablecoins, except in a few key ways.</p>
              <ol>
                <li><b>Fully Regulated</b>: Numey has always sought to comply with the highest level of security and financial regulation to prevent Numey from being used for nepharious goals such as the sale of illegal drugs, money laundering, and terrorism.</li>
                <li><b>A Private Ledger, Not a Public One</b>: There is no reason for stablecoins to use a public cryptographic ledger. These coins have a central authority that controls the value of their coin. These currencies are not "trustless" in the way BitCoin or Ethereum are. Hence operating as a cryptocurrency only adds additional risk and cost to the operation of their moneys. Numey avoids the additional costs, risk, and frictions of being a cryptocurrency. Instead we use a secure, private, encrypted ledger to resolve transactions.</li>
                <li><b>No Inflation</b>: Stablecoins are only as stable as what they peg themselves to. Current stablecoins peg themselves to nation currencies, primarily the US Dollar. The US Dollar is not stable. It inflates each year by roughly 1-3% or more. Numey is the first currency that experiences neither inflation nor deflation by tying its value to the rate of inflation as published by the Bureau of Labor Statistics each month.</li>
              </ol>
              <p>Turning a critical eye on stablecoins we find that they are needlessly complex, risky, and their stability is only as stable as the national fiat currencies they adhere to. Couldn't we do better?</p>
            </div>
            <div className="bg-white p-5 border rounded mb-5" id="the-numey">
              <h4>What is the Numey?</h4>
              <p>Numey is the first currency designed to avoid experiencing either inflation or deflation. Because it is so new, we needed to create a new word to describe this new feature. We chose the word "inflation-linked" since the Numey is tied to the general cost of living.</p>
            </div>
          </div>
        </div>
      </div>

    </div>
  )
}
export default AboutMoney

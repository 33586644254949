import React from "react";
import { useNavigate, useParams, Link } from "react-router-dom";

import AuthService from '../AuthService'
import { UserContext } from "../UserContext";
import { toast } from 'react-toastify';

function ConfirmEmail() {
  const params = useParams();
  const [confirmed, setConfirmed] = React.useState('loading')
  const { confirmEmail } = AuthService();

  React.useEffect(() => {
    confirmEmail(params.token).then(() => {
      setConfirmed('confirmed')
    }).catch(err => {
      setConfirmed('error')
      // toast.warning(err.response.data.message)
    });
  }, [])

  return (
    <div className="container mt-5">
      <section className="row gradient">
        <div className="col-md-6 offset-md-3 col-xl-4 offset-xl-4">
          <div className="card mb-5">
            <div className="card-body">
              <div className="card-title text-center">
                <legend>Confirm Email</legend>
              </div>
              <div className="card-text text-center">
                {confirmed === 'loading' &&
                  <div>Confirming...</div>
                }
                {confirmed === 'confirmed' &&
                  <div>
                    <div>Email confirmed!</div> 
                    <Link to='/wallet' className="mt-3">Go to my wallet</Link>
                  </div>
                }
                {confirmed === 'error' &&
                  <div>
                    <div>Your email is already confirmed.</div>
                    <Link to='/wallet' className="mt-3">Go to my wallet</Link>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}
export default ConfirmEmail;

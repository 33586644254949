import React, { useEffect } from "react";
import { IoLogOutOutline } from "react-icons/io5";
import { GoPencil } from "react-icons/go";
import { FaCamera } from "react-icons/fa";
import SignInHistory from "../Users/UserProfile/SignInHistory";
import FundingSources from "../Users/UserProfile/FundingSources";
import DeactivateWallet from "../Users/UserProfile/DeactivateWallet";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import EditProfile from "../Users/UserProfile/EditProfile";
import Address from "./Address";
import { UserContext } from "../UserContext";
import { useNavigate } from "react-router-dom";
import AuthService from "../AuthService";
import axios from "axios";
import { toast } from "react-toastify";

function Profile() {
  const { user, setUser } = UserContext();
  const { signOut } = AuthService();
  const navigation = useNavigate();
  const [show, setShow] = useState(false);
  const [file, setFile] = useState();
  const [name, setName] = useState();

  const nameImgUrl = `https://ui-avatars.com/api/?background=0D8ABC&color=fff&name=${user?.first}+${user?.last}`;

  const navigate = useNavigate();

  const handleClose = () => setShow(false);
  const handleShow = () => navigate("/profile/edit");

  const logOut = async () => {
    try {
      await signOut();
      setUser(null);
      navigation("/");
    } catch (error) {
      toast.warning("There is something problem");
      console.log("error in signout", error);
    }
  };

  return (
    <>
      {user && (
        <>
          <div className="breadcrumbar">
            <h4>Profile</h4>
          </div>
          <div className="wallet-bar">
            <div className="profile_bar">
              <div className="profile-usefullcontent">
                <div className="row">
                  <div className="col-md-10 offset-md-1 d-flex justify-content-between">
                    <div className="useredit-picture">
                      <div className="userdetail">
                        <h4>{user.fullName}</h4>
                      </div>
                      <div>
                        <div>{user.address}<br/>{user.city}, {user.state} {user.postalCode}</div>
                        <div>{user.phone}</div>
                        <a className="text-muted text-sm" type="button" onClick={handleShow}>Edit Profile</a>
                      </div>
                    </div>
                    <div className="editbtn">
                      <a className="btn p-3 btn-outline-danger my-2" type="button" onClick={logOut}><IoLogOutOutline className='me-1' /> Sign Out</a>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 offset-md-3">
                    <FundingSources />

                    <SignInHistory />
                  </div>
                </div> 
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}
export default Profile;

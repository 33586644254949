import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import { FaAngleRight } from "react-icons/fa6";
import { Outlet } from "react-router-dom";

const Blog = () => {
  // const [index, setIndex] = useState(0);

  // const handleSelect = (selectedIndex) => {
  //   setIndex(selectedIndex);
  // };
  return (
    <>
      <section>
        <div className="bg-faq pdd ">
          <h2 className="fs-1 fw-bold text-center">The Numey Blog</h2>
        </div>
        <div className="container px-0 py-5">
          <Row className="mx-0">
            <Col md={4}>
              <div className="shadow article rounded-3 p-4">

                <h2 className="fs-4 fw-bold">
                  <Link to="/blog" className="text-dark">All Articles</Link>
                </h2>
                <div>
                  <Link to="/blog/inflation-linked-currencies-in-financial-crises" className="d-flex align-items-center justify-content-between py-3 border-bottom-1 text-decoration-none text-dark">
                    <div>
                      <strong>Inflation-Linked Currency & Financial Crises</strong>
                      <p className="mb-0 text-color">February 6, 2024</p>
                    </div>
                    <div>
                      <FaAngleRight />
                    </div>
                  </Link>
                  <Link to="/blog/how-inflation-ruins-the-holidays" className="d-flex align-items-center justify-content-between py-3 border-bottom-1 text-decoration-none text-dark">
                    <div>
                      <strong>Jingle Bills: How Inflation Grinches the Holidays!</strong>
                      <p className="mb-0 text-color">November 27, 2023</p>
                    </div>
                    <div>
                      <FaAngleRight />
                    </div>
                  </Link>
                  <Link to="/blog/inflation-in-israel-and-palastine" className="d-flex align-items-center justify-content-between py-3 border-bottom-1 text-decoration-none text-dark">
                    <div>
                      <strong>How Inflation Deepens the Israel-Palestine Conflict</strong>
                      <p className="mb-0 text-color">November 7, 2023</p>
                    </div>
                    <div>
                      <FaAngleRight />
                    </div>
                  </Link>
                  
                  <Link to="/blog/federal-reserve-fails-to-update-payment-system" className="d-flex align-items-center justify-content-between py-3 border-bottom-1 text-decoration-none text-dark">
                    <div>
                      <strong>Why Won't the Fed Improve Payments?</strong>
                      <p className="mb-0 text-color">June 20, 2023</p>
                    </div>
                    <div>
                      <FaAngleRight />
                    </div>
                  </Link>
                  <Link to="/blog/five-benefits-to-an-inflation-proof-economy" className="d-flex align-items-center justify-content-between py-3 border-bottom-1 text-decoration-none text-dark">
                    <div>
                      <strong>
                        Five Benefits of an Inflation-Proof Economy
                      </strong>
                      <p className="mb-0 text-color">June 05, 2023</p>
                    </div>
                    <div>
                      <FaAngleRight />
                    </div>
                  </Link>
                  <Link to="/blog/the-federal-reserve-with-one-mandate" className="d-flex align-items-center justify-content-between py-3 border-bottom-1 text-decoration-none text-dark">
                    <div>
                      <strong>
                        Hayekian Currencies: Reassessing the Fed's Dual Mandate
                      </strong>
                      <p className="mb-0 text-color">May 29, 2023</p>
                    </div>
                    <div>
                      <FaAngleRight />
                    </div>
                  </Link>
                  <Link to="/blog/the-ultimate-inflation-survival-guide" className="d-flex align-items-center justify-content-between py-3 border-bottom-1 text-decoration-none text-dark">
                    <div>
                      <strong>The Ultimate Inflation Survival Guide</strong>
                      <p className="mb-0 text-color">May 13, 2023</p>
                    </div>
                    <div>
                      <FaAngleRight />
                    </div>
                  </Link>
                </div>
              </div>
            </Col>
            <Col md={8}>
              <div>
                <div className="mx-4">
                  <Outlet />
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </section>
    </>
  );
};

export default Blog;

import React from 'react';

function Source(props) {
  if (props.tx.source) {
    return <div className="">{props.tx.source.first} {props.tx.source.last}</div>
  } else if (props.tx.externalSource) {
    return <div className="">{props.tx.externalSource.accountInfo} {props.tx.externalSource.accountNumberLastFour}</div>
  } else {
    return <div></div>
  }
}

export default Source;

// AdminTransfer.js

import React from "react";
import moment from 'moment'
import axios from 'axios';
import Cookies from 'universal-cookie';
import { toast } from 'react-toastify';

import Source from "./Source";
import Target from "./Target";

function AdminTransfer(props) {
  const [showConfirmFail, setShowConfirmFail] = React.useState(false)
  const [showConfirmProcess, setShowConfirmProcess] = React.useState(false)
  const [processedAt, setProcessedAt] = React.useState(props.tx.processedAt)
  const [failedAt, setFailedAt] = React.useState(props.tx.failedAt)

  const cookies = new Cookies();
  const authToken = cookies.get('nJWT');
  const config = { headers: { Authorization: `Bearer ${authToken}` } };

  function processTx(id) {
    const url = process.env.REACT_APP_BASE_DOMAIN + `/api/admin/process-tx/${id}`;
    const body = { id: id }
    axios.put(url, body, config)
      .then(response => {
        setShowConfirmProcess(false)
        setProcessedAt(response.data.processedAt);
        toast.success("Transfer processed.")
      })
      .catch(err => {
        toast.warn("There was a problem processing the transfer.")
        console.log(err)
      });
  }

  function failTx(id) {
    const url = process.env.REACT_APP_BASE_DOMAIN + `/api/admin/fail-tx/${id}`;
    const body = { id: id }
    axios.put(url, body, config)
      .then(response => {
        setShowConfirmFail(false)
        setFailedAt(response.data.failedAt);
        toast.success("Transfer marked as failed.")
      })
      .catch(err => {
        toast.warn("There was a problem marking the transfer as failed.")
        console.log(err)
      });
  }

  return (
    <tr>
      <td>{moment(props.tx.createdAt).format('MM-DD-YY hh:mma')}</td>
      <td><Source tx={props.tx}/></td>
      <td><Target tx={props.tx}/></td>
      <td>ǂ{props.tx.amount} (${props.tx.amountInDollars})</td>
      <td>
        {props.tx.transferInitializedAt && 
          <span>
            { moment(props.tx.transferInitializedAt).format('MM-DD-YY hh:mma ')} 
          </span>
        }
      </td>
      <td>
        {processedAt &&
          <span>
            {moment(processedAt).format('MM-DD-YY hh:mma')}
          </span>
        }

        {!processedAt && !failedAt &&
          <span>
            {!showConfirmProcess && <button className="btn btn-sm btn-outline-primary" onClick={() => setShowConfirmProcess(true)}>Process</button> }
            {showConfirmProcess &&
              <span>
                <button className="btn btn-sm btn-primary me-1" onClick={() => processTx(props.tx.id)}>Confirm</button>
                <button className="btn btn-sm btn-outline-secondary" onClick={() => setShowConfirmProcess(false)}>Cancel</button>
              </span>
            }
          </span>
        }
      </td>
      <td>
        {failedAt &&
          <span>
            {moment(failedAt).format('MM-DD-YY hh:mma')}
          </span>
        }
        {!processedAt && !failedAt &&
          <span>
            {!showConfirmFail && <button className="btn btn-outline-danger btn-sm" onClick={() => setShowConfirmFail(true)}>Mark Failed</button>}
            {showConfirmFail &&
              <span>
                <button className="btn btn-danger btn-sm" onClick={() => failTx(props.tx.id)}>Confirm</button>
                <button className="btn btn-outline-secondary btn-sm ms-1" onClick={() => setShowConfirmFail(false)}>Cancel</button>
              </span>
            }
          </span>
        }
      </td>
    </tr>
  )
}

export default AdminTransfer;

// SignIn.js

import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { LuEyeOff } from "react-icons/lu";
// import { UserContext } from "../UserContext";
import logoHorizontal from '../images/logo-horizontal.jpg';

import AuthService from "../AuthService";

function SignIn(props) {
  const { signIn } = AuthService();
  
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [passwordShown, setPasswordShown] = React.useState(false);

  const [loading, setLoading] = React.useState(false);

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
   try{
    e.preventDefault();
    setLoading(true);

    const user = {
      email: email,
      password: password,
    };

   await signIn(user)
      .then((user) => {
        toast.success(
          "We sent your private authorization code via SMS and email."
        );
        navigate("/two-factor-auth");
      })
      .catch((err) => {
        toast.warning(err.response.data.message);
        setLoading(false);
      });
   }catch(error){
    // toast.warning(err.response.data.message);

   }
  };

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  
  return (
    <>
      <div className="authentication-block">
        <div className="sidebar">
          <div className="logobar" onClick={()=>navigate("/")} style={{cursor:"pointer"}}>
            <img alt="logo" src="images/login-logo.svg" />
          </div>
          <div className="ilustrationbar">
            <img src="images/cuate.png" alt="ilustrationbar" />
          </div>
          <div className="sidebar-bottomside">
            <h5>Get Numey Mobile App</h5>
            <p>
              Numey mobile app is now also available on Google play and App
              store
            </p>
          </div>
        </div>
        <div className="Auth-bar centeredbox">
        <div className="logobar mobenable" onClick={()=>navigate("/")} style={{cursor:"pointer"}}>
            <img alt="logo" src={ logoHorizontal } className="logo" />
          </div>
          <form onSubmit={handleSubmit}>
            <div className="formtitle">
              <h3>Sign In</h3>
              <p>Please sign in to your wallet </p>
            </div>
            <div className="mb-3">
              <label className="form-label" htmlFor="email">
                Email Address or Username<span className="text-danger">*</span>
              </label>
              <input
                name="email"
                id="email"
                className="form-control"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter email"
                required="required"
              />
            </div>
            <div className="mb-3">
              <div className="d-flex justify-content-between">
                <label className="form-label" htmlFor="password">
                  Password<span className="text-danger">*</span>
                </label>
              </div>
              <div className="input-group">
                <input
                  name="password"
                  type={passwordShown ? "text" : "password"}
                  id="password"
                  placeholder="Enter password"
                  className="form-control"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  autoComplete="off"
                  required="required"
                />
                <button
                  className="btneye btn"
                  type="button"
                  onClick={togglePassword}
                >
                  {passwordShown ? <LuEyeOff /> : <MdOutlineRemoveRedEye />}
                </button>
              </div>
            </div>
            <div className="d-flex justify-content-end mb-3">
                
                <Link className="forgotbar" to="/forgot-password">Forgot Your Password?</Link>
              </div>
            <div className="d-block text-render-bottom">
              {/*<Link className="me-3 btn btn-light" to="/subscribe">
                Get Updates
              </Link> */}
              {/*<Link className="me-3 btn btn-light" to="/sign-up">
                Sign Up
              </Link> */}
              <button
                className="btn btn-primary w-100"
                disabled={loading}
                type="submit"
              >
                Sign In
              </button>
              
              <h6 className="mt-3 text-center mb-0">Don’t have wallet? <Link className="forgotbar" to="/sign-up">Create Wallet</Link></h6>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
export default SignIn;


import React, { useEffect, useState, useContext } from 'react';
import { Link, useNavigate } from "react-router-dom";
import Cookies from 'universal-cookie';
import axios from 'axios';
import AuthService from '../AuthService'
import { UserContext } from "../UserContext";
import { toast } from 'react-toastify';
import { Link2 } from 'react-feather';

import { usePlaidLink } from 'react-plaid-link';

function NewExternal(props) {
  const { user, setUser } = UserContext();
  const { getCurrentUser } = AuthService();

  const [name, setName] = React.useState("");

  const [linkToken, setLinkToken] = useState(null);
  const [loading, setLoading] = React.useState(false);

  const [plaidLinkVisible, setPlaidLinkVisible] = React.useState(false);

  const navigate = useNavigate();

  const config = {
    token: linkToken,
    onSuccess: ((token, metadata) => {
      // send token to server to exchange for access_token
      setLoading(true)
      const cookies = new Cookies();
      const authToken = cookies.get('nJWT');
      const url = process.env.REACT_APP_BASE_DOMAIN + '/api/externals';
      const config = { headers: { Authorization: `Bearer ${authToken}`, 'Content-Type': 'application/json', } };
      const body = { public_token: token, name: document.getElementById('name').value, metadata: metadata }

      axios.post(url, body, config)
        .then(response => {
	        getCurrentUser().then((user) => {
	          setUser(user)
	          toast.success("New funding source added successfully!")
	          navigate('/wallet')
	        });
	      })
        .catch(err => {
          toast.warning("There was a problem connecting with our bank integration partner. Please try again.")
          setLoading(false)
        });
    }),
  };

  const { open, ready } = usePlaidLink(config);

  const generatePlaidToken = async () => {
    const cookies = new Cookies();
    const authToken = cookies.get('nJWT');
    const url = process.env.REACT_APP_BASE_DOMAIN + '/api/plaid/create-link-token';
    const config = { headers: { Authorization: `Bearer ${authToken}` } };

    axios.post(url, {}, config)
      .then(response => {
        setLinkToken(response.data.linkToken);
      })
      .catch(err => {
        toast.warning("There was a problem connecting with our bank integration partner.")
      });
  };

  React.useEffect(() => {
    generatePlaidToken();
  }, []);

  const handleNameChange = (e) => {
  	setName(e.target.value)
  	if (name.length > 0) {
  		setPlaidLinkVisible(true);
  	} else {
  		setPlaidLinkVisible(false);
  	}
  }

  // const handlenavigation = () =>{
  //   if(user.identityVerifiedAt){
  //     navigate("/me")
  //     props.setShowAddFund(false)
  //   } else {
  //     navigate("/add-funding-source")
  //   }
  // }

  return (
    <>
      <div className="breadcrumbar">
        <h4>Add New Funding Source</h4>
      </div>
      <div className="wallet-bar row">
        <div className="sendnumey col-lg-6 offset-lg-3">
          <label className="form-label" htmlFor="name">Nick Name for Account<span className="text-danger">*</span></label>
          <input
            className="form-control"
            id="name"
            name="name"
            placeholder=""
            value={name}
            required="required"
            autoComplete="off"
            autoFocus
            onChange={handleNameChange} />

          <div className="mt-4 d-grid gap-2 my-4 mx-2">
            {!plaidLinkVisible &&
              <button className="btn btn-secondary link-disabled w-100" disabled={plaidLinkVisible}>
                <Link2 className="pb-1 pt-1" /> Connect to Your Bank
              </button>
            }
            {plaidLinkVisible && !loading && ready &&
              <button className="btn btn-primary w-100"  onClick={() => open()} >
                <Link2 className="pb-1 pt-1" /> Connect to Your Bank
              </button>
            }
            {plaidLinkVisible && loading &&
              <button className="btn btn-primary w-100" type="button" disabled>
                <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                Loading...
              </button>
            }
            <div className="form-text">For your security, at this time we can only verify accounts through our bank integration partner Plaid.</div>
          </div>
        </div>
      </div>
    </>
  )
}
export default NewExternal;

import React from "react";
import { MdKeyboardBackspace } from "react-icons/md";
import Cookies from 'universal-cookie';
import axios from 'axios';
import { Link, useNavigate } from "react-router-dom";
import AuthService from "../AuthService";
import TargetSearch from "./TargetSearch";
import { toast } from 'react-toastify';
import { UserContext } from "../UserContext";


function SendNumey(props) {
  const { user, setUser } = UserContext();
  const { getCurrentUser } = AuthService();

  const [amount, setAmount] = React.useState("0.00");
  const [target, setTarget] = React.useState("");
  const [msg, setMsg] = React.useState("");
  const [steps,setSteps] = React.useState(1)
  const [loading, setLoading] = React.useState(false);

  // const [feeInScript, setFeeInScript] = React.useState(0);
  // const [feeInDollars, setFeeInDollars] = React.useState(0);
  const [amountInDollars, setAmountInDollars] = React.useState("0.00");
  // const [amountTotal, setAmountTotal] = React.useState(0);
  // const [amountTotalInDollars, setAmountTotalInDollars] = React.useState(0);
  const [conversionRateToDollars, setConversionRateToDollars] = React.useState(0);
  const [prettyConversionRateToDollars, setPrettyConversionRateToDollars] = React.useState("");

  const navigate = useNavigate();

  React.useEffect(() => {
    // GET CONVERSION RATE
    const url = process.env.REACT_APP_BASE_DOMAIN + "/api/exchange-rates/current";

    axios.get(url)
      .then(response => {
        setConversionRateToDollars(response.data.conversionRateToDollars);
        setPrettyConversionRateToDollars(response.data.prettyConversionRateToDollars);
      })
      .catch(err => {
        toast.warning(err.response.data.message)
      });
  }, [])

  const maxScript = (e) => {
    // balance - fee for transfering whole balance.
    const max = 1000 * user.balance / 1003
    // const feeInScript = (max * 0.003).toFixed(2);
    // const feeInDollars = (feeInScript * conversionRateToDollars).toFixed(2);
    // const amountTotal = (parseFloat(max) + parseFloat(feeInScript)).toFixed(2)
    // const amountTotalInDollars = (amountTotal * conversionRateToDollars).toFixed(2);

    setAmount(max.toFixed(2))
    // setFeeInScript(feeInScript);
    // setFeeInDollars(feeInDollars);
    // setAmountTotal(amountTotal)
    // setAmountTotalInDollars(amountTotalInDollars);
  }

  const handleAmountChange = (e) => {
    const value = e.target.value.slice(0, -1);                // input box value before keyup
    const input = e.target.value[e.target.value.length - 1];  // input char
    const intValue = parseInt(value.replace(/\D/g,''));       // replace the specail char
    const finalVal = intValue + input;                        // add like string and format it
    var formattedVal = (finalVal/100).toFixed(2);             // 12.34
    if (finalVal == "00" || isNaN(intValue)) {                // if 00 or NaN reset
      formattedVal = "0.00"
    }

    const calcAmount = parseFloat(formattedVal || 0);
    // const feeInScript = (parseFloat(calcAmount) * 0.003).toFixed(2);
    // const feeInDollars = (feeInScript * conversionRateToDollars).toFixed(2);
    // const amountTotal = (calcAmount + parseFloat(feeInScript)).toFixed(2);
    // const amountTotalInDollars = (amountTotal * conversionRateToDollars).toFixed(2);


    setAmount(formattedVal);
    setAmountInDollars((formattedVal * conversionRateToDollars).toFixed(2));
    // setFeeInScript(feeInScript);
    // setFeeInDollars(feeInDollars);
    // setAmountTotal(amountTotal)
    // setAmountTotalInDollars(amountTotalInDollars);
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true)

    // const amountFee = +amount*0.01;
    // const amountWithFee = +amount + amountFee
    const body = {
      amount: amount,
      target: target,
      msg: msg
    }
    const cookies = new Cookies();
    const authToken = cookies.get('nJWT');
    const url = process.env.REACT_APP_BASE_DOMAIN + "/api/txs/send";
    const config = { headers: { Authorization: `Bearer ${authToken}` } };

    axios.post(url, body, config)
      .then(response => {
        getCurrentUser().then((user) => {
          setUser(user)
          toast.success('Numey sent!')
          navigate('/wallet')
        });
      })
      .catch(err => {
        toast.warning(err.response.data.message)
        setLoading(false)
      });

  }

  return (
    <>
      <div className="breadcrumbar">
        <h4>Send Numey</h4>
      </div>
      <div className="wallet-bar row">
        <form onSubmit={handleSubmit}>
          <div className="sendnumey col-lg-8 offset-lg-2">
            <div className="text-center main_balance my-5">
              <span className="nmy">ǂ</span>
              <input
                name="amount"
                value={amount}
                onChange={handleAmountChange}
                type="number"
                placeholder="0.00"
                step="0.01" min="1" max="10000"
                required="required"
                className="form-control"
                autoFocus
              />
              <div className="mt-3">
                <small className="text-muted">{`($ ${parseFloat((amount*conversionRateToDollars).toFixed(2))})`}</small>
              </div>
            </div>
            <div className="mb-2">
              <TargetSearch target={target} setTarget={setTarget} />
            </div>
            <div className="mb-2">
              <label className='form-label'>Note (Optional)</label>
              <textarea
                className="form-control"
                id="note"
                name="note"
                placeholder="Add a private note..."
                maxLength="100"
                value={msg}
                rows="3"
                onChange={e => setMsg(e.target.value)}>
                </textarea>
            </div>
            <div className="my-2">
              <p className="form-text">Your transaction will be completed instantaneously and cannot be reversed.</p>
            </div>
            <button 
              className="btn btn-primary w-100" 
              type="submit"
              disabled={loading}
            >Confirm and Send</button>
          </div>
        </form>
      </div>
    </>
  );
}
export default SendNumey;

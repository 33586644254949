import React, { useEffect, useState,useRef } from "react";
import { Link } from "react-router-dom";
import { toast } from 'react-toastify';
import axios from 'axios';
import { Chart as ChartJS, LineElement, PointElement, CategoryScale, LinearScale } from 'chart.js'
import { Line } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement
)

function NumeyValueChart() {

  const [rates, setRates] = useState([]);
  const isMounted = useRef(true);

  useEffect(() => {
    // Fetch Exchange Rates
    isMounted.current = true;

    const url = process.env.REACT_APP_BASE_DOMAIN + "/api/exchange-rates";

    axios.get(url)
      .then(response => {
        setRates(response.data)
      }).catch(err => {
        console.log(err);
        toast.warning(err.response.data.message)
      });

      return () => {
        isMounted.current = false;
      };
  
  }, []);

  const data = {
    labels: rates?.map(r => `${r.year} ${r.period}`),
    datasets: [
      {
        label: 'Value of the Numey',
        data: rates?.map(r => r.current),
        borderColor: '#00339e',
        backgroundColor: '#00339e',
      }
    ],
  };


  return (
    <div>
      {/*<h1 className="text-center">A Proven Track Record Fighting Inflation</h1>
      <div className="row">
        <div className="col-lg-8 offset-lg-2">
          <p className="lead text-muted my-4">The US Dollar has fallen roughly 16% since January 2020. Numey's value increases by the same amount. Remember, however, that the Numey's real value, its purchasing power, does not change. The Numey's value only appears to go up because the US Dollar goes down in value through inflation. </p>
          <p className="lead text-muted my-4">Over the same time period, the value of Numey's asset reserve grew by roughly 32%.</p>
        </div>
      </div>
      <h5>The Value of the Numey in USD (Jan 2020-Present)</h5>*/}
      <Line 
        data={data}
      />
    </div>
  )
}
export default NumeyValueChart;

// SignUp.js

import React from "react";
import { Link, useNavigate } from "react-router-dom";
import SignUpForm from "./SignUpForm";

import logoHorizontal from '../images/logo-horizontal.jpg';

function SignUp(props) {

  const navigate = useNavigate();
  return (
    <>
      <div className="authentication-block">
        <div className="sidebar">
          <div className="logobar" onClick={()=>navigate("/")} style={{cursor:"pointer"}}>
            <img alt="logo" src="images/login-logo.svg" />
          </div>
          <div className="ilustrationbar">
            <img src="images/cuate.png" alt="ilustrationbar" />
          </div>
          <div className="sidebar-bottomside">
            <h5>Get Numey Mobile App</h5>
            <p>
              Numey mobile app is now also available on Google play and App
              store
            </p>
          </div>
        </div>
        <div className="Auth-bar">
          <div className="logobar mobenable" onClick={()=>navigate("/")} style={{cursor:"pointer"}}>
            <img alt="logo" src={ logoHorizontal } className="logo" />
          </div>
          <div className="formtitle text-center">
            <h3>Create Wallet</h3>
            <p>Please fill in the following information to register your wallet </p>
          </div>
          <SignUpForm />
          <h6 className="mt-3 text-center mb-0">
            Have wallet?
            <Link className="forgotbar" to="/sign-in">
              Sign in
            </Link>
          </h6>
        </div>
      </div>
    </>
  );
}
export default SignUp;

import React, { useState, useContext, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { FaBars } from "react-icons/fa";
import { FaPlus } from "react-icons/fa6";
import { CiCreditCard1 } from "react-icons/ci";
import { MdDashboardCustomize } from "react-icons/md";
import logo from './images/logo.svg';
import logoHorizontal from './images/logo-horizontal.jpg';

import axios from 'axios';

import AuthService from './AuthService'
import { UserContext } from "./UserContext";

import { toast } from 'react-toastify';

const Navbar = () => {
  const { user, setUser, exchangeRate } = UserContext();
  const [scrollTop, setScrollTop] = useState(0);
  
  const { signOut } = AuthService();
  const navigate = useNavigate();
  
  const [conversionRateToDollars, setConversionRateToDollars] = React.useState(0);
  const [prettyConversionRateToDollars, setPrettyConversionRateToDollars] = React.useState("");

  React.useEffect(() => {
    // GET CONVERSION RATE
    const url = process.env.REACT_APP_BASE_DOMAIN + "/api/exchange-rates/current";

    axios.get(url)
      .then(response => {
        setConversionRateToDollars(response.data.conversionRateToDollars);
        setPrettyConversionRateToDollars(response.data.prettyConversionRateToDollars);
      })
      .catch(err => {
        toast.warning(err.response.data.message)
      });
  }, [])
  
  function handleSignOut() {
    signOut().then(() => {
      setUser(null);
      navigate("/");
    });
  }

  useEffect(() => {
    const handleScroll = (event) => {
      setScrollTop(window.scrollY);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [window.scrollY]);

  const location = useLocation();
  const currentRoute = location.pathname;

  const scrollt = () => {
    return setScrollTop(0);
  };

  return (
    <>
    {/* -------------------------desktop------------------------- */}
    <nav className={`${scrollTop ? "shadow" : " "}  bg-white py-3 d-none d-lg-block`}>
      <div className="mx-md-5">
        <div className="d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center gap-5">
            <figure className="mb-0">
              <Link to="/" >
                <img src={ logoHorizontal } className="logo" alt="logo" />
              </Link>
            </figure>
            <ul className="m-0 p-0 d-flex align-items-center nav-links">
              <li onClick={scrollt}>
                <Link className={`${currentRoute === "/ourmission" ? "link-active" : "l"}`} to="/ourmission">Our Mission</Link>
              </li>
              <li onClick={scrollt}>
                <Link className={`${currentRoute === "/howitworks" ? "link-active" : "l"}`} to="/howitworks">How It Works</Link>
              </li>
              <li onClick={scrollt}>
                <Link className={`${currentRoute === "/faq" ? "link-active" : "l"}`} to="/faq">FAQ</Link>
              </li>
              <li onClick={scrollt}>
                <Link className={`${currentRoute === "/blog" ? "link-active" : "l"}`} to="/blog">Blog</Link>
              </li>
            </ul>
          </div>
          <div className="d-flex align-items-center btn-grp">
            <button className="btn btn-light text-dark">
              ǂ1 / ${conversionRateToDollars.toFixed(3)}
            </button>
      
            {!user && <>
              
              <Link to="/sign-in" className="btn py-2 px-3 btn-outline-primary bg-white text-dark">Sign In</Link>
              {/* <Link to="/sign-up" className="btn py-2 px-3 btn-primary">Get Started</Link> */ }
              <Link to="/subscribe" className="btn py-2 px-3 btn-primary">Get an Invite</Link>
            </>}
            <div className="headerblock btnheaderbar">
              {user &&  
                <Link to="/wallet" className="btn py-2 px-3 btn-primary"><span >Wallet</span></Link>
              }  
            </div>
          </div>
        </div>
      </div>
    </nav>
    {/* -------------------------mobile------------------------- */}
    <nav className={`${scrollTop ? "shadow" : " "} d-block d-lg-none px-2 bg-white py-2`}>
      <div className="d-flex align-items-center justify-content-between">
        <figure className="mb-0">
          <Link to="/">
            <img alt="logo" src={ logoHorizontal } className="logo" />
          </Link>
        </figure>
        <div>
          <button onClick={() => document.body.classList.toggle("nav-active")} className=" btn btnn btn-primary">
            <FaBars />
          </button>
        </div>
        <ul className="m-0 p-0 gap-2 mobile-nav  flex-column align-items-center nav-links-mob">
          <li onClick={() => document.body.classList.toggle("nav-active")}>
            <Link to="/ourmission">Our Mission</Link>
          </li>
          <li onClick={() => document.body.classList.toggle("nav-active")}>
            <Link to="/howitworks">How It Works</Link>
          </li>
          <li onClick={() => document.body.classList.toggle("nav-active")}>
            <Link to="/faq">FAQ</Link>
          </li>
          <li onClick={() => document.body.classList.toggle("nav-active")}>
            <Link to="/blog">Blog</Link>
          </li>
          {!user && <>
            <li onClick={() => document.body.classList.toggle("nav-active")}>
              <Link to="/subscribe">Get Invited</Link>
            </li>
            <li onClick={() => document.body.classList.toggle("nav-active")}>
              <Link to="/sign-in">Login</Link>
            </li>

            {/* <li onClick={() => document.body.classList.toggle("nav-active")}>
              <Link to="/sign-up">Sign Up</Link> 
            </li> */}  
          </>}
        </ul>
      </div>
    </nav>
  </>
  );
};

export default Navbar;

import React from "react";
import { render } from "react-dom";
import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router } from "react-router-dom";

import "./fonts/EBGaramondSC08-Regular.otf";
import "../src/fonts/stylesheet.css";

import { UserProvider } from "./UserContext";

const domNode = document.getElementById("root");
const root = createRoot(domNode);

  // const value  ={};

root.render(
  <React.StrictMode>
    <UserProvider>
      <App />
    </UserProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

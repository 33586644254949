// NewSub.jsx

import React from "react";
import { Col, Form, InputGroup, Row } from "react-bootstrap";
import InputMask from "react-input-mask";
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
// import { UserContext } from "../UserContext";
import logoHorizontal from '../images/logo-horizontal.jpg';

// import AuthService from "../AuthService";

function NewSub(props) {
  const navigate = useNavigate();

  const [first, setFirst] = React.useState("");
  const [last, setLast] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [subPhone, setSubPhone] = React.useState("");
  
  const [setLoading] = React.useState(false);
  // const [submitted, setSubmitted] = React.useState(false);

  const submitNewSub = (e) => {
    e.preventDefault();
    setLoading(true)

    const user = {
      first: first,
      last: last,
      email: email,
      phone: subPhone
    }
    
    const url = process.env.REACT_APP_BASE_DOMAIN + '/api/subs';

    axios.post(url, user).then(response => {
      setEmail("")
      setFirst("")
      setLast("")
      setSubPhone("")
      toast.success("Subscribed to the Numey newsletter");
      navigate("/")
    }).catch(err => {
      console.log(err.message)
      toast.warning("There was a problem with your form. Please try again.")
    })
  }

  
  return (
    <>
      <div className="authentication-block">
        <div className="sidebar">
          <div className="logobar" onClick={()=>navigate("/")} style={{cursor:"pointer"}}>
            <img alt="logo" src="images/login-logo.svg" />
          </div>
          <div className="ilustrationbar">
            <img src="images/cuate.png" alt="ilustrationbar" />
          </div>
{/*          <div className="sidebar-bottomside">
            <h5>Get Numey Mobile App</h5>
            <p>
              Numey mobile app is now also available on Google play and App
              store
            </p>
          </div>*/}
        </div>
        <div className="Auth-bar centeredbox">
          <div className="logobar mobenable" onClick={()=>navigate("/")} style={{cursor:"pointer"}}>
            <img alt="logo" src={ logoHorizontal } className="logo" />
          </div>
          <form onSubmit={submitNewSub}>  
            <div className="formtitle">
              <h3>Get an Invite</h3>
              <p>Fill out your email and other info below to ...</p> 
            </div>
            <ul className="mb-5">
              <li>Recieve <b>a personalized invitation</b> for Numey when we go live.</li>
              <li>Get the occasional <b>Numey Update</b> about fundraising and go live schedule.</li>
              <li>Get <b>a free e-book</b> — <i>The Denationalization of Money</i> by FA Hayek.</li>
            </ul>
            <Row>
              <Col md={12}>
                <Form.Label htmlFor="inlineFormInputGroup">Email<span className="text-danger">*</span></Form.Label>
                <InputGroup className="mb-3">
                  <Form.Control
                    type="email"
                    className="py-3"
                    id="inlineFormInputGroup"
                    placeholder="Enter Email"
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                    required="required" autoFocus
                  />
                </InputGroup>
              </Col>
              <Col md={6}>
                <Form.Label htmlFor="inlineFormInputGroup">
                  First Name
                </Form.Label>
                <InputGroup className="mb-3">
                  <Form.Control
                    className="py-3"
                    id="inlineFormInputGroup"
                    placeholder="Enter First Name"
                    value={first}
                    onChange={e => setFirst(e.target.value)}
                  />
                </InputGroup>
              </Col>
              <Col md={6}>
                <Form.Label htmlFor="inlineFormInputGroup">
                  Last Name
                </Form.Label>
                <InputGroup className="mb-3">
                  <Form.Control
                    className="py-3"
                    id="inlineFormInputGroup"
                    placeholder="Enter Last Name"
                    value={last}
                    onChange={e => setLast(e.target.value)}
                  />
                </InputGroup>
              </Col>
              <Col md={12}>
                <Form.Label htmlFor="inlineFormInputGroup">
                  Phone number
                </Form.Label>
                <InputGroup className="mb-3">
                  <InputMask
                    className="form-control"
                    id="phone"
                    type="tel"
                    name="phone"
                    placeholder="+1(555) 333-4444"
                    value={subPhone}
                    onChange={e => setSubPhone(e.target.value)}
                    mask="+1\(999) 999-9999"
                    maskplaceholder={""}
                    maskchar=" "
                    />
                </InputGroup>
              </Col>
              <Col md={12}>
                <button type="submit" className="btn fs-6 mt-4 btn-primary w-100 py-3">
                  Subscribe
                </button> 
              </Col>
            </Row>
          </form>
        </div>
      </div>
    </>
  );
}
export default NewSub;

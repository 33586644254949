
// TXS-LIST.JS

import React, { useEffect, useState } from "react";
import axios from 'axios';
import Cookies from 'universal-cookie';
import TxsAll from './TxsAll';
import { toast } from 'react-toastify';
import ReactPaginate from 'react-paginate';
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";

import { UserContext } from "../UserContext";
import TxsSend from "./TxsSend";
import TxsBuySell from "./Txs-Buy-Sell";

function PaginatedTxs({ txsPerPage }) {
  const { user } = UserContext();
  // We start with an empty list of items.
  const [txs, setTxs] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [offset, setOffset] = useState(0);

  useEffect(() => {
    // Fetch items from another resources.
    // const endOffset = offset + txsPerPage;
    // console.log(`Loading items from ${offset} to ${endOffset}`);
    const url = process.env.REACT_APP_BASE_DOMAIN + "/api/txs/paginate";
    const cookies = new Cookies();
    const authToken = cookies.get('nJWT');
    const config = {
      headers: { Authorization: `Bearer ${authToken}` },
      params: {
        limit: txsPerPage,
        offset: offset
      }
    };

    axios.get(url, config)
      .then(txs => {
        setTxs(txs.data.rows)
        setPageCount(Math.ceil(txs.data.count / txsPerPage));
      }).catch(err => {
        console.log(err);
        toast.warning(err.response.data.message)
      });

  }, [offset, txsPerPage]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * txsPerPage)
    setOffset(newOffset);
  };

  return (
    <>
      <div className="list-group">
        <div className="trasection-list-block">
          {/*<h4>Recent Transactions</h4>*/}
          <div className="transection-tabs">
            <Tabs defaultActiveKey="All" transition={false} id="noanim-tab-example" className="mb-3">
              <Tab eventKey="All" title="All">
                {txs && txs.map(txs=>
                  <TxsAll currentUserId={user.id} tx={txs} key={txs.id}></TxsAll>
                )}
              </Tab>
              <Tab eventKey="Sent/Received" title="Sent/Received">
                {txs && txs.map(txs=>
                  <TxsSend currentUserId={user.id} tx={txs} key={txs.id}></TxsSend>
                )}
              </Tab>
              <Tab eventKey="Buy/Sell" title="Buy/Sell" >
                {txs && txs.map((txs)=>
                  <TxsBuySell currentUserId={user.id} tx={txs} key={txs.id}></TxsBuySell>
                )}
              </Tab>   
            </Tabs>
            {txs && txs.length === 0 &&
              <div className="container text-center mt-5">
                <div className="mb-3">
                  <h3>Welcome!</h3>
                </div>
                <div className="col-sm-10 offset-sm-1">
                  <img src="/images/undraw/welcome.svg" alt="Welcome!" className="img-fluid" />
                </div>
                <div className="form-text mt-3">You don't have any transactions yet. <br/>They will go here once you do.</div>
              </div>
            }
          </div>
        </div>
      </div>

      
      <ReactPaginate
        className="pagination justify-content-end mt-3"
        nextLabel=">"
        onPageChange={handlePageClick}
        pageRangeDisplayed={5}
        pageCount={pageCount}
        previousLabel="<"
        renderOnZeroPageCount={null}
        breakLabel="..."
        breakClassName={'page-item'}
        breakLinkClassName={'page-link'}
        containerClassName={'pagination'}
        pageClassName={'page-item'}
        pageLinkClassName={'page-link'}
        previousClassName={'page-item'}
        previousLinkClassName={'page-link'}
        nextClassName={'page-item'}
        nextLinkClassName={'page-link'}
        activeClassName={'active'}
      />
    </>
  );
}

function TxsList(props) {
  const { user } = UserContext();

  return (
    <>
      {user && <PaginatedTxs txsPerPage={25}/> }
    </>
  )
}
export default TxsList;

import React, { useState, useContext } from "react";
import { Link, Navigate, useLocation } from "react-router-dom";
import AdminUser from "./AdminUser";

import axios from 'axios';
import Cookies from 'universal-cookie';

import { Search } from 'react-feather';

import moment from 'moment'
import { toast } from 'react-toastify';

function AdminUsers() {
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);

  const handleUserFilter = e => {
    const value = e.target.value.toLowerCase();
    const matches = users.filter(u => {
      if (value == '') return true
      if (u.fullName.toLowerCase().includes(value) || u.email.includes(value)) {
        return true
      }
    });
    setFilteredUsers(matches);
  }

  React.useEffect(() => {
    const userUrl = process.env.REACT_APP_BASE_DOMAIN + "/api/admin/users";
    const cookies = new Cookies();
    const authToken = cookies.get('nJWT');
    const config = { headers: { Authorization: `Bearer ${authToken}` } };

    axios.get(userUrl, config)
      .then(users => {
        setUsers(users.data);
        setFilteredUsers(users.data);
      }).catch(err => {
        toast.warning(err.response.data.message)
      });
  }, []);

  return (
    <div className="card mb-4">
      <div className="card-body">
        <div className="card-text">
          <div className="d-flex justify-content-between mb-1">
            <h4 className="me-5">Users</h4>
            <div className="ms-5 me-5 input-group">
              <span className="input-group-text" id="basic-addon1"><Search className="feather-16"/></span>
              <input className="form-control" onChange={handleUserFilter} placeholder="Search Users"/>
            </div>
          </div>
        </div>
        <table className="table table-striped">
          <thead>
            <tr>
              <th>Joined On</th>
              <th>Verified</th>
              <th>Name</th>
              <th>Email</th>
              <th>Phone</th>
              {/*<th>DOB</th>*/}
              <th>Address I</th>
              <th>Balance</th>
              <th>Freeze</th>
            </tr>
          </thead>
          <tbody>
          {filteredUsers.map((user) => (
            <AdminUser user={user} key={user.id}></AdminUser>
          ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}
export default AdminUsers;


// TargetSearch.js
import React from "react";
import Cookies from 'universal-cookie';
import axios from 'axios';
import { toast } from 'react-toastify';

// onChange, fetch users
// display users in SuggestionsListComponent
// onClick of a user item, set user data to value

const TargetSearch = ({ target, setTarget }) => {
  const [users, setUsers] = React.useState([]);
  const [showSuggestions, setShowSuggestions] = React.useState(false);

  const onChange = (e) => {
    setTarget(e.target.value);

    const cookies = new Cookies();
    const authToken = cookies.get('nJWT');
    const config = {
      headers: { Authorization: `Bearer ${authToken}` },
      params: { term: target }
     };

    const url = process.env.REACT_APP_BASE_DOMAIN + "/api/recipients";

    

    axios.get(url, config)
    .then(response => {
      setUsers(response.data);
      console.log("targetdata",response.data);
      if (target.length > 0) {
        setShowSuggestions(true);
      }
    })
    .catch(err => {
      toast.warning(err?.response?.data?.message)
    });
      

  };

  const SuggestionsListComponent = () => {
    return (
      <ul className="list-group autocomplete-list">
        { users.length > 0 &&
          <div>
          {users.map((u, index) => {
            return (
              <div className="list-group-item" key={index} onClick={onClick} data-username={u.username}>{u.fullName} - {u.username}</div>
            );
          })}
          </div>
        }
        { users.length === 0 &&
          <li className="list-group-item"><em>There is no wallet with that information.</em></li>
        }
      </ul>
    );
  };

  const onClick = (e) => {
    setUsers([]);
    setTarget(e.target.dataset.username);
    setShowSuggestions(false);
  };

  return (
    <>
      <label className='form-label'>Send Numey To:</label>
      <input
        type="text"
        className="form-control"
        onChange={onChange}
        value={target}
        placeholder="Username, email, or phone number"
        required="required"
        id="target-search"
        name="target"
        autoFocus
      />
      <div className="autocomplete">
        {showSuggestions && target && <SuggestionsListComponent />}
      </div>
    </>
  );
};
export default TargetSearch;

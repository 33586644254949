import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";

import AuthService from "../AuthService";
import { toast } from "react-toastify";
import { UserContext } from "../UserContext";
import { useLocation } from "react-router-dom";

function TwoFactorAuth() {
  const [token, setToken] = useState("");

  const { twoFactorAuthenticate, resendToken } = AuthService();
  const  {setUser}  = UserContext();
   

  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();

    twoFactorAuthenticate( token )
  
      .then((user) => {
        console.log("user in Auth",user)
        setUser(user)
       
        if (
          !user.first ||
          !user.last ||
          !user.username ||
          !user.address ||
          !user.dob ||
          !user.city ||
          !user.postalCode ||
          !user.state
        ) {
          navigate("/personal-information");
        } else {
          if (user.identityVerifiedAt) {
            navigate("/wallet");
          } else {
            navigate("/verify-identity");
          }
        }
        console.log("Two-factor authentication successful!")
        toast.success("Two-factor authentication successful!");
      })
      

      .catch((err) => {
        toast.warning(err.response.data.message);
        console.log("err",err.response.data.message)
      });
  };

  const handleResendToken = (e) => {
    e.preventDefault();
    resendToken().then(() => {
      toast.success(
        "We resent your private authorization code via SMS and email."
      );
    });
  };

  return (
    <div className="authentication-block">
      <div className="sidebar">
        <div className="logobar" onClick={()=>navigate("/")} style={{cursor:"pointer"}}>
          <img alt="logo" src="images/login-logo.svg" />
        </div>
        <div className="ilustrationbar">
          <img src="images/cuate.png" alt="ilustrationbar" />
        </div>
        <div className="sidebar-bottomside">
          <h5>Get Numey Mobile App</h5>
          <p>
            Numey mobile app is now also available on Google play and App store
          </p>
        </div>
      </div>
      <div className="Auth-bar">
        <form onSubmit={handleSubmit}>
        <div className="formtitle">
              <h3>Two Factor Authentication</h3>
              <p>A unique 6-digit token was sent to your phone.</p>
            </div>
          <div className="mb-3">
            <label className="form-label" htmlFor="token">
              Authentication Token<span className="text-danger">*</span>
            </label>
            <input
              className="form-control"
              id="token"
              value={token}
              onChange={(e) => setToken(e.target.value)}
              name="token"
              placeholder="123456"
              autocomplete="one-time-code" 
              inputmode="numeric"
              pattern="[0-9]*"
              required="required"
            />
            <div className="form-text">
              You should have just received an SMS message with your temporary
              authentication token. Enter that token here to authenticate
              yourself.
            </div>
          </div>
          <div className="d-flex justify-content-between">
            <a className="btn btn-light" onClick={handleResendToken}>
              Resend
            </a>
            <button className="btn btn-primary" type="submit">
              Authenticate
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
export default TwoFactorAuth;

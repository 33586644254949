
// AdminUser.js

import React, { useEffect, useState, useContext } from "react";
import moment from 'moment'
import axios from 'axios';
import Cookies from 'universal-cookie';
import { toast } from 'react-toastify';
import { Square, CheckSquare, Check } from 'react-feather';

function AdminUser(props) {
  const [frozenAt, setFrozenAt] = useState(props.user.frozenAt);

  const cookies = new Cookies();
  const authToken = cookies.get('nJWT');
  const config = { headers: { Authorization: `Bearer ${authToken}` } };

  function freezeUser(user) {
    const url = process.env.REACT_APP_BASE_DOMAIN + `/api/admin/users/${user.id}/freeze`;
    const body = { id: user.id };

    axios.put(url, body, config)
      .then(user => {
        setFrozenAt(user.frozenAt)
        toast.success("User frozen.")
      }).catch(err => {
        toast.warning("There was a problem freezing this user.")
      });
    return
  }

  function unFreezeUser(user) {
    const url = process.env.REACT_APP_BASE_DOMAIN + `/api/admin/users/${user.id}/unfreeze`;
    const body = { id: user.id };

    axios.put(url, body, config)
      .then(user => {
        setFrozenAt(null)
        toast.success("User unfrozen.")
      }).catch(err => {
        toast.warning("There was a problem freezing this user.")
      });
    return
  }

  return (
    <tr>
      <td>{moment(props.user.createdAt).format('MM/DD/YY')}</td>
      <td>
          {props.user.verified && <CheckSquare className="text-primary"/> }
          {!props.user.verified && <Square /> }
      </td>
      <th className={props.user.identityVerifiedAt ? "" : 'text-muted'}>{props.user.first} {props.user.last} {props.user.iosDeviceId ? "(iOS)" : ""} {props.user.androidDeviceId ? "(Droid)" : ""}</th>
      <td className={props.user.emailConfirmedAt ? "" : 'text-muted'}>{props.user.email}</td>
      <td className={props.user.phoneConfirmedAt ? "" : 'text-muted'}>{props.user.phone.slice(2)}</td>
      {/*<td>{moment(props.user.dob).format('MM/DD/YY')}</td>*/}
      <td>{props.user.city}, {props.user.state} {props.user.postalCode}</td>
      <td max-width="20px">{props.user.balanceInScript}</td>
      <td>
        {!frozenAt &&
          <a href="#" className="btn btn-sm btn-outline-info" onClick={() => freezeUser(props.user)}>Freeze</a>
        }
        {frozenAt &&
          <span>
            <span>{moment(frozenAt).format('d MMM YY')}</span>
            <a href="#" className="btn btn-sm btn-info" onClick={() => unFreezeUser(props.user)}>Unfreeze</a>
          </span>
        }

      </td>
    </tr>
  )
}

export default AdminUser;

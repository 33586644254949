import React,{useEffect} from "react";
import { Link } from "react-router-dom";
import { Outlet } from "react-router-dom";
import { IoCopy } from "react-icons/io5";

import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import AdminTotals from "../Admin/AdminTotals";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { UserContext } from "../UserContext";
import TxList from "./Txs-List";

function Admin() {
  const{user} = UserContext();


  const [TransactionDetailshow, setShow] = useState(false);
  const[todayDate,setTodayDate] = useState("")

  const TransactionDetailhandleClose = () => setShow(false);
  const TransactiondetailhandleShow = () => setShow(true);

  useEffect(()=>{
    const today = new Date();
    const formattedDate = formatDate(today);
    
  
  },[])

  function formatDate(date) {
    const months = [
      "Jan", "Feb", "Mar",
      "Apr", "May", "Jun",
      "Jul", "Aug", "Sep",
      "Oct", "Nov", "Dec"
    ];
  
    const day = date.getDate();
    const monthIndex = date.getMonth();
    const year = date.getFullYear();
    setTodayDate(`Today, ${day} ${months[monthIndex]} ${year}`)
    
  }
  
  return (
    <>
     {user &&
     <>
      <div className="breadcrumbar">
        <h4>Activity</h4>
      </div>
      <div className="wallet-bar">
        <div className="row">
          <div className="col-xl-8 offset-xl-1 mt-xl-0">
            <TxList/>
          </div>
        </div>
      </div>
     </>}
    </>
  );
}
export default Admin;
